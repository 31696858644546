import { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Alert, FormFeedback, Card, CardBody, CardFooter } from 'reactstrap';
import qs from 'qs';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { auth, firestore, EmailAuthProvider, functions } from '../../firebase';
import UnAuthPage from '../hocs/UnAuthPage';
import { Invitation } from '../../models/firestore';
import { EMAIL_DUMMY_DOMAIN } from '../../shared/config';

const invitationsRef = firestore.collection('invitations');
const registerInvitedUser = functions.httpsCallable('registerInvitedUser');

type IFormInputs = {
  displayName: string;
  password: string;
  passwordConfirmation: string;
};

export default UnAuthPage(function SignUp(props: any) {
  const [invitation, setInvitation] = useState<Invitation>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<IFormInputs>();
  const { ref: displayNameRef, ...restDisplayName } = register('displayName', { required: '表示名が未入力です' });
  const { ref: passwordRef, ...restPassword } = register('password', {
    required: 'パスワードが未入力です',
    minLength: { value: 8, message: '8文字以上入力してください' },
  });
  const { ref: passwordConfirmationRef, ...restPasswordConfirmation } = register('passwordConfirmation', {
    required: 'パスワード（確認）が未入力です',
    validate: (value) => value === getValues('password') || 'パスワードが一致しません',
  });
  const [errorMessage, setErrorMessage] = useState<string>();
  const [invalidUrl, setInvalidUrl] = useState(false);
  const { invitationId } = qs.parse(window.location.search.slice(1)) as { invitationId: string };

  useEffect(() => {
    let unmounted = false;
    invitationsRef
      .doc(invitationId)
      .get()
      .then((snap) => {
        if (!snap.exists) {
          toast.error('無効なURLです');
          unmounted || setInvalidUrl(true);
          return;
        }
        unmounted || setInvitation(new Invitation({ id: snap.id, ref: snap.ref, ...snap.data() }));
      });
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: IFormInputs) => {
    if (!invitation) return;

    const { email } = invitation;
    const { displayName: name, password } = values;
    try {
      const providers = await auth.fetchSignInMethodsForEmail(email);
      if (providers.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)) throw new Error('すでに登録されています');
      const { user } = await auth.createUserWithEmailAndPassword(email, password);
      if (user) {
        await Promise.all([
          // プロファイルの表示名更新
          auth.currentUser?.updateProfile({ displayName: name }),
          // ユーザー登録
          registerInvitedUser({ invitationId, name }),
        ]);
      }
    } catch (e: any) {
      console.error(e);
      setErrorMessage(e?.message);
    }
  };

  return invalidUrl ? (
    <Navigate to="/" />
  ) : (
    <div className="container px-5 py-3">
      <div className="d-flex justify-content-center">
        <Card style={{ width: '520px' }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CardBody>
              <Alert color="info">初回ログインのため、表示名・パスワードを設定してください。</Alert>
              <FormGroup>
                <Label for="email">メールアドレス or ID</Label>
                <Input type="email" id="email" value={invitation?.email.replace(`@${EMAIL_DUMMY_DOMAIN}`, '') || ''} disabled />
              </FormGroup>
              <FormGroup>
                <Label for="display_name">表示名</Label>
                <Input
                  type="text"
                  id="display_name"
                  invalid={Boolean(errors.displayName)}
                  {...restDisplayName}
                  innerRef={displayNameRef}
                />
                {errors.displayName && <FormFeedback>{errors.displayName.message}</FormFeedback>}
              </FormGroup>
              <FormGroup>
                <Label for="password">パスワード</Label>
                <Input
                  type="password"
                  id="password"
                  invalid={Boolean(errors.password)}
                  {...restPassword}
                  innerRef={passwordRef}
                />
                {errors.password && <FormFeedback>{errors.password.message}</FormFeedback>}
              </FormGroup>
              <FormGroup>
                <Label for="password_confirmation">パスワード（確認）</Label>
                <Input
                  type="password"
                  id="password_confirmation"
                  invalid={Boolean(errors.passwordConfirmation)}
                  {...restPasswordConfirmation}
                  innerRef={passwordConfirmationRef}
                />
                {errors.passwordConfirmation && <FormFeedback>{errors.passwordConfirmation.message}</FormFeedback>}
              </FormGroup>
              {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            </CardBody>
            <CardFooter className="text-center">
              <a href="/terms-of-service" target="_blank" rel="noopener noreferrer" className="me-3">
                Onlineアナログゲーム 利用規約
              </a>
              <Button color="primary" type="submit" onClick={handleSubmit(onSubmit)}>
                利用規約に同意して登録する
              </Button>
            </CardFooter>
          </Form>
        </Card>
      </div>
    </div>
  );
});
