import React, { Fragment, useState } from 'react';
import { FormGroup, Label as ReactstrapLabel, Input } from 'reactstrap';
import { get, isEmpty, isNaN } from 'lodash';
import Select from 'react-select';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import { format as formatDate, parse as parseDate, setHours, setMinutes, getHours, getMinutes } from 'date-fns';

// 型定義ないのでrequireでanyにしてる
const texts = require('../shared/texts');

const validationText = (documentName: string, fieldName: string, key: any) => {
  return (
    get(texts.validations[documentName], `${fieldName}.${key}`)
    || get(texts.validations.general, key)
  );
}

export default function Field (props: any) {
  const { documentName, type, name: fieldName, label, value, setValue, options = [], validationErrors = [], readOnly = (_: any) => false, shouldHide, placeholder, rows, showsErrorsAlways = false, disabled = false, native = true, validations = {}, } = props;
  const [hasStarted, setHasStarted] = useState(false);
  const setValueAndStart = (value: any) => {
    setValue(isNaN(value) ? null : value);
    setHasStarted(true);
  };
  const isValid = validationErrors.length === 0;
  const validationCss = (hasStarted || showsErrorsAlways) ? classnames({ 'is-valid': isValid, 'is-invalid': isValid === false }) : '';
  const Label = (props: any) => {
    return (
      <ReactstrapLabel {...props}>
        {props.children}
        {validations.required != null && <span className="ms-1 text-danger">*</span>}
      </ReactstrapLabel>
    )
  };
  const types: { [key: string]: () => void } = {
    string: () => (
      <Fragment>
        {label && <Label>{label}</Label>}
        <Input name={fieldName} value={value || ''} onChange={(_: any) => setValueAndStart(_.target.value)} className={validationCss} readOnly={readOnly(value, props)} placeholder={placeholder} disabled={disabled} />
      </Fragment>
    ),
    password: () => (
      <Fragment>
        {label && <Label>{label}</Label>}
        <Input type="password" name={fieldName} value={value || ''} onChange={(_: any) => setValueAndStart(_.target.value)} className={validationCss} readOnly={readOnly(value, props)} placeholder={placeholder} disabled={disabled} />
      </Fragment>
    ),
    integer: () => (
      <Fragment>
        {label && <Label>{label}</Label>}
        <Input name={fieldName} type="number" step="1" value={value != null ? value : ''} onChange={(_: any) => setValueAndStart(parseInt(_.target.value))} className={classnames('text-end', validationCss)} readOnly={readOnly(value, props)} placeholder={placeholder} disabled={disabled} />
      </Fragment>
    ),
    boolean: () => (
      <Fragment>
        <FormGroup check>
          <Label check>
            <Input name={fieldName} type="checkbox" checked={value} onChange={(_: any) => setValueAndStart(_.target.checked)} className={validationCss} disabled={readOnly(value, props) || disabled} />
            {label}
          </Label>
        </FormGroup>
      </Fragment>
    ),
    select: () => (
      <Fragment>
        {label && <Label>{label}</Label>}
        <Select
          data-test={fieldName}
          value={options.find((_: any) => _.value === value) || null}
          onChange={(_: any) => setValueAndStart(_ && _.value)}
          className={validationCss}
          options={options}
          isDisabled={readOnly(value, props) || disabled}
          isClearable
        />
      </Fragment>
    ),
    multiSelect: () => (
      <Fragment>
        {label && <Label>{label}</Label>}
        <Select
          data-test={fieldName}
          isMulti
          value={options.filter((_: any) => (value || []).includes(_.value)) || null}
          onChange={(_: any) => setValueAndStart((_ || []).map((_: any) => _.value))}
          className={validationCss}
          options={options}
          isDisabled={readOnly(value, props) || disabled}
          isClearable
        />
      </Fragment>
    ),
    date: () => (
      <Fragment>
        {label && <Label>{label}</Label>}
        <div>
          <DatePicker
            data-test={fieldName}
            dateFormat="yyyy/MM/dd"
            timeIntervals={10}
            selected={value}
            onChange={setValueAndStart}
            className={classnames('form-control', validationCss)}
            readOnly={readOnly(value, props) || disabled}
            // placeholder={placeholder}
          />
        </div>
      </Fragment>
    ),
    time: () => (
      <Fragment>
        {label && <Label>{label}</Label>}
        <div>
          {
            native ? (
              <Input
                type="time"
                data-test={fieldName}
                value={value && formatDate(value, 'HH:mm')}
                onChange={(_: any) => setValueAndStart(isEmpty(_.target.value) ? '' : parseDate(_.target.value, 'HH:mm', new Date(1970, 0, 1)))}
                className={classnames('form-control', validationCss)}
                readOnly={readOnly(value, props) || disabled}
                placeholder={placeholder}
              />
            ) : (
              <DatePicker
                data-test={fieldName}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                timeIntervals={15}
                selected={value}
                onChange={(_: any) => value != null ? setValueAndStart(setHours(setMinutes(value, getMinutes(_)), getHours(_))) : _}
                className={classnames('form-control', validationCss)}
                readOnly={readOnly(value, props) || disabled}
                // placeholder={placeholder}
              />
            )
          }
        </div>
      </Fragment>
    ),
    text: () => (
      <Fragment>
        {label && <Label>{label}</Label>}
        <Input type="textarea" value={value || ''} onChange={_ => setValueAndStart(_.target.value)} className={validationCss} readOnly={readOnly(value, props)} placeholder={placeholder} rows={rows} disabled={disabled} />
      </Fragment>
    ),
  };
  if(shouldHide) return null;

  return (
    <FormGroup key={fieldName}>
      {
        types[type]()
      }
      {
        (hasStarted || showsErrorsAlways) && type !== 'list' && validationErrors.length > 0 && (
          <div className="small text-danger mt-1">
            {
              validationErrors.map((k: any) => (
                <div key={k}>{validationText(documentName, fieldName.replace(/\.\d+\./g, '.fields.'), k)}</div>
              ))
            }
          </div>
        )
      }
    </FormGroup>
  );
};
