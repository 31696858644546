import React from 'react';
import { Button, } from 'reactstrap';
import classnames from 'classnames';

export default function JoinButton (props: any) {
  const { item, itemRef, label = '参加', iconClassName = 'fas fa-sign-in-alt', ...extraProps } = props;
  const { id } = item;
  const onClickJoin = async (props: any) => {
    if (!window.confirm('参加しますか？')) return;

    window.location.href = `rooms/${id}`;
  };

  return (
    <Button color="primary" onClick={onClickJoin} {...extraProps}>
      <span className={classnames(iconClassName, 'me-1')} />
      {label}
    </Button>
  );
};
