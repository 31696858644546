import firebase from 'firebase/compat/app';
import { format } from 'date-fns';
import { FieldValue } from '../../firebase';
import { IStack, IHolder, IDeck, IHand } from '../database';

export type LoggingAction = 'drop' | 'pick' | 'import' | 'recall' | '';
export type LoggingHandler = (action: LoggingAction, stack: IStack, space: IHolder | IDeck | IHand) => void;

export type LogTarget = { type: 'card' | 'snapshot' | 'deck' | ''; key: string; name: string; remarks: string };
export type LogSpace = { type: 'holder' | 'hand' | ''; key: string; name: string; remarks: string };

export interface ILog {
  id: string;
  ref: firebase.firestore.DocumentReference | null;
  roomId: string;
  action: LoggingAction;
  user: { id: string; name: string } | null;
  target: LogTarget | null;
  space: LogSpace | null;
  stack: { key: string } | null;
  createdAt: firebase.firestore.Timestamp;
  toObject: () => {};
  toCsvLine: () => string;
}

export class Log implements ILog {
  id: string = '';
  ref: firebase.firestore.DocumentReference | null = null;
  roomId: string = '';
  action: LoggingAction = '';
  user: { id: string; name: string } | null = null;
  target: LogTarget | null = null;
  space: LogSpace | null = null;
  stack: { key: string } | null = null;
  createdAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp;

  constructor({
    id = '',
    ref = null,
    roomId = '',
    action = '',
    user = null,
    target = null,
    space = null,
    stack = null,
    createdAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
  }: Partial<ILog>) {
    Object.assign(this, {
      id,
      ref,
      roomId,
      action,
      user,
      target,
      space,
      stack,
      createdAt,
    });
  }

  toObject(): {} {
    return {
      roomId: this.roomId,
      action: this.action,
      user: this.user,
      target: this.target,
      space: this.space,
      stack: this.stack,
      createdAt: this.createdAt,
    };
  }

  static csvHeader(): string {
    return '時間,ユーザーID,ユーザー名,操作,種別,ターゲットID,ターゲット名,ターゲット備考,スペース種別,スペースID,スペース名,スペース備考,スタックID';
  }

  toCsvLine(): string {
    const timestamp = format(this.createdAt.toDate(), 'yyyy-MM-dd HH:mm:ss');
    const userId = this.user?.id || '';
    const userName = this.user?.name || '';
    const targetType = this.target?.type || '';
    const targetKey = this.target?.key || '';
    const targetName = this.target?.name || '';
    const targetRemarks = this.target?.remarks || '';
    const spaceType = this.space?.type || '';
    const spaceKey = this.space?.key || '';
    const spaceName = this.space?.name || '';
    const spaceRemarks = this.space?.remarks || '';
    const stackKey = this.stack?.key || '';
    return `${timestamp},${userId},${userName},${this.action},${targetType},${targetKey},${targetName},${targetRemarks},${spaceType},${spaceKey},${spaceName},${spaceRemarks},${stackKey}`;
  }
}
