import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import { shuffle } from 'lodash';
import { IStack, IDeck, IHand } from '../models/database';
import { RoomContext } from './pages/Room';

type Props = {
  stacks: IStack[];
  hands: IHand[];
  deck: IDeck;
  width?: number;
  height?: number;
  className?: string;
};

export default function ShuffleButton(props: Props) {
  const { stacks, deck, height = 40, width = 72, className } = props;
  const { roomRef, scale = 1.0, isBlocking } = useContext(RoomContext);

  const style: React.CSSProperties = {
    height: height * scale,
    width: width * scale,
    fontSize: `${18 * scale}px`,
    borderRadius: '25%',
    textAlign: 'center',
    padding: '0 5px',
  };

  const onShuffle = async () => {
    if (isBlocking) return;
    await roomRef?.child('isBlocking').set(true);

    const stack = stacks.find((stack) => stack.holderId === deck.key);
    if (stack) {
      const stacksRef = stack.ref?.parent;
      const newStackObjects = shuffle(stack.objects);
      const [{ key: newStackKey }] = newStackObjects;
      const updates = {
        ...stacks.reduce((acc: any, cur: IStack) => ({ ...acc, [cur.key]: cur.toObject() }), {}),
        [stack.key]: null,
        [newStackKey]: {
          ...stack.toObject(),
          objects: newStackObjects,
        },
      };
      await stacksRef?.set(updates);
    }

    await roomRef?.child('isBlocking').set(false);
  };

  return (
    <Button color="info" style={style} className={className || ''} onClick={onShuffle} disabled={isBlocking}>
      <span>Shuffle</span>
    </Button>
  );
}
