import { Container } from 'reactstrap';
import PublicPage from '../hocs/PublicPage';
import '../../css/privacyPolicy.css';

export default PublicPage(function PrivacyPolicy(props: any) {
  return (
    <Container>
      <article className="privacy-policy">
        <h1>プライバシーポリシー</h1>
        <p>
          当社は、現代の情報通信社会における個人情報保護の重要性を認識し、下記の方針に基づき個人情報の保護に努めます。弊社に個人情報を提供するにあたっては、下記の内容についてご同意のうえ、ご提供いただきますようお願いします。
        </p>
        <section>
          <h2>１. 個人情報の定義</h2>
          <p>
            個人情報とは、個人情報の保護に関する法律に規定される生存する個人に関する情報ならびに特定の個人と結びついて使用される情報であると認識しています。
          </p>
        </section>
        <section>
          <h2>２. 利用目的</h2>
          <p>当社は、収集した個人情報を以下の目的で利用することができるものとします。</p>
          <ul>
            <li>（１） ユーザーの個人認証及びユーザー向け各種サービスの提供</li>
            <li>（２） ユーザーの利用に伴う各種連絡・お知らせの配信</li>
            <li>
              （３）
              ユーザーへの各種アンケート等のご連絡、送付、別途ご同意いただいたユーザーからのアンケートの回収ほかアンケート業務の実施
            </li>
            <li>（４）ユーザーへの各種キャンペーン等のご連絡、実施</li>
            <li>（５）ユーザーへのメールマガジン、各種お知らせ等の情報配信</li>
            <li>（６）本サービスに関するご意見、お問い合わせへの回答</li>
            <li>（７）その他、前各号に付随する事項</li>
          </ul>
        </section>
        <section>
          <h2>３. 個人情報利用の制限</h2>
          <p>
            当社は、あらかじめユーザーの同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取扱うことはありません。ただし、次の場合はこの限りではありません。
          </p>
          <ul>
            <li>（１） 法令に基づく場合</li>
            <li>
              （２） 人の生命、身体または財産の保護のために必要がある場合であって、ユーザーの同意を得ることが困難であるとき
            </li>
            <li>
              （３）
              公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ユーザーの同意を得ることが困難であるとき
            </li>
            <li>
              （４）
              国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
            </li>
          </ul>
        </section>
        <section>
          <h2>４. 個人情報の適正な取得</h2>
          <p>当社は、適正に個人情報を取得し、偽りその他不正の手段により取得することはありません。</p>
        </section>
        <section>
          <h2>５. 個人情報の取得に際する利用目的の通知</h2>
          <p>
            当社は、個人情報を取得するにあたり、あらかじめその利用目的を公表します。ただし、次の場合はこの限りではありません。
          </p>
          <ul>
            <li>
              （１） 利用目的をユーザーに通知し、または公表することによりユーザーまたは第三者の生命、身体、財産その他の
              権利利益を害するおそれがある場合
            </li>
            <li>
              （２）
              利用目的をユーザーに通知し、または公表することによりプライバシーの権利または正当な利益を害するおそれがある場合
            </li>
            <li>
              （３）
              国の機関もしくは地方公共団体が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用目的をユーザーに通知し、または公表することにより当該事務の遂行に支障を及ぼすおそれがあるとき
            </li>
            <li>（４） 取得の状況からみて利用目的が明らかであると認められる場合</li>
          </ul>
        </section>
        <section>
          <h2>６. 委託先の監督</h2>
          <p>
            当社は、個人情報の取扱いの全部又は一部を委託する場合は、委託先と機密保持を含む契約の締結を求め、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。
          </p>
        </section>
        <section>
          <h2>７. 第三者提供の制限</h2>
          <p>
            当社は、次に掲げる場合を除くほか、あらかじめユーザーの同意を得ないで、個人情報を第三者に提供しません。ただし、当社が有料職業紹介事業を行う場合には、個人情報を当該事業に必要な範囲で第三者に提供することがあります。
          </p>
          <ul>
            <li> （１） 法令に基づく場合</li>
            <li>
              （２） 人の生命、身体または財産の保護のために必要がある場合であって、ユーザーの同意を得ることが困難であるとき
            </li>
            <li>
              （３）
              公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ユーザーの同意を得ることが困難であるとき
            </li>
            <li>
              （４）
              国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
            </li>
            <li>
              （５） 予め次の事項を告知あるいは公表をしている場合
              <ul>
                <li>１．利用目的に第三者への提供を含むこと</li>
                <li>２．第三者に提供されるデータの項目</li>
                <li>３．第三者への提供の手段または方法</li>
                <li>４．ユーザーの求めに応じて個人情報の第三者への提供を停止すること</li>
              </ul>
              ただし次に掲げる場合は上記に定める第三者には該当しません。
            </li>
            <li>（６） 当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</li>
            <li>（７） 合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
            <li>
              （８）
              個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめユーザーに通知し、またはユーザーが容易に知り得る状態に置いているとき
            </li>
          </ul>
        </section>
        <section>
          <h2>８. 個人情報の開示</h2>
          <p>
            当社は、ユーザーから、個人情報の開示を求められたときは、ユーザーからの請求であることを確認の上で、ユーザーに対し、遅滞なく開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。
          </p>
          <ul>
            <li>（１）ユーザーまたは第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
            <li>（２）当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
            <li>（３）他の法令に違反することとなる場合</li>
          </ul>
        </section>
        <section>
          <h2>９. 個人情報の訂正等</h2>
          <p>
            当社は、ユーザーから、個人情報が真実でないという理由によって、内容の訂正、追加または削除（以下「訂正等」といいます）を求められた場合には、ユーザーからの請求であることを確認の上で、他の法令の規定により特別の手続きが定められている場合を除き、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨ユーザーに通知します。
          </p>
        </section>
        <section>
          <h2>１０. 個人情報の利用停止等</h2>
          <p>
            当社は、ユーザーから、ユーザーの個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由、または偽りその他不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下「利用停止等」といいます）を求められた場合には、ユーザーからの請求であることを確認の上で、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨ユーザーに通知します。ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。請求が個人情報保護法の定める要件を満たさない場合、ご希望に添えない場合があります。
            <br />
            また、対応に別途費用をいただく場合がございます。
          </p>
        </section>
        <section>
          <h2>１１. クッキーについて</h2>
          <p>
            当社が提供するサービスには、ユーザーにいっそう便利に本ウェブサイトをご利用いただくため、又、閲覧履歴を採取するために「クッキー」という技術を使っているページがあります。
          </p>
          <p>
            「クッキー」は、ウェブサイトからユーザーのコンピュータを識別する技術です。
            ユーザーは、ブラウザの設定によりクッキーの受け取りを拒否したり、クッキーを受け取ったとき警告を表示させたりすることができます。
            ユーザーがクッキーの受け取りを拒否された場合も本ウェブサイトをご利用いただけますが一部機能がご利用できない場合があります。
          </p>
        </section>
        <section>
          <h2>１２. （お問い合わせ）</h2>
          <p>個人情報に関するお問い合わせは下記にご連絡ください。</p>
          <p>
            ＜受付窓口＞
            <br />
            <a href="mailto:info@so-guu.com">info@so-guu.com</a>
          </p>
        </section>
        <p style={{ textAlign: 'right' }}>2021年4月1日制定</p>
      </article>
    </Container>
  );
});
