import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label } from 'reactstrap';
import { toast } from 'react-toastify';

import { firestore, database } from '../../firebase';
import { IRoom, Snapshot, IUser } from '../../models/firestore';
import { EDIT_MODAL_ZINDEX } from '../../shared/config';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  room: IRoom;
  currentUser: IUser;
};

const snapshotsRef = firestore.collection('snapshots');
const databaseRoomsRef = database.ref('rooms');
const databaseSnapshotsRef = database.ref('snapshots');

export default function ExportSnapshotModal(props: Props) {
  const { isOpen, onClose, room, currentUser } = props;
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');
  const isUnsubmittable = !name;

  useEffect(() => {
    setName('');
    setComment('');
  }, [isOpen]);

  const onSave = async () => {
    if (isUnsubmittable) return;

    try {
      const snapshot = new Snapshot({ name, comment, owner: currentUser.id })
      const snapshotRef = await snapshotsRef.add(snapshot.toObject());
      const data = (await databaseRoomsRef.child(room.id).once('value')).val();
      await databaseSnapshotsRef.child(snapshotRef.id).set({ ...data, owner: currentUser.id })

      toast.success('スナップショットを作成しました');
      onClose()
    } catch(e) {
      console.error(e)
      toast.error('スナップショットの作成に失敗しました');
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} zIndex={EDIT_MODAL_ZINDEX}>
      <ModalHeader>Save Snapshot</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>Name</Label>
            <Input
              value={name}
              invalid={!name}
              valid={Boolean(name)}
              onChange={({ target: { value } }: { target: { value: string } }) => setName(value)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Comment</Label>
            <Input
              type="textarea"
              value={comment}
              onChange={({ target: { value } }: { target: { value: string } }) => setComment(value)}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
        <Button color="secondary" onClick={onSave} disabled={isUnsubmittable}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
