import firebase from 'firebase/compat/app';
import { parse } from 'papaparse';
import { SCALE_FACTOR } from './shared/config';
const { REACT_APP_ENV } = process.env;

type OverlapObject = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export const isOverlap = (obj1: OverlapObject, obj2: OverlapObject, ratio = 0): boolean => {
  const horizontal = obj2.x < obj1.x + obj1.width && obj1.x < obj2.x + obj2.width;
  const vertical = obj2.y < obj1.y + obj1.height && obj1.y < obj2.y + obj2.height;
  const isOverlap = horizontal && vertical;

  const isInclutionalX =
    obj1.width < obj2.width
      ? obj2.x <= obj1.x && obj1.x <= obj2.width - obj1.width
      : obj1.x <= obj2.x && obj2.x <= obj1.width - obj2.width;
  const isInclutionalY =
    obj1.height < obj2.height
      ? obj2.y <= obj1.y && obj1.y <= obj2.height - obj1.height
      : obj1.y <= obj2.y && obj2.y <= obj1.height - obj2.height;

  const overlapX = () => {
    if (isInclutionalX) {
      return Math.min(obj1.width, obj2.width);
    } else {
      return obj1.x < obj2.x ? obj1.x + obj1.width - obj2.x : obj2.x + obj2.width - obj1.x;
    }
  };
  const overlapY = () => {
    if (isInclutionalY) {
      return Math.min(obj1.height, obj2.height);
    } else {
      return obj1.y < obj2.y ? obj1.y + obj1.height - obj2.y : obj2.y + obj2.height - obj1.y;
    }
  };

  const overlapArea = overlapX() * overlapY();
  const targetArea1 = obj1.width * obj1.height;
  const targetArea2 = obj2.width * obj2.height;
  const isOverRatio = ratio ? overlapArea / Math.min(targetArea1, targetArea2) > ratio : true;

  return isOverlap && isOverRatio;
};

export const scaleToPercent = (scale: number) => {
  return scale * SCALE_FACTOR;
};

export const percentToScale = (percent: number) => {
  return percent / SCALE_FACTOR;
};

export const downloadCsv = async (rows: string[], fileName: string) => {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const data: any = new Blob([bom, rows.join('\n')], { type: 'text/csv' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(data);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  await setTimeout(() => {}, 1000);
  window.URL.revokeObjectURL(data);
  document.body.removeChild(link);
};

export const replaceUselessCharAsFileName = (filename: string) => {
  return filename.replace(/^\.|[¥/:*?"<>|]/g, '_');
};

export const validateRoom = async (roomRef: firebase.database.Reference) => {
  // NOTE: 2021/03中は本番環境にも適用する
  if (!['development', 'staging', 'production'].includes(REACT_APP_ENV || '')) return;

  const snapshot = await roomRef.once('value');
  const objects = snapshot.val().objects || {};

  const cardIds = Object.keys(objects.cards || {});
  const stacks: any[] = Object.entries(objects.stacks || {}).map(([key, value]: [string, any]) => ({ key, ...value }));
  const stackCardIds = stacks.map((stack: any) => (stack.objects || []).map((_: any) => _.key)).flat();

  // stacksにはあるが、cardsにはない場合
  for (const stack of stacks) {
    for (const cardId of (stack.objects || []).map((_: any) => _.key)) {
      if (!cardIds.includes(cardId)) {
        throw new Error(`[Room]:${snapshot.key} [Stack]:${stack.key} [Card]:${cardId} が objects/cards に存在しません`);
      }
    }
  }
  // cardsにはあるが、stacksには無い場合
  for (const cardId of cardIds) {
    if (!stackCardIds.includes(cardId)) {
      throw new Error(`[Room]:${snapshot.key} [Card]:${cardId} が objects/stacks に存在しません`);
    }
  }

  const decks: any[] = Object.entries(objects.decks || {}).map(([key, value]: [string, any]) => ({ key, ...value }));
  const deckCardIds = decks.map((deck: any) => (deck.deckCards || []).map((_: any) => _.cardIds || []).flat()).flat();
  // decksにはあるが、cardsにはない場合
  for (const deck of decks) {
    for (const cardId of (deck.deckCards || [])
      .map((_: any) => _.cardIds || [])
      .flat()
      .filter((_: any) => _)) {
      if (!cardIds.includes(cardId)) {
        throw new Error(`[Room]:${snapshot.key} [Deck]:${deck.key} [Card]:${cardId} が objects/cards に存在しません`);
      }
    }
  }
  // cardsにはあるが、decksには無い場合
  for (const cardId of cardIds) {
    if (!deckCardIds.includes(cardId)) {
      throw new Error(`[Room]:${snapshot.key} [Card]:${cardId} が objects/decks に存在しません`);
    }
  }

  const hands: any[] = Object.entries(objects.hands || {}).map(([key, value]: [string, any]) => ({ key, ...value }));
  // handsにはあるが、stacksにはない場合
  for (const hand of hands) {
    for (const cardId of hand.objects || []) {
      if (!stackCardIds.includes(cardId)) {
        throw new Error(
          `[Room]:${snapshot.key} [Hand]:${hand.key}[${hand.name}] [Card]:${cardId} が objects/stacks に存在しません`
        );
      }
    }
  }
};

export const sleep = (msec: number) => new Promise((resolve) => setTimeout(resolve, msec));

export const readFile = async (
  file: File,
  method: 'readAsArrayBuffer' | 'readAsBinaryString' | 'readAsDataURL' | 'readAsText' = 'readAsArrayBuffer'
): Promise<string | ArrayBuffer | null | undefined> => {
  const reader = new FileReader();
  reader[method](file);
  return new Promise((resolve) => {
    reader.addEventListener('load', ({ target }) => resolve(target?.result));
  });
};

export const readCSVFile = async (file: File, encoding: string) => {
  const fileContent = (await readFile(file)) as ArrayBuffer | null;
  const decoder = new TextDecoder(encoding);
  const { data = [] } = fileContent ? parse(decoder.decode(fileContent), { header: true }) : {};
  return data;
};
