import { useCallback } from 'react';
import { Button, ButtonGroup, Form, FormGroup, Label, Input, FormFeedback, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { updateUserPassword } from '../../firebaseFunctions';

const schema = z
  .object({
    password: z.string().min(8, '8文字以上のパスワードを入力してください'),
    passwordConfirmation: z.string().min(8, '8文字以上のパスワードを入力してください'),
  })
  .refine(({ password, passwordConfirmation }) => password === passwordConfirmation, {
    message: 'パスワードが一致しません',
    path: ['passwordConfirmation'],
  });

export const EditUserPasswordForm = ({
  uid,
  onSubmit,
  onClose,
}: {
  uid: string;
  onSubmit?: () => void;
  onClose?: () => void;
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ resolver: zodResolver(schema) });
  const _handleSubmit = useCallback(
    async (values: any) => {
      const { password } = values;
      try {
        await updateUserPassword({ uid, password });
        toast.success('パスワードを変更しました。');
        onSubmit?.();
      } catch (error) {
        console.error(error);
        toast.error('パスワードの変更に失敗しました。');
      }
    },
    [uid, onSubmit]
  );

  return (
    <Form onSubmit={handleSubmit(_handleSubmit)}>
      <Controller
        name="password"
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => (
          <FormGroup>
            <Label for="password">新しいパスワード</Label>
            <Input type="password" invalid={Boolean(fieldState.error)} {...field} innerRef={field.ref} />
            {fieldState.error && <FormFeedback>{fieldState.error.message}</FormFeedback>}
          </FormGroup>
        )}
      />
      <Controller
        name="passwordConfirmation"
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => (
          <FormGroup>
            <Label for="password">新しいパスワード（確認）</Label>
            <Input type="password" invalid={Boolean(fieldState.error)} {...field} innerRef={field.ref} />
            {fieldState.error && <FormFeedback>{fieldState.error.message}</FormFeedback>}
          </FormGroup>
        )}
      />
      <FormGroup>
        <div className="d-flex justify-content-end text-nowrap">
          <ButtonGroup>
            <Button color="primary" type="submit" disabled={isSubmitting}>
              {isSubmitting ? <Spinner size="sm" /> : '変更する'}
            </Button>
            {onClose && (
              <Button color="secondary" onClick={onClose}>
                閉じる
              </Button>
            )}
          </ButtonGroup>
        </div>
      </FormGroup>
    </Form>
  );
};
