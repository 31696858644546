import { useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { EDIT_MODAL_ZINDEX } from '../../shared/config';
import { IUser, IGroup, groupRoles } from '../../models/firestore';

type Props = {
  user: IUser;
  group: IGroup;
  isOpen: boolean;
  onClose: () => void;
};

type IFormInputs = {
  role: {
    label: string;
    value: string;
  };
};

export default function UserGroupRoleFormModal(props: Props) {
  const { user, group, isOpen, onClose } = props;
  const { control, handleSubmit, setValue } = useForm<IFormInputs>();

  const onSubmit = async (values: IFormInputs) => {
    const groups = { ...user.groups, [group.id]: { role: values.role.value } };
    const roles = Object.values(groups).map((_) => _.role);
    const role = () => {
      if (roles.some((_) => _ === 'admin')) return 'admin';
      if (roles.some((_) => _ === 'creator')) return 'creator';

      return 'player';
    };
    const updates = user.isSysAdmin ? { groups } : { role: role(), groups };

    try {
      await user.ref?.update(updates);
      toast.success('設定しました');
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
    onClose();
  };

  useEffect(() => {
    setValue('role', groupRoles.find((_) => _.value === user.groups[group.id]?.role)!);
  }, [isOpen, user, group, setValue]);

  return (
    <Modal isOpen={isOpen} toggle={onClose} zIndex={EDIT_MODAL_ZINDEX}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>ユーザ権限</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>権限</Label>
            <Controller name="role" control={control} render={({ field }) => <Select options={groupRoles} {...field} />} />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">
            Save
          </Button>
          <Button color="secondary" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
