import React, { useEffect } from 'react';

import { auth } from '../../firebase';

export default function RoomPageHOC(WrappedComponent: any) {
  return function RoomPage(props: any) {
    const { firebaseUser, isLoading, currentUser } = props;

    useEffect(() => {
      if (!firebaseUser && !isLoading) {
        auth.signInAnonymously();
      }
    }, [firebaseUser, isLoading]);

    return (
      !isLoading && currentUser && (
        <div className="page">
          <WrappedComponent {...props} currentUser={currentUser} />
        </div>
      )
    );
  };
}
