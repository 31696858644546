import React from 'react';
import { useToggle } from 'react-use';
import { Card, CardBody, Button, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';

import { firestore as db } from './../firebase';
import { IInvitation, IGroup, IUser, GroupRole, groupRoles } from '../models/firestore';
import InvitationModal from './modals/InvitationModal';
import { EMAIL_DUMMY_DOMAIN } from '../shared/config';

const invitationsRef = db.collection('invitations');

type Props = {
  group: IGroup;
  currentUser: IUser;
  invitations: IInvitation[];
};

export default function Invitations(props: Props) {
  const { group, currentUser, invitations } = props;
  const [showModal, toggleModal] = useToggle(false);
  // group.capacityが0または未設定の場合は無制限に招待可能
  const canInvite = !group.capacity || group.capacity > group.userIds.length + invitations.length;

  const InvitationRow = ({ id, email, role, result }: { id: string; email: string; role: GroupRole; result: string }) => {
    const onDelete = async () => {
      await invitationsRef.doc(id).delete();
      toast.warn('削除しました');
    };
    const onClickCopy = async () => {
      copy(`${window.location.origin}/signup?invitationId=${id}`);
      toast.success('URLをクリップボードにコピーしました');
    };
    const invitationMailResult = (result: string) => {
      if (!result) return '未送信';
      if (result === 'ok') return '送信済み';
      return <span title={result}>送信エラー</span>;
    };
    return (
      <tr key={id}>
        <td>{email.replace(`@${EMAIL_DUMMY_DOMAIN}`, '')}</td>
        <td>{groupRoles.find(({ value }) => value === role)?.label}</td>
        <td>{invitationMailResult(result)}</td>
        <td className="text-end text-nowrap">
          <Button color="info" onClick={onClickCopy}>
            <i className="fas fa-copy me-1"></i>招待URLコピー
          </Button>
          <Button color="danger" className="ms-2" onClick={onDelete}>
            <i className="fas fa-trash me-1"></i>削除
          </Button>
        </td>
      </tr>
    );
  };

  return (
    <div>
      {canInvite && <div className="d-flex justify-content-end mb-2">
        <Button color="primary" onClick={toggleModal}>
          <span className="fas fa-plus me-1" />
          ユーザー招待
        </Button>
        <InvitationModal isOpen={showModal} group={group} onClickClose={toggleModal} currentUser={currentUser} />
      </div>}
      <Card>
        <CardBody>
          <div className="d-flex justify-content-center">
            <h4>招待中</h4>
          </div>
          <Table>
            <thead>
              <tr>
                <th>メールアドレス or ID</th>
                <th>権限</th>
                <th>招待メール</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {invitations.map(({ id, email, role, result }) => (
                <InvitationRow key={id} id={id} email={email} role={role} result={result} />
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
}
