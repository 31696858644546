import { useState, useEffect, useMemo } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import Select from 'react-select';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import validator from 'validator';
import { firestore as db } from '../../firebase';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import { User, IUser, IGroup, GroupRole, groupRoles } from '../../models/firestore';
import { EMAIL_DUMMY_DOMAIN } from '../../shared/config';

const invitationsRef = db.collection('invitations');
const usersRef = db.collection('users');

type Props = {
  isOpen: boolean;
  group: IGroup;
  onClickClose: () => void;
  currentUser: IUser;
};

export default function InvitationModal(props: Props) {
  const { isOpen, group, onClickClose, currentUser } = props;
  const [_email, setEmail] = useState('');
  const isEmailUser = useMemo(() => _email.match(/@/), [_email]);
  const email = useMemo(() => (isEmailUser ? _email : _email + '@' + EMAIL_DUMMY_DOMAIN), [isEmailUser, _email]);
  const [role, setRole] = useState<GroupRole>('player');
  const isUnsubmittable = !email || !validator.isEmail(email) || validator.isMultibyte(email);
  const users = useCollectionSubscription(usersRef, User);

  useEffect(() => {
    setEmail('');
    setRole('player');
  }, [isOpen]);

  const createInvitation = (): Promise<void> => {
    const token = nanoid();
    return invitationsRef
      .doc(token)
      .get()
      .then(({ exists, ref }) => {
        if (exists) return createInvitation();
        return ref.set({
          email,
          role,
          groupId: group.id,
          createdAt: new Date(),
        });
      });
  };

  const addUserToGroup = async (user: IUser, group: IGroup) => {
    const newGroups = { ...user.groups, [group.id]: { role } };
    const newGroupRoles = Object.values(newGroups).map((_) => _.role);
    const newRole = () => {
      if (newGroupRoles.some((_) => _ === 'admin')) return 'admin';
      if (newGroupRoles.some((_) => _ === 'creator')) return 'creator';

      return 'player';
    };
    const commonUpdates = { groupIds: [...user.groupIds, group.id], groups: { ...user.groups, [group.id]: { role } } };
    const updates = user.isSysAdmin ? commonUpdates : { ...commonUpdates, role: newRole() };

    try {
      await user.ref?.update(updates);
      await group.ref?.update({ userIds: [...group.userIds, user.id] });
      toast.success('招待しました');
      onClickClose();
    } catch (e) {
      console.error(e);
      toast.error('招待に失敗しました');
    }
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    if (isUnsubmittable) return;

    const user = users.find((_) => _.email === email);
    if (user) {
      if (isEmailUser) {
        await addUserToGroup(user, group);
      } else {
        toast.error('登録済みのIDです。別のIDを指定してください');
      }
      return;
    }

    try {
      await createInvitation();
      const message = isEmailUser ? '招待メールを送信予約しました' : '招待URLからアカウント登録を行ってください';
      toast.success(message);
      onClickClose();
    } catch (e) {
      console.error(e);
      toast.error('招待に失敗しました');
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={onClickClose}>
      <ModalHeader>ユーザー招待</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <FormGroup>
            <Label for="email">メールアドレス or ID</Label>
            <Input
              value={_email}
              valid={!isUnsubmittable}
              invalid={email ? isUnsubmittable : false}
              type="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          {currentUser?.isSysAdmin && (
            <FormGroup>
              <Label for="role">権限</Label>
              <Select
                id="role"
                value={groupRoles.find((_) => _.value === role) || null}
                options={groupRoles}
                onChange={(_) => setRole(_?.value as GroupRole)}
              />
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>
            閉じる
          </Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>
            招待メール送信
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
