import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Navbar, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { auth } from '../firebase';
import { IUser } from '../models/firestore';

type Props = {
  currentUser: IUser | undefined;
};

export default function HeaderNav(props: Props) {
  const { currentUser: user } = props;
  const signOut = () => {
    toast.success('サインアウトしました');
    auth.signOut();
  };
  return (
    <header className="header-nav">
      <Navbar color="light" light className="justify-content-between">
        <Link to="/" className="navbar-brand">
          Onlineアナログゲーム
        </Link>
        <Nav navbar className="header-nav__user-menu">
          {user && (
            <UncontrolledDropdown nav tag="div">
              <DropdownToggle nav caret>
                {user.name || user.email}
              </DropdownToggle>
              <DropdownMenu end>
                {user?.isSysAdmin && (
                  <Fragment>
                    <DropdownItem header>システム管理機能</DropdownItem>
                    <DropdownItem tag={Link} to="/groups">
                      グループ管理
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/sysadmin/logs">
                      ログ一覧
                    </DropdownItem>
                    <DropdownItem divider />
                  </Fragment>
                )}
                {user?.isSomeGroupAdmin() && (
                  <DropdownItem tag={Link} to="/groups">
                    グループ管理
                  </DropdownItem>
                )}
                <DropdownItem onClick={signOut}>サインアウト</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </Nav>
      </Navbar>
    </header>
  );
}
