import firebase from 'firebase/compat/app';
import { nanoid } from 'nanoid';
import { IStack, Card, ICard, Stack, IHand } from '.';
import { LogSpace } from '../firestore';
import { DEFAULT_CARD_WIDTH, DEFAULT_CARD_HEIGHT, DEFAULT_SCALE } from '../../shared/config';
import { DeckCard, CardImage } from './deckCard';

type ActualSize = {
  width: number;
  height: number;
  scale: number;
};

export interface IDeck {
  key: string;
  ref: firebase.database.Reference | null;
  type: string;
  x: number;
  y: number;
  z: number;
  width: number;
  height: number;
  actualSize: ActualSize;
  label: string;
  backImage: CardImage | null;
  deckCards: DeckCard[];
  isSameBackImage: boolean;
  toObject: () => {};
  allCardIds: string[];
  changeImageCount: (
    ref: firebase.database.Reference,
    image: DeckCard,
    newCount: number,
    stacks: IStack[],
    stacksRef: firebase.database.Reference
  ) => void;
  changeImageUrl: (
    cardIds: string[],
    pathName: 'frontImg' | 'backImg',
    url: string,
    cardsRef: firebase.database.Reference
  ) => void;
  changeImageSize: (width: number, height: number, stacks: IStack[], cardsRef: firebase.database.Reference) => void;
  deleteImageRow: (
    deckCard: DeckCard,
    deckCardRef: firebase.database.Reference,
    stacks: IStack[],
    hands: IHand[],
    cardsRef: firebase.database.Reference
  ) => Promise<void>;
  recallShouldConfirm: boolean;
  recallAllShouldConfirm: boolean;
  deleteCards: (cardIds: string[], stacks: IStack[], hands: IHand[], cardsRef: firebase.database.Reference) => void;
  changeAllBackImageUrls: (isSameBackImage: boolean, cardsRef: firebase.database.Reference) => Promise<void[]>;
  toLogFormat: LogSpace;
}

export class Deck implements IDeck {
  key: string = '';
  ref: firebase.database.Reference | null = null;
  type: string = '';
  x: number = 0;
  y: number = 0;
  z: number = 0;
  width: number = 0;
  height: number = 0;
  actualSize: ActualSize = {
    width: DEFAULT_CARD_WIDTH,
    height: DEFAULT_CARD_HEIGHT,
    scale: DEFAULT_SCALE,
  };
  label: string = '';
  backImage: CardImage | null = null;
  deckCards: DeckCard[] = [];
  isSameBackImage: boolean = true;
  recallShouldConfirm: boolean = false;
  recallAllShouldConfirm: boolean = false;

  constructor({
    key = '',
    ref = null,
    type = '',
    x = 0,
    y = 0,
    z = 0,
    width = DEFAULT_CARD_WIDTH * DEFAULT_SCALE,
    height = DEFAULT_CARD_HEIGHT * DEFAULT_SCALE,
    actualSize = {
      width: DEFAULT_CARD_WIDTH,
      height: DEFAULT_CARD_HEIGHT,
      scale: DEFAULT_SCALE,
    },
    label = '',
    backImage = null,
    deckCards = [],
    isSameBackImage = true,
    recallShouldConfirm = false,
    recallAllShouldConfirm = false,
  }: Partial<IDeck>) {
    Object.assign(this, {
      key,
      ref,
      type,
      x,
      y,
      z,
      width,
      height,
      actualSize,
      label,
      backImage,
      deckCards: this.deckCardsInitialize(deckCards),
      isSameBackImage,
      recallShouldConfirm,
      recallAllShouldConfirm,
    });
  }

  deckCardsInitialize(deckCards: any): DeckCard[] {
    const array = Array.isArray(deckCards) ? deckCards : Object.values(deckCards);
    return array.map((_: any) => new DeckCard(_));
  }

  toObject(): {} {
    return {
      type: this.type,
      x: this.x,
      y: this.y,
      z: this.z,
      width: this.width,
      height: this.height,
      actualSize: this.actualSize,
      label: this.label,
      backImage: this.backImage,
      deckCards: this.deckCards,
      isSameBackImage: this.isSameBackImage,
      recallShouldConfirm: this.recallShouldConfirm,
      recallAllShouldConfirm: this.recallAllShouldConfirm,
    };
  }

  get allCardIds(): string[] {
    return this.deckCards.filter((_) => _.cardIds).reduce((x: string[], y) => [...x, ...y.cardIds], []);
  }

  generateUuid(): string {
    const id = nanoid();
    return this.allCardIds.some((_) => _ === id) ? this.generateUuid() : id;
  }

  generateNewCards(deckCard: DeckCard, count: number, cardsRef: firebase.database.Reference): ICard[] {
    const frontImg = deckCard.front?.url;
    const backImg = this.isSameBackImage ? this.backImage?.url : deckCard.back?.url;
    if (!frontImg || !backImg) return [];
    const uuids: string[] = [...Array(count)].reduce((x, y) => [...x, this.generateUuid()], []);
    return uuids.map((uuid) => {
      const card = new Card({ key: uuid, width: this.width, height: this.height, frontImg, backImg, deckId: this.key });
      cardsRef.child(uuid).set(card.toObject());
      return card;
    });
  }

  changeImageCount(
    ref: firebase.database.Reference,
    deckCard: DeckCard,
    newCount: number,
    stacks: IStack[],
    roomRef: firebase.database.Reference
  ): void {
    if (deckCard.count === newCount) return;
    const stacksRef = roomRef.child('objects/stacks');
    const cardsRef = roomRef.child('objects/cards');

    if (deckCard.count < newCount) {
      // increase cards
      const cards = this.generateNewCards(deckCard, newCount - deckCard.count, cardsRef);
      if (!cards.length) return;

      const objects = cards.map((_) => ({ key: _.key, opened: false }));
      const stack = stacks.find((_) => _.x === this.x && _.y === this.y);
      if (stack) {
        stack.ref?.update({ objects: [...stack.objects, ...objects] });
      } else {
        const newStack = new Stack({
          x: this.x,
          y: this.y,
          z: this.z + 1,
          width: this.width,
          height: this.height,
          holderId: this.key,
          objects,
        });
        const [top] = cards;
        stacksRef.child(top.key).set(newStack.toObject());
      }
      const uuids = cards.map(({ key }) => key);
      ref.update({ count: newCount, cardIds: deckCard.cardIds ? [...deckCard.cardIds, ...uuids] : uuids });
    } else {
      // decrease cards
      const removeIds = deckCard.cardIds.slice(newCount - deckCard.count);
      const targetStacks = stacks.filter((stack) => stack.objects.some((_) => removeIds.includes(_.key)));
      Promise.all([
        ...targetStacks.map(async (stack) => {
          const restObjects = stack.objects.filter(({ key }) => !removeIds.includes(key));
          if (restObjects.length === 0) {
            return stack.ref?.remove();
          } else {
            const [{ key: restStackKey }] = restObjects;
            const restStack = new Stack({ ...stack.toObject(), objects: restObjects });
            const updates =
              stack.key === restStackKey
                ? {
                    [stack.key]: restStack.toObject(),
                  }
                : {
                    [stack.key]: null,
                    [restStackKey]: restStack.toObject(),
                  };
            return stack.ref?.parent?.update(updates);
          }
        }),
        ...removeIds.map(async (id) => cardsRef.child(id).remove()),
      ]);
      ref.update({ count: newCount, cardIds: deckCard.cardIds.slice(0, newCount) });
    }
  }

  changeImageUrl(cardIds: string[], pathName: 'frontImg' | 'backImg', url: string, cardsRef: firebase.database.Reference) {
    Promise.all(cardIds.map(async (id) => cardsRef.child(id).update({ [pathName]: url })));
  }

  changeImageSize(width: number, height: number, stacks: IStack[], cardsRef: firebase.database.Reference): void {
    const targetStacks = stacks.filter(({ key }) => this.allCardIds.includes(key));
    Promise.all([
      ...this.allCardIds.map(async (id) => cardsRef.child(id).update({ width, height })),
      ...targetStacks.map(async (stack) => stack.ref?.update({ width, height })),
    ]);
  }

  async deleteImageRow(
    deckCard: DeckCard,
    deckCardRef: firebase.database.Reference,
    stacks: IStack[],
    hands: IHand[],
    cardsRef: firebase.database.Reference
  ): Promise<void> {
    if (!deckCardRef.parent) return;

    this.deleteCards(deckCard.cardIds, stacks, hands, cardsRef);
    await deckCardRef.remove();
    // NOTE: ref.remove()すると配列が歯抜けになるので、隙間を詰める
    const deckCards = await deckCardRef.parent.once('value').then((_) => _.val());
    deckCards && (await deckCardRef.parent.set(deckCards.filter((_: any) => _)));
  }

  deleteCards(cardIds: string[], stacks: IStack[], hands: IHand[], cardsRef: firebase.database.Reference): void {
    const targetStacks = stacks.filter((_) => _.objects.some(({ key }) => cardIds.includes(key)));
    Promise.all([
      ...hands.map(async (hand) => hand.removeStacks(targetStacks)),
      ...targetStacks.map(async (stack) => {
        const rest = stack.objects.filter(({ key }) => !cardIds.includes(key));
        if (rest.length === 0) {
          stack.ref?.remove();
        } else {
          stack.ref?.update({ objects: rest });
        }
      }),
      ...cardIds.map(async (id) => cardsRef.child(id).remove()),
    ]);
  }

  changeAllBackImageUrls(isSameBackImage: boolean, cardsRef: firebase.database.Reference): Promise<void[]> {
    return Promise.all(
      this.deckCards
        .map((deckCard) => {
          const backImg = (isSameBackImage ? this.backImage?.url : deckCard.back?.url) || null;
          return deckCard.cardIds.map((id) => cardsRef.child(id).update({ backImg }));
        })
        .flat()
    );
  }

  get toLogFormat(): LogSpace {
    return { type: 'holder', key: this.key, name: this.label, remarks: '' };
  }
}
