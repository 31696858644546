const { REACT_APP_ENV: ENV = 'development' } = process.env;

const firebaseConfig: { [key: string]: any } = {
  production: {
    apiKey: 'AIzaSyAJpE40tJphqGp7-Jev2a0-BL7MbLNwk9E',
    authDomain: 'tkool-production.firebaseapp.com',
    databaseURL: 'https://tkool-production-default-rtdb.firebaseio.com',
    projectId: 'tkool-production',
    storageBucket: 'tkool-production.appspot.com',
    messagingSenderId: '659290019521',
    appId: '1:659290019521:web:bd1a6bf13abecefb456640',
  },
  staging: {
    apiKey: 'AIzaSyD1LPStFeY3mAKl25ldBMbjfg--IrF41XA',
    authDomain: 'tkool-staging.firebaseapp.com',
    databaseURL: 'https://tkool-staging.firebaseio.com',
    projectId: 'tkool-staging',
    storageBucket: 'tkool-staging.appspot.com',
    messagingSenderId: '270516600807',
    appId: '1:270516600807:web:a61b6d6b33850575144742',
  },
  development: {
    apiKey: 'AIzaSyD1LPStFeY3mAKl25ldBMbjfg--IrF41XA',
    authDomain: 'tkool-staging.firebaseapp.com',
    databaseURL: 'https://tkool-staging.firebaseio.com',
    projectId: 'tkool-staging',
    storageBucket: 'tkool-staging.appspot.com',
    messagingSenderId: '270516600807',
    appId: '1:270516600807:web:a61b6d6b33850575144742',
  },
};

export const config = firebaseConfig[ENV];
