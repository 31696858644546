import { useState, useContext } from 'react';
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  FormFeedback,
} from 'reactstrap';
import { storage } from '../../firebase';
import isInt from 'validator/lib/isInt';
import { IBoard } from '../../models/database';
import { scaleToPercent, percentToScale } from '../../util';
import { EDIT_MODAL_ZINDEX, BOARD_WIDTH, BOARD_HEIGHT, DEFAULT_SCALE } from '../../shared/config';
import ImageFileUploader from '../ImageFileUploader';
import { RoomContext } from '../pages/Room';

const widthValidIntOption = { min: 0, max: BOARD_WIDTH / DEFAULT_SCALE };
const heightValidIntOption = { min: 0, max: BOARD_HEIGHT / DEFAULT_SCALE };
const scaleValidIntOption = { min: 0, max: 500 };

export const BoardEditModal = ({ isOpen, onClose, board }: { isOpen: boolean; onClose: () => void; board: IBoard }) => {
  const { room } = useContext(RoomContext);
  const [width, setWidth] = useState<string>(board.actualSize.width.toString());
  const [height, setHeight] = useState<string>(board.actualSize.height.toString());
  const [scale, setScale] = useState<number>(board.actualSize.scale);

  const onChangeWidth = ({ target: { value } }: { target: { value: string } }) => {
    setWidth(value);
    if (!board.ref || !isInt(value, widthValidIntOption)) return;

    board.ref.update({
      width: Number(value) * scale,
      actualSize: { ...board.actualSize, width: Number(value) },
    });
  };

  const onChangeHeight = ({ target: { value } }: { target: { value: string } }) => {
    setHeight(value);
    if (!board.ref || !isInt(value, heightValidIntOption)) return;

    board.ref.update({
      height: Number(value) * scale,
      actualSize: { ...board.actualSize, height: Number(value) },
    });
  };

  const onChangeScale = ({ target: { value } }: { target: { value: string } }) => {
    const changedScale = percentToScale(Number(value));
    setScale(changedScale);
    if (!board.ref || !isInt(value, scaleValidIntOption)) return;

    board.ref.update({
      width: Number(width) * changedScale,
      height: Number(height) * changedScale,
      actualSize: { ...board.actualSize, scale: changedScale },
    });
  };

  const onChangeImage = async (file: File) => {
    if (!board.ref) return;

    // TODO: gameIdが設定されるようになるまで、仮でroomIdをいれとく
    const path = `games/${room && (room.snapshotId || room.id)}/boards/${board.key}/${file.name}`;
    const storageRef = storage.ref().child(path);
    const metadata = { cacheControl: 'max-age=31536000' };
    await storageRef.put(file, metadata);
    const url = await storageRef.getDownloadURL();
    await board.ref.update({ image: { name: file.name, path, url } });
  };

  const onRemoveImage = async () => {
    if (!board.ref) return;

    // NOTE: 複数ルームで画像が共有されている可能性があるのでストレージからは削除しない
    await board.ref.update({ image: null });
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} zIndex={EDIT_MODAL_ZINDEX}>
      <ModalHeader>Edit Board</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>Board Size (width / height / scale)</Label>
            <Row>
              <Col md={4}>
                <InputGroup>
                  <Input value={width} onChange={onChangeWidth} invalid={!isInt(width, widthValidIntOption)} />
                  <div className="input-group-append">
                    <InputGroupText>mm</InputGroupText>
                  </div>
                  <FormFeedback>{`${widthValidIntOption.min}~${widthValidIntOption.max}の範囲で指定してください`}</FormFeedback>
                </InputGroup>
              </Col>
              <Col md={4}>
                <InputGroup>
                  <Input value={height} onChange={onChangeHeight} invalid={!isInt(height, heightValidIntOption)} />
                  <div className="input-group-append">
                    <InputGroupText>mm</InputGroupText>
                  </div>
                  <FormFeedback>{`${heightValidIntOption.min}~${heightValidIntOption.max}の範囲で指定してください`}</FormFeedback>
                </InputGroup>
              </Col>
              <Col md={4}>
                <InputGroup>
                  <Input
                    value={scaleToPercent(scale)}
                    onChange={onChangeScale}
                    invalid={!isInt(scaleToPercent(scale).toString(), scaleValidIntOption)}
                  />
                  <div className="input-group-append">
                    <InputGroupText>%</InputGroupText>
                  </div>
                  <FormFeedback>{`${scaleValidIntOption.min}~${scaleValidIntOption.max}の範囲で指定してください`}</FormFeedback>
                </InputGroup>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Label>Board Image</Label>
            <div className="d-flex" style={{ backgroundColor: '#f5f5f5', borderRadius: '5px' }}>
              <div className="flex-fill">
                {board.image?.url && <img src={board.image?.url} width="100%" alt="board preview" />}
              </div>
              <div className="ms-2">
                <ImageFileUploader onChange={onChangeImage} isUploaded={!!board.image} onRemove={onRemoveImage} />
              </div>
            </div>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
