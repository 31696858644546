import React, { useRef } from 'react';
import { Button } from 'reactstrap';

type Props = {
  className?: string;
  isUploaded: boolean;
  onChange: (file: File) => void;
  onRemove: () => void;
};

export default function ImageFileUploader(props: Props) {
  const { className = '', isUploaded, onChange, onRemove } = props;
  const hiddenFileInput: any = useRef(null);

  const onClickUpload = () => {
    if (!hiddenFileInput || !hiddenFileInput.current) return;

    hiddenFileInput.current.click();
  };
  const onChangeFile = async ({ target: { files } }: { target: { files: FileList | null } }) => {
    if (!files) return;
    const [file] = Array.from(files);
    onChange(file);
  };
  const onClickRemove = () => {
    hiddenFileInput.current.value = null;
    onRemove();
  };
  return (
    <span className={className}>
      <Button onClick={onClickUpload} size="sm" color="primary" outline className="me-1">
        <i className="fas fa-upload"></i>
      </Button>
      {isUploaded && (
        <Button onClick={onClickRemove} size="sm" color="danger" outline>
          <i className="fas fa-trash-alt"></i>
        </Button>
      )}
      <input type="file" ref={hiddenFileInput} accept="image/*" onChange={onChangeFile} style={{ display: 'none' }} />
    </span>
  );
}
