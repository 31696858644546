import React, { useState } from 'react';
import { useToggle } from 'react-use';
import { Card, CardBody, Table, Input, Button, FormGroup } from 'reactstrap';
import { Navigate } from 'react-router-dom';
import { format } from 'date-fns';
import firebase from 'firebase/compat/app';

import useCollectionSubscription from '../../../hooks/useCollectionSubscription';
import { firestore as db } from '../../../../firebase';
import { IRoom, Room, Log } from '../../../../models/firestore';
import Page from '../../../hocs/Page';
import { downloadCsv, replaceUselessCharAsFileName } from '../../../../util';

const roomsRef = db.collection('rooms');
const logsRef = db.collection('logs');

const formatDate = (date: firebase.firestore.Timestamp | undefined) => {
  if (!date) return '';
  return format(date.toDate(), 'yyyy-MM-dd HH:mm:ss');
};

export default Page(function Logs(props: any) {
  const { currentUser } = props;
  const rooms = useCollectionSubscription(roomsRef, Room, []);
  const [checkedRooms, setCheckedRooms] = useState<IRoom[]>([]);
  const [isDownloading, toggleDownloading] = useToggle(false);
  const [currentDownloadingId, setCurrentDownloadingId] = useState<string | null>(null);
  const [downloadedResults, setDownloadedResults] = useState<{ roomId: string; result: 'success' | 'failed' }[]>([]);

  const DownloadingIcon = (roomId: string) => {
    if (currentDownloadingId === roomId) return <i className="fas fa-spinner fa-spin"></i>;
    if (downloadedResults.some((_) => _.roomId === roomId && _.result === 'success')) return <i className="fas fa-check"></i>;
    if (downloadedResults.some((_) => _.roomId === roomId && _.result === 'failed')) return <i className="fas fa-times"></i>;
    return <span></span>;
  };

  const RoomRow = (room: IRoom) => {
    const { id, name, description, log } = room;
    const checked = checkedRooms.some((_) => _.id === id);
    const backgroundColor = checked ? 'lightblue' : '';
    const onChange = ({ target: { checked } }: any) => {
      if (checked) {
        setCheckedRooms([...checkedRooms, room]);
      } else {
        setCheckedRooms(checkedRooms.filter((_) => _.id !== id));
      }
    };
    return (
      <tr key={id} style={{ backgroundColor }}>
        <td>
          <FormGroup check>
            {isDownloading ? DownloadingIcon(id) : <Input type="checkbox" onChange={onChange} checked={checked}></Input>}
          </FormGroup>
        </td>
        <td>{name}</td>
        <td>{description}</td>
        <td>{log?.size || 0}</td>
        <td>{formatDate(log?.lastLoggedAt)}</td>
      </tr>
    );
  };

  const downloadRoomLogs = async (room: IRoom): Promise<'success' | 'failed'> => {
    try {
      const logs = await logsRef
        .where('roomId', '==', room.id)
        .orderBy('createdAt')
        .get()
        .then(({ docs }) => docs.map((doc) => new Log({ id: doc.id, ref: doc.ref, ...doc.data() })));
      await downloadCsv(
        [Log.csvHeader(), ...logs.map((_) => _.toCsvLine())],
        `${replaceUselessCharAsFileName(room.name)}-${room.id}.csv`
      );
      return 'success';
    } catch (e) {
      console.error(e);
      return 'failed';
    }
  };

  const onDownload = async () => {
    if (!window.confirm(`${checkedRooms.length} ルームのログを出力します。よろしいですか？`)) return;
    toggleDownloading();
    try {
      await checkedRooms
        .filter((_) => _.log?.size)
        .reduce(async (prev: Promise<{ roomId: string; result: 'success' | 'failed' }[]>, room: IRoom) => {
          const results = await prev;
          setCurrentDownloadingId(room.id);
          const result = await downloadRoomLogs(room);
          const newResults = [...results, { roomId: room.id, result }];
          setDownloadedResults(newResults);
          return newResults;
        }, Promise.resolve([]));
    } finally {
      setCheckedRooms([]);
      toggleDownloading();
    }
  };

  return currentUser?.isSysAdmin ? (
    <div className="container py-5 position-relative">
      <Card className="my-5">
        <CardBody>
          <div className="d-flex justify-content-center">
            <h4>ログ一覧</h4>
          </div>
          <div className="d-flex flex-row-reverse mb-2">
            <Button color="primary" onClick={onDownload} disabled={Boolean(!checkedRooms.length)}>
              <i className="fas fa-download me-1"></i>
              ダウンロード
            </Button>
          </div>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>ルーム名</th>
                <th>説明</th>
                <th>サイズ</th>
                <th>最終ログ出力</th>
              </tr>
            </thead>
            <tbody>{rooms.map((room) => RoomRow(room))}</tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  ) : (
    <Navigate to="/" />
  );
});
