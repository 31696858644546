import firebase from 'firebase/compat/app';
import React, { useContext } from 'react';
import { Badge, Button, Input } from 'reactstrap';
import { IDeck, DeckCard, IStack, IHand } from '../../../models/database';
import NumberCounter from '../../NumberCounter';
import { RoomContext } from '../../pages/Room';
import DeckImageCardRow from './DeckImageCardRow';

type Props = {
  deck: IDeck;
  stacks: IStack[];
  deckCardRef: firebase.database.Reference;
  deckCard: DeckCard;
  index: number;
  hands: IHand[];
};

export default function BothImageRow(props: Props) {
  const { deck, stacks, deckCardRef, deckCard, index = 0, hands } = props;
  const { roomRef } = useContext(RoomContext);

  const onChangeName = async ({ target: { value } }: { target: { value: string } }) => {
    if (!deckCardRef || !deckCard) return;
    await deckCardRef.update({ name: value });
  };

  const onChangeCount = async (count: number) => {
    if (!deckCardRef || !deckCard || !roomRef) return;
    await deck.changeImageCount(deckCardRef, deckCard, count, stacks, roomRef);
  };

  const onClickRemove = async () => {
    if (!deckCardRef || !deckCardRef.parent || !roomRef) return;
    if (!window.confirm('この行を削除しますか？')) return;
    deckCard && (await deck.deleteImageRow(deckCard, deckCardRef, stacks, hands, roomRef.child('objects/cards')));
  };

  const onChangeFrontUrl = async (url: string) => {
    if (!deckCard || !roomRef) return;
    deck.changeImageUrl(deckCard.cardIds, 'frontImg', url, roomRef.child('objects/cards'));
  };

  const onChangeBackUrl = async (url: string) => {
    if (!deckCard || !roomRef) return;
    deck.changeImageUrl(deckCard.cardIds, 'backImg', url, roomRef.child('objects/cards'));
  };

  return (
    <div key={index} className="p-1 mb-1" style={{ border: 'dashed 1px darkgray', borderRadius: '0.5rem' }}>
      <div className="d-flex align-items-center p-1">
        <Badge className="me-1" color="secondary">
          {index + 1}
        </Badge>
        <Input className="me-1" value={deckCard.name} onChange={onChangeName} placeholder="Card Label" />
        <NumberCounter className="me-1" onChange={onChangeCount} value={deckCard.count} disabled={!Boolean(deckCard.front)} />
        <Button onClick={onClickRemove} size="sm" color="danger" outline>
          <i className="fas fa-trash-alt"></i>
        </Button>
      </div>
      <span>Front Image</span>
      <DeckImageCardRow
        deck={deck}
        cardImageRowRef={deckCardRef.child('front')}
        cardImage={deckCard.front || null}
        onChangeUrl={onChangeFrontUrl}
      />
      <span>Back Image</span>
      <DeckImageCardRow
        deck={deck}
        cardImageRowRef={deckCardRef.child('back')}
        cardImage={deckCard.back || null}
        onChangeUrl={onChangeBackUrl}
      />
    </div>
  );
}
