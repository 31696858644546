import { Route, Routes } from 'react-router';

import Dashboard from './components/pages/Dashboard';
import Room from './components/pages/Room';
import Users from './components/pages/Users';
import Logs from './components/pages/sysadmin/logs/Logs';
import Groups from './components/pages/Groups';
import Signup from './components/pages/SignUp';
import Signin from './components/pages/SignIn';
import TermsOfService from './components/pages/TermsOfService';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import NotFound from './components/pages/NotFound';

const routeObjs: { exact: boolean; path: string; component: any; render?: any; props?: any }[] = [
  {
    exact: true,
    path: '/',
    component: Dashboard,
  },
  {
    exact: true,
    path: '/rooms/:roomId',
    component: Room,
  },
  {
    exact: true,
    path: '/groups/:groupId/users',
    component: Users,
  },
  {
    exact: true,
    path: '/sysadmin/logs',
    component: Logs,
  },
  {
    exact: true,
    path: '/groups',
    component: Groups,
  },
  {
    exact: true,
    path: '/signup',
    component: Signup,
  },
  {
    exact: true,
    path: '/signin',
    component: Signin,
  },
  {
    exact: true,
    path: '/terms-of-service',
    component: TermsOfService,
  },
  {
    exact: true,
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
];

export default function routes(extraProps: any) {
  return (
    <Routes>
      {routeObjs.map((route, i) => {
        const { path, props } = route;
        return <Route path={path} element={<route.component {...extraProps} {...props} />} key={i} />;
      })}
      <Route path="*" element={<NotFound {...extraProps} />} />
    </Routes>
  );
}
