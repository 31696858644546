import firebase from 'firebase/compat/app';

export interface ICard {
  key: string;
  ref: firebase.database.Reference | null;
  width: number;
  height: number;
  frontImg: string;
  backImg: string;
  deckId: string;
  toObject: () => {};
}

export class Card implements ICard {
  key: string = '';
  ref: firebase.database.Reference | null = null;
  width: number = 0;
  height: number = 0;
  frontImg: string = '';
  backImg: string = '';
  deckId: string = '';

  constructor({
    key = '',
    ref = null,
    width = 0,
    height = 0,
    frontImg = '',
    backImg = '',
    deckId = '',
  }: Partial<ICard>) {
    Object.assign(this, {
      key,
      ref,
      width,
      height,
      frontImg,
      backImg,
      deckId,
    });
  }

  toObject(): {} {
    return {
      width: this.width,
      height: this.height,
      frontImg: this.frontImg,
      backImg: this.backImg,
      deckId: this.deckId,
    };
  }
}
