import { ReactNode } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ButtonProps, ModalProps } from 'reactstrap';
import { useToggle } from 'react-use';

export const ModalButton = ({
  modalTitle,
  modalContent,
  modalProps,
  ...props
}: {
  modalTitle?: string;
  modalContent: ({ close }: { close: () => void }) => ReactNode;
  modalProps?: ModalProps;
} & Omit<ButtonProps, 'onClick'>) => {
  const [isOpen, toggle] = useToggle(false);

  return (
    <>
      <Button {...props} onClick={toggle} />
      <Modal {...modalProps} isOpen={isOpen} toggle={toggle}>
        {modalTitle && <ModalHeader>{modalTitle}</ModalHeader>}
        <ModalBody>{modalContent({ close: toggle })}</ModalBody>
      </Modal>
    </>
  );
};
