import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

import { firestore } from '../../firebase';
import { IRoom, ISnapshot, IUser, Snapshot } from '../../models/firestore';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import { EDIT_MODAL_ZINDEX } from '../../shared/config';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  room: IRoom;
  currentUser: IUser;
};

const snapshotsRef = firestore.collection('snapshots');

export default function ImportSnapshotModal(props: Props) {
  const { isOpen, onClose, currentUser } = props;
  const filteredSnapshotsRef = currentUser.isSysAdmin ? snapshotsRef : snapshotsRef.where('owner', '==', currentUser.id);
  const snapshots = useCollectionSubscription(filteredSnapshotsRef, Snapshot);

  return (
    <Modal isOpen={isOpen} toggle={onClose} zIndex={EDIT_MODAL_ZINDEX} size="lg">
      <ModalHeader>Load Snapshot</ModalHeader>
      <ModalBody>
        <Table>
          <thead>
            <tr>
              <th>name</th>
              <th>comment</th>
              <th>created At</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {snapshots.map((snapshot) => (
              <ImportSnapshotRow key={snapshot.id} snapshot={snapshot} {...props} />
            ))}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const ImportSnapshotRow = (props: { room: IRoom; snapshot: ISnapshot; onClose: () => void; currentUser: IUser }) => {
  const { room, snapshot, onClose, currentUser } = props;

  const onLoad = async () => {
    try {
      await room.import(snapshot, currentUser);
      toast.success('スナップショットを反映しました');
      onClose();
    } catch (e) {
      console.error(e);
      toast.error('スナップショットの反映に失敗しました');
    }
  };

  const onDelete = async () => {
    if (!window.confirm('本当に削除しますか？')) return;

    try {
      await snapshot.ref?.delete();
      toast.success('スナップショットを削除しました');
    } catch (e) {
      console.error(e);
      toast.error('スナップショットの削除に失敗しました');
    }
  };

  return (
    <tr key={snapshot.id}>
      <td>{snapshot.name}</td>
      <td>
        <p>{snapshot.comment}</p>
      </td>
      <td>{format(snapshot.createdAt.toDate(), 'yyyy-MM-dd HH:mm')}</td>
      <td className="d-flex justify-content-end">
        {currentUser?.canDeleteSnapshot(snapshot) && (
          <Button color="danger" onClick={onDelete} className="me-2">
            Delete
          </Button>
        )}
        <Button color="primary" onClick={onLoad}>
          Load
        </Button>
      </td>
    </tr>
  );
};
