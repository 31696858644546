import React from 'react';

type Props = {
  color?: string;
};

export function HolderIcon(props: Props) {
  const { color = 'black' } = props;

  const container: React.CSSProperties = {
    height: '64px',
    width: '64px',
    position: 'relative',
  };

  const icon: React.CSSProperties = {
    height: '48px',
    width: '48px',
    left: '8px',
    position: 'relative',
  };

  const holder: React.CSSProperties = {
    display: 'block',
    width: '32px',
    height: '40px',
    position: 'absolute',
    top: '4px',
    left: '8px',
    border: `solid 2px ${color}`,
    borderRadius: '4px',
    backgroundColor: '#fff',
  };

  const name: React.CSSProperties = {
    position: 'relative',
    height: '16px',
  };

  return (
    <div style={container}>
      <div style={icon}>
        <div style={holder} />
      </div>
      <div style={name}>Holder</div>
    </div>
  );
}
