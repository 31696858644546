import { Navigate, Link } from 'react-router-dom';

import useCollectionSubscription from '../hooks/useCollectionSubscription';
import { firestore as db } from '../../firebase';
import { IGroup, Group, groupFormFields } from '../../models/firestore/group';
import { Invitation } from '../../models/firestore';
import Page from '../hocs/Page';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ModelFormModal from '../modals/ModelFormModal';

const groupsRef = db.collection('groups');
const invitationsRef = db.collection('invitations');

export default Page(function Groups(props: any) {
  const { currentUser } = props;
  const visibleGroupsRef = currentUser?.isSysAdmin ? groupsRef : groupsRef.where('userIds', 'array-contains', currentUser.id);
  const groups = useCollectionSubscription(visibleGroupsRef, Group, []);
  // TODO: currentUserがグループ管理者のグループへの招待だけを取得するように
  const invitations = useCollectionSubscription(invitationsRef, Invitation, []);
  // グループ作成時にクエリ条件で使用しているuserIdsフィールドを作成しておく
  const processValues = (values: any) => {
    return {
      ...values,
      userIds: [],
    };
  };

  return currentUser?.isSysAdmin || currentUser?.isSomeGroupAdmin() ? (
    <div>
      <div className="container py-5 position-relative">
        <h4>グループ一覧</h4>
        {currentUser?.isSysAdmin && (
          <div className="d-flex justify-content-end">
            <AddButton
              itemRef={groupsRef.doc()}
              FormModal={ModelFormModal}
              processValues={processValues}
              formProps={{ title: 'グループ作成', fields: groupFormFields }}
            />
          </div>
        )}
        <table className="table table-bordered mt-4">
          <thead>
            <tr>
              <th>グループID</th>
              <th>グループ名</th>
              <th>参加人数</th>
              {currentUser?.isSysAdmin && <th></th>}
            </tr>
          </thead>
          <tbody>
            {groups
              .filter((_) => currentUser.isSysAdmin || currentUser.isGroupAdmin(_.id))
              .map((group: IGroup) => {
                const { id, ref, name, capacity, userIds } = group;
                const groupInvitations = invitations.filter((_) => _.groupId === id);
                const userCount = userIds.length + groupInvitations.length;

                return (
                  <tr key={id}>
                    <td>{id}</td>
                    <td>{name}</td>
                    <td>
                      <Link to={`/groups/${id}/users`}>{capacity ? `${userCount} ／ ${capacity} 人` : `${userCount} 人`}</Link>
                    </td>
                    {currentUser?.isSysAdmin && (
                      <td className="text-end text-nowrap">
                        <EditButton
                          initialValues={group}
                          itemRef={ref}
                          FormModal={ModelFormModal}
                          formProps={{ title: 'グループ編集', fields: groupFormFields }}
                          className="ms-2"
                        />
                        <DeleteButton storeRef={ref} className="ms-2" />
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <Navigate to="/" />
  );
});
