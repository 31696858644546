import React from 'react';

import HolderComponent from './Holder';
import { IHolder } from '../models/database';

type Props = {
  holders: IHolder[];
};

export default function HolderController(props: Props) {
  const { holders } = props;

  return (
    <>
      {holders.map((holder: IHolder) => {
        return <HolderComponent key={holder.key} {...props} holder={holder} />;
      })}
    </>
  );
}
