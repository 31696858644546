import firebase from 'firebase/compat/app';
import { useState, useEffect } from 'react';
import { IHand, Hand } from '../../models/database';
import { IUser } from '../../models/firestore';

export default function useHand(user: IUser | undefined, roomRef: firebase.database.Reference | undefined): IHand | undefined {
  const { id: key, name } = user || {};
  const [hand, setHand] = useState<IHand>();

  useEffect(() => {
    if (!key || !roomRef) return;
    const ref = roomRef.child('objects').child('hands').child(key);

    const callback = (snapshot: firebase.database.DataSnapshot) => {
      setHand(new Hand({ key, ref, ...(snapshot.val() || {}) }));
    };

    ref.once('value', (snapshot: firebase.database.DataSnapshot) => {
      if (snapshot.exists()) return;

      ref.update(new Hand({ name: `${name} の手札` }).toObject());
    });
    ref.on('value', callback);

    return () => ref && ref.off('value', callback);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return hand;
}
