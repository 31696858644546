import React from 'react';
import { Link } from 'react-router-dom';
import { FOOTER_ZINDEX } from '../shared/config';

export default function Footer(props: any) {

  const footerStyle: React.CSSProperties = {
    borderTop: "1px solid #d3d3d3",
    padding: "12px 0px",
    zIndex: FOOTER_ZINDEX
  }

  const linksContainerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '24px',
    fontSize: '14px',
    color: '#333333',
  };

  const copyrightStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '24px',
    fontSize: '12px',
    color: '#333333',
  };

  const linkStyle: React.CSSProperties = {
    color: '#333333',
  };

  return (
    <footer style={footerStyle}>
      <div style={linksContainerStyle}>
        <Link className="me-4" to="/privacy-policy" style={linkStyle}>プライバシーポリシー</Link>
        <Link className="me-4" to="/terms-of-service" style={linkStyle}>利用規約</Link>
      </div>
      <div style={copyrightStyle}>
        Copyright
        <i className="far fa-copyright ms-1"></i>
        <a className="mx-1" style={linkStyle} href="https://so-guu.com/" target="_blank" rel="noopener noreferrer">
          ビジネスゲームの開発・研修の株式会社 遭遇設計
        </a>
        All Rights Reserved
      </div>
    </footer>
  );
}
