import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import { Badge, Button, Card, CardBody, CardTitle } from 'reactstrap';
import { IDeck, IStack, IHand } from '../models/database';
import DeckEditModal from './modals/DeckEditModal';
import { RoomContext } from './pages/Room';
import DraggableObject from './DraggableObject';
import RecallButton from './RecallButton';
import ShuffleButton from './ShuffleButton';

type Props = {
  deck: IDeck;
  stacks: IStack[];
  hands: IHand[];
};

export default function Deck(props: Props) {
  const { deck, stacks, hands } = props;
  const { roomRef, zIndexRef, scale = 1.0, mode } = useContext(RoomContext);
  const [showModal, toggleModal] = useToggle(false);
  const isEdit = mode === 'edit';

  const deckStyle: React.CSSProperties = {
    width: deck.width * scale,
    height: deck.height * scale,
  };
  const editStyle: React.CSSProperties = {
    backgroundColor: '#fff',
    border: 'solid 1px gray',
    borderRadius: '10px',
    position: 'relative',
    top: '-4px',
    left: '-4px',
    width: 'calc(100% + 8px)',
    height: 'calc(100% + 8px)',
  };
  const playStyle = { ...editStyle };
  const labelStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row-reverse',
    position: 'relative',
    top: `-${deck.height * scale + 32}px`,
  };

  const moveToFront = () => {
    if (!deck.ref || !roomRef || !zIndexRef) return;

    deck.ref.update({ z: ++zIndexRef.current });
    roomRef.update({ frontZ: zIndexRef.current });
  };

  const onRemove = async () => {
    if (!window.confirm('このDeckを削除してよろしいですか？') || !deck.ref || !roomRef) return;

    deck.deleteCards(deck.allCardIds, stacks, hands, roomRef.child('objects/cards'));
    await deck.ref.remove();
    toast.warn('Deckを削除しました');
  };

  const onDrag = () => {
    const stack = stacks.find((_) => _.holderId === deck.key);
    stack?.ref?.update({ holderId: null });
  };

  return (
    <DraggableObject
      object={deck}
      onStart={moveToFront}
      onDrag={onDrag}
      disabled={!isEdit}
      style={{ zIndex: isEdit ? deck.z : 0 }}
    >
      <>
        <div style={deckStyle}>
          {isEdit ? (
            <Card style={editStyle}>
              <CardTitle className="panningDisabled d-flex justify-content-between p-1" style={{ fontSize: `${14 * scale}px` }}>
                <Badge color="primary">
                  Deck
                </Badge>
                <Button close onClick={onRemove} />
              </CardTitle>
              <CardBody className="panningDisabled p-1 d-flex flex-column">
                <h1 className="text-center">{deck.deckCards.reduce((_, { count }) => _ + count, 0)}</h1>
                <Button outline size="sm" color="primary" className="mt-auto w-100" onClick={toggleModal}>
                  Edit Deck
                </Button>
              </CardBody>
              <DeckEditModal {...props} isOpen={showModal} onClose={toggleModal} />
            </Card>
          ) : (
            <div style={playStyle}></div>
          )}
          {deck.label != null && (
            <div style={labelStyle} className="panningDisabled">
              <span style={{ fontSize: `${16 * scale}px`, color: 'gray' }}>{deck.label}</span>
            </div>
          )}
        </div>
        {!isEdit && (
          <div className="d-flex justify-content-center" style={{ position: 'relative', top: '8px' }}>
            <RecallButton {...props} className="me-1" />
            <ShuffleButton {...props} />
          </div>
        )}
      </>
    </DraggableObject>
  );
}
