import { useCallback, useState } from 'react';
import { Button, Input, ButtonProps } from 'reactstrap';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { readCSVFile } from '../util';

export const ImportButton = ({
  importProcess,
  encoding = 'utf-8',
  ...props
}: { importProcess: (rows: any[]) => Promise<boolean>; encoding?: string } & ButtonProps) => {
  const [isImporting, setIsImporting] = useState(false);
  const handleChange = useCallback(
    async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = target;
      const file = files?.[0];
      if (!file) return;

      try {
        setIsImporting(true);
        const rows = await readCSVFile(file, encoding);
        if (!(await importProcess(rows))) throw new Error('インポートに失敗しました');
        toast.success('インポートしました');
      } catch (e) {
        console.error(e);
        toast.error('インポートに失敗しました');
      } finally {
        target.value = '';
        setIsImporting(false);
      }
    },
    [setIsImporting, encoding, importProcess]
  );

  return (
    <Button disabled={isImporting} {...props}>
      <label className="m-0 cursor-pointer" role="button">
        <span className={classnames('fas me-1', { 'fa-upload': !isImporting, 'fa-spin fa-spinner': isImporting })} />
        インポート
        <Input type="file" className="d-none" onChange={handleChange} accept=".csv" />
      </label>
    </Button>
  );
};
