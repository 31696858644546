import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import { Badge, Button, Card, CardBody, CardTitle } from 'reactstrap';
import { IHolder } from '../models/database';
import HolderEditModal from './modals/HolderEditModal';
import { RoomContext } from './pages/Room';
import DraggableObject from './DraggableObject';

type Props = {
  holder: IHolder;
};

export default function Holder(props: Props) {
  const { holder } = props;
  const { roomRef, zIndexRef, scale = 1.0, mode } = useContext(RoomContext);
  const [showModal, toggleModal] = useToggle(false);
  const isEdit = mode === 'edit';

  const holderStyle: React.CSSProperties = {
    width: holder.width * scale,
    height: holder.height * scale,
  };
  const editStyle: React.CSSProperties = {
    backgroundColor: '#fff',
    border: 'solid 1px gray',
    borderRadius: '10px',
    position: 'relative',
    top: '-4px',
    left: '-4px',
    width: 'calc(100% + 8px)',
    height: 'calc(100% + 8px)',
  };
  const playStyle = { ...editStyle };
  const labelStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row-reverse',
    position: 'relative',
    top: `-${holder.height * scale + 32}px`,
  };

  const moveToFront = () => {
    if (!holder.ref || !roomRef || !zIndexRef) return;

    holder.ref.update({ z: ++zIndexRef.current });
    roomRef.update({ frontZ: zIndexRef.current });
  };

  const onRemove = () => {
    if (!window.confirm('このHolderを削除してよろしいですか？') || !holder.ref) return;

    holder.ref.remove();
    toast.warn('Holderを削除しました');
  };

  return (
    <DraggableObject object={holder} onStart={moveToFront} disabled={!isEdit} style={{ zIndex: isEdit ? holder.z : 0 }}>
      <div style={holderStyle} className="panningDisabled">
        {isEdit ? (
          <Card style={editStyle}>
            <CardTitle className="panningDisabled d-flex justify-content-between p-1" style={{ fontSize: `${14 * scale}px` }}>
              <Badge color="info" className="panningDisabled">
                Holder
              </Badge>
              <Button close onClick={onRemove} />
            </CardTitle>
            <CardBody className="p-1 d-flex flex-column panningDisabled">
              <Button outline size="sm" color="primary" className="mt-auto w-100" onClick={toggleModal}>
                Edit Holder
              </Button>
            </CardBody>
            <HolderEditModal isOpen={showModal} onClose={toggleModal} holder={holder} />
          </Card>
        ) : (
          <div style={playStyle}></div>
        )}
        {holder.label != null && (
          <div style={labelStyle} className="panningDisabled">
            <span style={{ fontSize: `${16 * scale}px`, color: 'gray' }}>{holder.label}</span>
          </div>
        )}
      </div>
    </DraggableObject>
  );
}
