import { useState, useEffect } from 'react';

const { entries } = Object;

export default function useFormState (values: any, fields: any, shouldReset: any) {
  const initialState = entries(fields)
    .reduce((x: any, [fieldName, fieldSetting]: any) => {
      const { type, initialValue } = fieldSetting;
      const _value = (values || {})[fieldName];
      const toDateInSomeCases = (v: any) => (v && v.toDate) ? v.toDate() : v;
      const types: { [key: string]: () => void } = {
        datetime: () => toDateInSomeCases(_value),
        date: () => toDateInSomeCases(_value),
        time: () => toDateInSomeCases(_value),
      };
      const value = (types[type] || ((_: any) => _value))();
      return {
        ...x,
        [fieldName]: value != null ? value : initialValue != null ? initialValue : null,
      };
    }, {});
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if(shouldReset) setState(initialState);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, shouldReset]);

  return entries(fields).reduce((x: any, [fieldName, fieldSetting]: any) => {
    const { validations = {}, hidden = (_: any) => false } = fieldSetting;
    const shouldHide = hidden(state);
    const value = shouldHide ? null : state[fieldName];
    const validationErrors = shouldHide ? [] : entries(validations)
      .filter(([k, v]: any) => !v(value, state, fieldName))
      .map(([k]) => k);
    return {
      ...x,
      [fieldName]: {
        ...fieldSetting,
        value,
        setValue: (v: any) => setState({ ...state, [fieldName]: v }),
        validationErrors,
        isValid: validationErrors.length === 0,
        shouldHide,
      },
    };
  }, {});
};
