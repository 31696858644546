import firebase from 'firebase/compat/app';
import { DEFAULT_CARD_WIDTH, DEFAULT_CARD_HEIGHT, DEFAULT_SCALE } from '../../shared/config';
import { LogSpace } from '../firestore';

type ActualSize = {
  width: number;
  height: number;
  scale: number;
}

export interface IHolder {
  key: string;
  ref: firebase.database.Reference | null;
  type: string;
  x: number;
  y: number;
  z: number;
  width: number;
  height: number;
  actualSize: ActualSize;
  label: string;
  toObject: () => {};
  toLogFormat: LogSpace;
}

export class Holder implements IHolder {
  key: string = '';
  ref: firebase.database.Reference | null = null;
  type: string = '';
  x: number = 0;
  y: number = 0;
  z: number = 0;
  width: number = 0;
  height: number = 0;
  actualSize: ActualSize = {
    width: DEFAULT_CARD_WIDTH,
    height: DEFAULT_CARD_HEIGHT,
    scale: DEFAULT_SCALE,
  };
  label: string = '';

  constructor({
    key = '',
    ref = null,
    type = '',
    x = 0,
    y = 0,
    z = 0,
    width = DEFAULT_CARD_WIDTH * DEFAULT_SCALE,
    height = DEFAULT_CARD_HEIGHT * DEFAULT_SCALE,
    actualSize = {
      width: DEFAULT_CARD_WIDTH,
      height: DEFAULT_CARD_HEIGHT,
      scale: DEFAULT_SCALE,
    },
    label = '',
  }: Partial<IHolder>) {
    Object.assign(this, {
      key,
      ref,
      type,
      x,
      y,
      z,
      width,
      height,
      actualSize,
      label,
    });
  }

  toObject(): {} {
    return {
      type: this.type,
      x: this.x,
      y: this.y,
      z: this.z,
      width: this.width,
      height: this.height,
      actualSize: this.actualSize,
      label: this.label,
    };
  }

  get toLogFormat(): LogSpace {
    return { type: 'holder', key: this.key, name: this.label, remarks: '' };
  }
}
