import firebase from 'firebase/compat/app';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';

import { database } from '../firebase';
import { DeckIcon, HolderIcon, BoardIcon } from './icons';
import { Deck, Holder, Board } from '../models/database';
import { BOARD_WIDTH, BOARD_HEIGHT, MENU_ZINDEX } from '../shared/config';

type Props = {
  className?: string;
  left?: number;
};

const COLOR_INFO = 'rgb(23, 162, 184)';
const databaseRoomsRef = database.ref('rooms');

export default function EditMenu(props: Props) {
  const {
    className = '',
    left = 0,
  } = props;
  const { roomId } = useParams<{ roomId: string }>();
  const [frontZ, setFrontZ] = useState<number>(0);
  const databaseRoomRef = databaseRoomsRef.child(roomId!);
  const databaseRoomDecksRef = databaseRoomRef.child('objects').child('decks');
  const databaseRoomHoldersRef = databaseRoomRef.child('objects').child('holders');
  const databaseRoomBoardsRef = databaseRoomRef.child('objects').child('boards');
  const menuStyle: React.CSSProperties = {
    position: 'absolute',
    zIndex: MENU_ZINDEX,
    left,
  };

  useEffect(() => {
    const callback = (snapshot: firebase.database.DataSnapshot) => {
      if (!snapshot) return;
      setFrontZ(snapshot.val());
    };
    databaseRoomRef.child('frontZ').on('value', callback);
    return () => databaseRoomRef.child('frontZ').off('value', callback);
  }, [databaseRoomRef]);

  const onClickDeck = async () => {
    const deck = new Deck({ type: 'deck', x: BOARD_WIDTH / 2, y: BOARD_HEIGHT / 2, z: frontZ + 1 });
    await Promise.all([databaseRoomDecksRef.push(deck.toObject()), databaseRoomRef.update({ frontZ: deck.z })]);
    toast.info('Deckを追加しました');
  };

  const onClickHolder = async () => {
    const holder = new Holder({ type: 'holder', x: BOARD_WIDTH / 2, y: BOARD_HEIGHT / 2, z: frontZ + 1 });
    await Promise.all([databaseRoomHoldersRef.push(holder.toObject()), databaseRoomRef.update({ frontZ: holder.z })]);
    toast.info('Holderを追加しました');
  };

  const onClickBoard = async () => {
    const board = new Board({ type: 'board', x: BOARD_WIDTH / 3, y: BOARD_HEIGHT / 3, z: frontZ + 1 });
    await Promise.all([databaseRoomBoardsRef.push(board.toObject()), databaseRoomRef.update({ frontZ: board.z })]);
    toast.info('Boardを追加しました');
  };

  return (
    <div className={`d-flex ${className}`} style={menuStyle}>
      <Button outline color="info" className="m-1" onClick={onClickDeck}>
        <DeckIcon color={COLOR_INFO} />
      </Button>
      <Button outline color="info" className="m-1" onClick={onClickHolder}>
        <HolderIcon color={COLOR_INFO} />
      </Button>
      <Button outline color="info" className="m-1" onClick={onClickBoard}>
        <BoardIcon color={COLOR_INFO} />
      </Button>
    </div>
  );
}
