import { Button } from 'reactstrap';
import { useCSVDownloader } from 'react-papaparse';

export const ExportButton = ({ data }: { data: any[] }) => {
  const { CSVDownloader, Type } = useCSVDownloader();

  return (
    <Button>
      <label className="m-0 cursor-pointer" role="button">
        <CSVDownloader className="d-none" type={Type.Button} filename={'users'} bom config={{ delimiter: ',' }} data={data} />
        <span className="fas fa-download me-1" />
        エクスポート
      </label>
    </Button>
  );
};
