import firebase from 'firebase/compat/app';
import { orderBy } from 'lodash';
import useDatabaseSubscription from './useDatabaseSubscription';
import { Stack, IStack } from '../../models/database';

export default function useStacksSubscription(roomRef: firebase.database.Reference | undefined, dependencies: any[] = []): IStack[] {
  const stacks = useDatabaseSubscription(roomRef && roomRef.child('objects').child('stacks'), Stack, dependencies);
  const sortedStacks = orderBy(stacks, ['z'], ['asc']);

  return sortedStacks;
}
