import firebase from 'firebase/compat/app';
import { FieldValue } from '../../firebase';
import { IRoom, ISnapshot } from '.';

export type Role = 'sysadmin' | 'admin' | 'creator' | 'player';
export type GroupRole = 'admin' | 'creator' | 'player';
type Group = {
  [key: string]: {
    role: GroupRole;
  };
};
export const groupRoles = [
  { label: 'グループ管理者', value: 'admin' },
  { label: 'ゲーム制作者', value: 'creator' },
  { label: 'プレイヤー', value: 'player' },
];
export interface IUser {
  id: string;
  ref: firebase.firestore.DocumentReference | null;
  name: string;
  email: string;
  role: Role;
  groupIds: string[];
  groups: Group;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  isSysAdmin: boolean;
  isGroupAdmin: (groupId: string) => boolean;
  isSomeGroupAdmin: () => boolean;
  canEdit: (room: IRoom) => boolean;
  canCreateRoom: () => boolean;
  canEditRoom: () => boolean;
  canDeleteRoom: () => boolean;
  canRemoveGroupUser: (groupId: string) => boolean;
  canEditGroupUser: () => boolean;
  canEditGroupUserPassword: (groupId: string) => boolean;
  canUseSnapshot: (room: IRoom) => boolean;
  canDeleteSnapshot: (snapshot: ISnapshot) => boolean;
}

export class User implements IUser {
  id: string = '';
  ref: firebase.firestore.DocumentReference | null = null;
  name: string = '';
  email: string = '';
  role: Role = 'player';
  groupIds: string[] = [];
  groups: Group = {};
  createdAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
  updatedAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp;

  constructor({
    id = '',
    ref = null,
    name = '',
    email = '',
    role = 'player',
    groupIds = [],
    groups = {},
    createdAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    updatedAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
  }: Partial<IUser>) {
    Object.assign(this, {
      id,
      ref,
      name,
      email,
      role,
      groupIds,
      groups,
      createdAt,
      updatedAt,
    });
  }

  toObject(): {} {
    return {
      name: this.name,
      email: this.email,
      role: this.role,
      groupIds: this.groupIds,
      groups: this.groups,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  get isSysAdmin(): boolean {
    return this.role === 'sysadmin';
  }

  isGroupAdmin(groupId: string): boolean {
    const { role } = this.groups[groupId] || {};

    return role === 'admin';
  }

  isSomeGroupAdmin(): boolean {
    const groupRoles = Object.values(this.groups).map((_) => _.role);
    return groupRoles.some((_) => _ === 'admin');
  }

  canEdit(room: IRoom): boolean {
    const { groupId } = room;
    return this.isSysAdmin || ['admin', 'creator'].includes(this.groups[groupId].role);
  }

  canCreateRoom(): boolean {
    return ['sysadmin'].includes(this.role);
  }

  canEditRoom(): boolean {
    return ['sysadmin'].includes(this.role);
  }

  canDeleteRoom(): boolean {
    return ['sysadmin'].includes(this.role);
  }

  canRemoveGroupUser(groupId: string): boolean {
    return ['sysadmin'].includes(this.role) || this.isGroupAdmin(groupId);
  }

  canEditGroupUser(): boolean {
    return ['sysadmin'].includes(this.role);
  }

  canEditGroupUserPassword(groupId: string): boolean {
    return this.isSysAdmin || this.isGroupAdmin(groupId);
  }

  canUseSnapshot(room: IRoom): boolean {
    const { groupId } = room;
    return this.isSysAdmin || ['creator'].includes(this.groups[groupId].role);
  }

  canDeleteSnapshot(snapshot: ISnapshot): boolean {
    return this.isSysAdmin || snapshot.owner === this.id;
  }
}
