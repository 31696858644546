import React, { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';

type Props = {
  className?: string;
  value: number;
  onChange: (count: number) => void;
  disabled: boolean;
};

export default function NumberCounter(props: Props) {
  const { className = '', onChange, value = 0, disabled } = props;
  const [count, setCount] = useState<number>(value);

  useEffect(() => {
    if (disabled) {
      setCount(0);
      onChange(0);
    }
  }, [disabled, onChange])

  const onChangeInput = ({ target: { value } }: { target: { value: string } }) => {
    const num = Number(value);
    if (isNaN(num)) return;
    if (num >= 0) {
      setCount(num);
      onChange(num);
    }
  };

  const onChangePlus = () => {
    const num = count + 1;
    setCount(num);
    onChange(num);
  };

  const onChangeMinus = () => {
    const num = count - 1;
    if (num >= 0) {
      setCount(num);
      onChange(num);
    }
  };

  return (
    <span className={`d-flex ${className}`}>
      <Button onClick={onChangeMinus} size="sm" color="primary" outline className="me-1" disabled={disabled}>
        <i className="fas fa-minus"></i>
      </Button>
      <Input
        className="me-1"
        bsSize="sm"
        type="number"
        value={count}
        style={{ width: 64 }}
        onChange={onChangeInput}
        min="0"
        disabled={disabled}
      />
      <Button onClick={onChangePlus} size="sm" color="primary" outline disabled={disabled}>
        <i className="fas fa-plus"></i>
      </Button>
    </span>
  );
}
