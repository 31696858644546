import firebase from 'firebase/compat/app';
import React, { useContext } from 'react';
import { storage } from '../../../firebase';
import { IDeck, CardImage } from '../../../models/database';
import ImageFileUploader from '../../ImageFileUploader';
import { RoomContext } from '../../pages/Room';


type Props = {
  deck: IDeck;
  cardImageRowRef: firebase.database.Reference;
  cardImage: CardImage | null;
  onChangeUrl: (url: string) => void;
};

export default function DeckImageCardRow(props: Props) {
  const { deck, cardImageRowRef, cardImage, onChangeUrl } = props;
  const { room } = useContext(RoomContext);
  const isUploaded = Boolean(cardImage && cardImage.url);
  const onChangeImage = async (file: File) => {
    if (!cardImageRowRef || !file) return;

    // TODO: gameIdが設定されるようになるまで、仮でroomIdをいれとく
    const ref = `games/${room && (room.snapshotId || room.id)}/${deck.key}/${file.name}`;
    const metadata = { cacheControl: 'max-age=31536000' };
    const storageRef = storage.ref().child(ref);
    await storageRef.put(file, metadata);
    const url = await storageRef.getDownloadURL();
    await cardImageRowRef.set({ fileName: file.name, url, ref });
    onChangeUrl(url);
  };

  const onRemoveImage = async () => {
    if (!cardImageRowRef || !cardImage) return;
    await cardImageRowRef.remove();
  };

  return (
    <div className="d-flex p-1" style={{ backgroundColor: '#f5f5f5', borderRadius: '5px' }}>
      {cardImage && cardImage.url != null && <img src={cardImage.url} height="96px" alt="card preview" />}
      <div className="w-100 ms-1">
        <div className="d-flex align-items-start">
          <ImageFileUploader className="ms-auto" isUploaded={isUploaded} onChange={onChangeImage} onRemove={onRemoveImage} />
        </div>
        <span>{cardImage?.fileName}</span>
      </div>
    </div>
  );
}
