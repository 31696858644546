import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import { Badge, Button, Card, CardBody, CardTitle } from 'reactstrap';
import { IBoard } from '../models/database';
import { BoardEditModal } from './modals/BoardEditModal';
import { RoomContext } from './pages/Room';
import DraggableObject from './DraggableObject';

export const Board = ({ board }: { board: IBoard }) => {
  const { scale = 1.0, mode } = useContext(RoomContext);
  const [showModal, toggleModal] = useToggle(false);
  const isEdit = mode === 'edit';

  const boardStyle: React.CSSProperties = {
    width: board.width * scale,
    height: board.height * scale,
  };
  const commonStyle: React.CSSProperties = {
    position: 'relative',
    width: '100%',
    height: '100%',
  };
  const editStyle: React.CSSProperties = {
    ...commonStyle,
    backgroundColor: '#fff',
    border: 'solid 1px gray',
  };
  const playStyle: React.CSSProperties = {
    ...commonStyle,
  };

  const onRemove = async () => {
    if (!window.confirm('このBoardを削除してよろしいですか？') || !board.ref) return;

    await board.ref.remove();
    toast.warn('Boardを削除しました');
  };

  return (
    <DraggableObject object={board} disabled={!isEdit} style={{ zIndex: 0 }}>
      <div style={boardStyle} className={`${isEdit && 'panningDisabled'}`}>
        {isEdit ? (
          <Card style={editStyle}>
            <CardTitle className="panningDisabled d-flex justify-content-between p-1" style={{ fontSize: `${14 * scale}px` }}>
              <Badge color="info" className="panningDisabled">
                Board
              </Badge>
              <Button close onClick={onRemove} />
            </CardTitle>
            <CardBody className="p-1 d-flex flex-column panningDisabled">
              <Button outline size="sm" color="primary" className="mt-auto w-100" onClick={toggleModal}>
                Edit Board
              </Button>
            </CardBody>
            <BoardEditModal isOpen={showModal} onClose={toggleModal} board={board} />
          </Card>
        ) : board.image?.url ? (
          <img src={board.image.url} style={playStyle} alt="board" />
        ) : (
          <div style={playStyle} />
        )}
      </div>
    </DraggableObject>
  );
};
