import { Container } from 'reactstrap';
import PublicPage from '../hocs/PublicPage';
import '../../css/termsOfService.css';

export default PublicPage(function TermsOfService(props: any) {
  return (
    <Container>
      <article className="terms-of-service">
        <h1>利用規約</h1>
        <p>
          お客様が、株式会社遭遇設計（以下「当社」という）が提供する「リモートコミュニケーション支援のオンラインアナログゲーム」（以下「本サービス」という）をご利用頂く際の取扱いにつき定めるものです。お客様が本規約に同意した場合には、契約内容になります。本規約に同意した上で本サービスをご利用ください。
        </p>
        <section>
          <h2>第１条（利用規約について）</h2>
          <ol>
            <li>当社が運営する本サービスについて、これを利用する会員は、本利用規約に必ず同意頂き、ご利用ください。</li>
            <li>
              当社は、会員の承諾を得ることなく、本規約の内容を改定することができるものとし、会員はこれを承諾するものとします。本規約が改定された後の本サービスの提供条件は、改定後の本規約の条件によるものとします。
            </li>
            <li>当社は、本規約を改定するときは、その内容について当社所定の方法により会員に通知します。</li>
            <li>
              前２項に定める本規約の改定の効力は、当社が前項により通知を行った時点から生じるものとします。本規約の内容の改定を承諾しない会員については、本サービスを利用できないものとします。
            </li>
            <li>
              本利用規約等の他、本サービスからリンクされた他のサイトについては、そのサイトの利用規約に同意したうえでご利用ください。
            </li>
          </ol>
        </section>
        <section>
          <h2>第２条（会員総則について）</h2>
          <ol>
            <li>
              本サービスの会員とは、本サービスの利用を申請し、当社が承認した人のことをいいます。会員は、本サービスの利用申請に際して、当社指定の情報を入力するものとします。
              <br />
              （以下、「登録情報」といいます）を入力します。
              <br />
              会員は、登録情報を厳正に管理するものとします。
            </li>
            <li>
              会員は、本サービス利用中に、何らかの事情でデータが破損・滅失した場合のために、会員側で定期的に登録情報のデータをバックアップすることを遵守するものとします。
              <br />
              会員がバックアップを取らなかったことによる損害については、当社が責任を負わないものとします。
            </li>
          </ol>
        </section>
        <section>
          <h2>第３条（会員情報について）</h2>
          <ol>
            <li>会員は、登録情報について、自己の責任の下、任意に登録、管理するものとします。</li>
            <li>
              当社は、登録された登録情報によって本サービスの利用があった場合、利用登録をおこなった本人が利用したものと扱うことができ、当該利用によって生じた結果ならびにそれに伴う一切の責任については、利用登録を行った本人に帰属するものとします。
            </li>
            <li>
              会員は、登録情報の不正使用によって当社または第三者に損害が生じた場合、当社および第三者に対して、当該損害を賠償するものとします。また、登録情報の管理は、会員が自己の責任の下で行うものとし、登録情報が不正確または虚偽であったために会員が被った一切の不利益および損害に関して、当社は責任を負わないものとします。
            </li>
            <li>
              会員は、本サービスを、年齢や利用環境等の条件に応じて、当社の定める範囲内で利用できるものとします。未成年者が会員する場合には、その法定代理人の同意のもと、本サービスを利用するものとします。
            </li>
          </ol>
        </section>
        <section>
          <h2>第４条（個人情報について）</h2>
          <p>個人情報は、当社が別途定めるプライバシーポリシーに則り、適正に取り扱うこととします。</p>
        </section>
        <section>
          <h2>第５条（禁止行為）</h2>
          <p>
            本サービスの利用に際し、当社は、会員に対し、法令を遵守した方法で使用することを義務づけ、次に掲げる行為を禁止します。違反した場合、利用停止等、当社が必要と判断した措置を取ることができます。
          </p>
          <ul>
            <li>（１）当社または第三者の知的財産権を侵害する行為</li>
            <li>（２）当社または第三者の名誉・信用を毀損または不当に差別もしくは誹謗中傷する行為</li>
            <li>（３）当社または第三者の財産を侵害する行為、または侵害する恐れのある行為</li>
            <li>（４）当社または第三者に経済的損害を与える行為</li>
            <li>（５）当社または第三者に対する脅迫的な行為</li>
            <li>（６）コンピューターウィルス、有害なプログラムを仕様またはそれを誘発する行為</li>
            <li>（７）本サービス用インフラ設備に対して過度な負担となるストレスをかける行為</li>
            <li>（８）当サイトのサーバーやシステム、セキュリティへの攻撃</li>
            <li>（９）当社提供のインターフェース以外の方法で当社サービスにアクセスを試みる行為</li>
            <li>（１０）本サービスから得た情報を漏えいする行為</li>
            <li>（１１）上記の他、当社が不適切と判断する行為</li>
          </ul>
        </section>
        <section>
          <h2>第６条 （本サービス内コンテンツの権利）</h2>
          <ul>
            <li>会員は､本サービスのコンテンツを当社の定める範囲内でのみ使用することができるものとします｡</li>
            <li>
              本サービスで提供される全てのコンテンツに関する権利は当社が有しており､会員に対しは､当社が許諾する範囲で、会員は、特許権､実用新案権､
              意匠権､商標権､著作権､その他知的財産権の実施または使用許諾をすることができます｡
            </li>
            <li>
              会員は､当社の定める使用範囲を超えていかなる方法によっても複製､送信､譲渡（会員同士の売買も含みます）､貸与､翻訳､翻案、無断で転載、二次使用、営利目的の使用、改変、逆アセンブル、逆コンパイル、リバースエンジニアリング等を行う事を禁止します。
            </li>
            <li>
              前項に関わらず､退会等により会員が会員資格を喪失した場合は､提供されたコンテンツの使用権も消滅するものとします｡
            </li>
          </ul>
        </section>
        <section>
          <h2>第７条（利用料金）</h2>
          <p>本サービスの利用料金は、別途当社及び会員が合意した金額とします。</p>
        </section>
        <section>
          <h2>第８条（利用期間）</h2>
          <p>本サービスの利用期間は、別途当社及び会員が合意した期間とします。</p>
        </section>
        <section>
          <h2>第９条（免責）</h2>
          <ol>
            <li>当社は、会員のＰＣ・タブレット・スマートフォン利用環境について一切関与せず、また一切の責任を負いません。</li>
            <li>当社は、本サービスの内容変更、中断、終了によって生じたいかなる損害についても、一切責任を負いません。</li>
            <li>
              当社は、本サービスの各ページからリンクしているホームページに関して、合法性、道徳性、信頼性、正確性について一切の責任を負いません。
            </li>
            <li>
              当社は、本サービスを利用したことにより直接的または間接的に会員に発生した損害について、一切賠償責任を負いません。
            </li>
            <li>
              当社は、会員その他の第三者に発生した機会逸失、業務の中断その他いかなる損害（間接損害や逸失利益を含みます）に対して、当社が係る損害の可能性を事前に通知されていたとしても、一切の責任を負いません。
            </li>
            <li>
              第１項から前項までの規定は、当社に故意または重過失が存する場合又は契約者が消費者契約法上の消費者に該当する場合には適用しません。
            </li>
            <li>
              会員と第三者との間の紛争及びトラブルについて、当社は一切責任を負わないものとします。会員と第三者でトラブルになった場合でも、両者同士の責任で解決するものとし、当社には一切の請求をしないものとします。
            </li>
            <li>
              本サービスの利用に関し当社が損害賠償責任を負う場合、会員が当社に本サービスの対価として直近１ヶ月間に支払った総額を限度額として賠償責任を負うものとします。
            </li>
            <li>
              会員は、本サービスの利用に関連し、他の会員に損害を与えた場合または第三者との間に紛争を生じた場合、自己の費用と責任において、かかる損害を賠償またはかかる紛争を解決するものとし、当社には一切の迷惑や損害を与えないものとします。
            </li>
            <li>
              会員の行為により、第三者から当社が損害賠償等の請求をされた場合には、会員の費用（弁護士費用）と責任で、これを解決するものとします。
            </li>
            <li>
              当社が、当該第三者に対して、損害賠償金を支払った場合には、会員は、当社に対して当該損害賠償金を含む一切の費用（弁護士費用及び逸失利益を含む）を支払うものとします。
            </li>
            <li>
              会員が本サービスの利用に関連して当社に損害を与えた場合、会員の費用と責任において当社に対して損害を賠償（訴訟費用及び弁護士費用を含む）をするものとします。
            </li>
            <li>
              本サービスに掲載されている情報及びコンテンツについての情報の正確性について、当社は保証しません。当社は、本サービスに掲載されている情報及びコンテンツについての紛争及びトラブルについて一切の責任を負いません。
            </li>
          </ol>
        </section>
        <section>
          <h2>第１０条（広告の掲載について）</h2>
          <p>
            会員は、本サービス上にあらゆる広告が含まれる場合があること、当社またはその提携先があらゆる広告を掲載する場合があることを理解しこれを承諾したものとみなします。本サービス上の広告の形態や範囲は、当社によって随時変更されます。
          </p>
        </section>
        <section>
          <h2>第１１条（権利譲渡の禁止）</h2>
          <ol>
            <li>
              会員は、予め当社の書面による承諾がない限り、本規約上の地位および本規約に基づく権利または義務の全部または一部を第三者に譲渡してはならないものとします。
            </li>
            <li>
              当社は、本サービスの全部または一部を当社の裁量により第三者に譲渡することができ、その場合、譲渡された権利の範囲内で会員のアカウントを含む、本サービスに係る会員の一切の権利が譲渡先に移転するものとします。
            </li>
          </ol>
        </section>
        <section>
          <h2>第１２条（反社会的勢力の排除）</h2>
          <ol>
            <li>
              ユーザーは、現在、暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者（以下総称して「反社会的勢力」といいます。）に該当しないこと、及び次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約します。
              <ul>
                <li>（１）反社会的勢力が経営を支配していると認められる関係を有すること</li>
                <li>（２）反社会的勢力が経営に実質的に関与していると認められる関係を有すること</li>
                <li>
                  （３）自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってする等、不当に反社会的勢力を利用していると認められる関係を有すること
                </li>
                <li>
                  （４）反社会的勢力に対して資金等を提供し、又は便宜を供与する等の関与をしていると認められる関係を有すること
                </li>
                <li>（５）役員又は経営に実質的に関与している者が反社会的勢力と社会的に非難されるべき関係を有すること</li>
              </ul>
            </li>
            <li>
              ユーザーは、自ら又は第三者を利用して次の各号のいずれにも該当する行為を行わないことを確約します。
              <ul>
                <li>（１）暴力的な要求行為</li>
                <li>（２）法的な責任を超えた不当な要求行為</li>
                <li>（３）取引に関して、脅迫的な言動をし、又は暴力を用いる行為</li>
                <li>（４）風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為</li>
                <li>（５）その他前各号に準ずる行為</li>
              </ul>
            </li>
            <li>
              当社は、ユーザーが反社会的勢力若しくは第1項各号のいずれかに該当し、若しくは前項各号のいずれかに該当する行為をし、又は第1項の規定にもとづく表明・確約に関して虚偽の申告をしたことが判明した場合には、自己の責に帰すべき事由の有無を問わず、ユーザーに対して何らの催告をすることなく本契約を解除することができます。
            </li>
            <li>
              ユーザーは、前項により当社が本契約を解除した場合、ユーザーに損害が生じたとしてもこれを一切賠償する責任はないことを確認し、これを了承します。
            </li>
          </ol>
        </section>
        <section>
          <h2>第１３条（分離可能性）</h2>
          <p>
            本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
          </p>
        </section>
        <section>
          <h2>第１４条（準拠法）</h2>
          <p>本規約の有効性，解釈及び履行については，日本法に準拠し，日本法に従って解釈されるものとする。</p>
        </section>
        <section>
          <h2>第１５条（管轄裁判所）</h2>
          <p>会員と当社との間で訴訟が生じた場合､高知地方裁判所又は高知簡易裁判所を第１審の専属的合意管轄裁判所とします｡</p>
        </section>
        <p style={{ textAlign: 'right' }}>2021年4月1日制定</p>
      </article>
    </Container>
  );
});
