import { Board } from './Board';
import { IBoard } from '../models/database';

export const BoardController = ({ boards }: { boards: IBoard[] }) => {
  return (
    <>
      {boards.map((board) => {
        return <Board key={board.key} board={board} />;
      })}
    </>
  );
};
