export const BOARD_WIDTH = 1920;
export const BOARD_HEIGHT = 1080;
export const ASPECT_RATIO = BOARD_WIDTH / BOARD_HEIGHT;
export const MAX_ZINDEX = 2147483647;

export const EDIT_MODAL_ZINDEX = MAX_ZINDEX;
export const ENLARGE_ZINDEX = MAX_ZINDEX;
export const MENU_ZINDEX = MAX_ZINDEX - 1;
export const STACK_TAB_ZINDEX = MAX_ZINDEX - 2;
export const FOOTER_ZINDEX = MAX_ZINDEX;

export const DEFAULT_SCALE = 2.5;
// 100%=2.5倍にしたい。2.5 * 40 = 100(%)になる。
export const SCALE_FACTOR = 40;
// 一度に手札に追加できるカードの上限枚数

export const DEFAULT_CARD_WIDTH = 63;
export const DEFAULT_CARD_HEIGHT = 89;
export const DEFAULT_BOARD_WIDTH = BOARD_WIDTH / (2 * DEFAULT_SCALE);
export const DEFAULT_BOARD_HEIGHT = BOARD_HEIGHT / (2 * DEFAULT_SCALE);
export const DEFAULT_BOARD_COLOR = '#e3e7e8';

export const LIMIT_OF_DROP_CARDS_TO_HAND = 15;

export const URL_REGEX = /^https?:\/\/[\w!?/+\-_~;.,*&@#$%()'[\]]+/;

export const EMAIL_DUMMY_DOMAIN = 'example.com';
