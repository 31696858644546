import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { useToggle } from 'react-use';
import { omit } from 'lodash';
import { IUser, IGroup } from '../models/firestore';

type Props = {
  group: IGroup;
  user: IUser;
  beforeRemove?: (group: IGroup, user: IUser) => boolean;
  onFinish?: () => void;
  disabled?: boolean;
  className?: string;
};

export default function RemoveGroupUserButton(props: Props) {
  const { group, user, beforeRemove = (_: any) => true, onFinish, disabled, className = '' } = props;
  const [isRemoving, toggleRemoving] = useToggle(false);
  const onClickRemove = async () => {
    if (!window.confirm('本当に削除しますか？')) return;

    toggleRemoving(true);
    if ((await beforeRemove(group, user)) === false) {
      return toggleRemoving(false);
    }

    const newGroups = omit(user.groups, [group.id]);
    const newGroupRoles = Object.values(newGroups).map((_) => _.role);
    const newRole = () => {
      if (newGroupRoles.some((_) => _ === 'admin')) return 'admin';
      if (newGroupRoles.some((_) => _ === 'creator')) return 'creator';

      return 'player';
    };
    const commonUpdates = {
      groupIds: user.groupIds.filter((id) => id !== group.id),
      groups: newGroups,
    };
    const updates = user.isSysAdmin ? commonUpdates : { ...commonUpdates, role: newRole() };

    await Promise.all([
      group.ref?.update({ userIds: group.userIds.filter((id) => id !== user.id) }),
      user.ref?.update(updates),
    ]);

    onFinish && (await onFinish());
    toast.success('削除しました');
    toggleRemoving(false);
  };

  return (
    <Button color="danger" onClick={onClickRemove} disabled={disabled || isRemoving} className={className}>
      <span className="fas fa-trash me-1" />
      削除
    </Button>
  );
}
