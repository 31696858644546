import firebase from 'firebase/compat/app';
import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import qs from 'qs';
import { Mode } from './hooks/useMode';
import { MENU_ZINDEX } from '../shared/config';
import { IUser } from '../models/firestore';
import { RoomContext } from './pages/Room';

import HelpEditModal from './modals/HelpEditModal';
import ExportSnapshotModal from './modals/ExportSnapshotModal';
import ImportSnapshotModal from './modals/ImportSnapshotModal';
import ResetGameModal from './modals/ResetGameModal';

type Props = {
  width: number;
  right?: number;
  mode?: Mode;
  currentUser: IUser | undefined;
};

export default function RoomMenu(props: Props) {
  const { right = 0, mode = 'play', currentUser } = props;
  const { search } = useLocation();
  const navigate = useNavigate();
  const { room, roomRef } = useContext(RoomContext);
  const [showsHelpEditModal, toggleHelpEditModal] = useToggle(false);
  const [showsExportModal, toggleExportModal] = useToggle(false);
  const [showsImportModal, toggleImportModal] = useToggle(false);
  const [showsResetGameModal, toggleResetGameModal] = useToggle(false);
  const [helpUrl, setHelpUrl] = useState<string>();
  const isEdit = mode === 'edit';
  const menuStyle: React.CSSProperties = {
    position: 'absolute',
    zIndex: MENU_ZINDEX,
    right,
  };
  const iconStyle: React.CSSProperties = {
    width: '16px',
  };

  const onClickToEdit = () => {
    const queries = qs.parse(search.slice(1));
    navigate({ search: `?${qs.stringify({ ...queries, mode: 'edit' })}` }, { replace: true });
  };

  const onClickToPlay = () => {
    const queries = qs.parse(search.slice(1));
    navigate({ search: `?${qs.stringify({ ...queries, mode: 'play' })}` }, { replace: true });
  };

  const onClickToRooms = () => {
    navigate('/');
  };

  const playModeMenu = () => {
    return (
      <>
        <DropdownMenu end>
          <DropdownItem tag="a" href={helpUrl} target="_blank" rel="noopener noreferrer">
            <i className="fas fa-question me-2" style={iconStyle} />
            ヘルプ
          </DropdownItem>
          <DropdownItem disabled>
            <i className="fas fa-bullhorn me-2" style={iconStyle} />
            アナウンス
          </DropdownItem>
          {room?.snapshotId && (
            <DropdownItem onClick={toggleResetGameModal}>
              <i className="fas fa-redo-alt me-2" style={iconStyle} />
              ゲームをリセット
            </DropdownItem>
          )}
          {room && currentUser?.canEdit(room) && (
            <>
              <DropdownItem onClick={onClickToEdit}>
                <i className="fas fa-edit me-2" style={iconStyle} />
                ゲーム編集
              </DropdownItem>
              <DropdownItem disabled>
                <i className="fas fa-user me-2" style={iconStyle} />
                ユーザー招待
              </DropdownItem>
            </>
          )}
          {currentUser && (
            <>
              <DropdownItem disabled>
                <i className="fas fa-share-alt me-2" style={iconStyle} />
                URL共有
              </DropdownItem>
              <DropdownItem onClick={onClickToRooms}>
                <i className="fas fa-share me-2" style={iconStyle} />
                ルーム一覧へ戻る
              </DropdownItem>
            </>
          )}
        </DropdownMenu>
      </>
    );
  };

  const editModeMenu = () => {
    return (
      <>
        <DropdownMenu end>
          <DropdownItem onClick={toggleHelpEditModal}>
            <i className="fas fa-question me-2" style={iconStyle} />
            ヘルプURLを設定
          </DropdownItem>
          {room && currentUser?.canUseSnapshot(room) && (
            <>
              <DropdownItem onClick={toggleExportModal}>
                <i className="fas fa-upload me-2" style={iconStyle} />
                スナップショットを保存
              </DropdownItem>
              <DropdownItem onClick={toggleImportModal}>
                <i className="fas fa-download me-2" style={iconStyle} />
                スナップショットを読込
              </DropdownItem>
            </>
          )}
          <DropdownItem onClick={onClickToPlay}>
            <i className="fas fa-share me-2" style={iconStyle} />
            プレイ画面へ戻る
          </DropdownItem>
        </DropdownMenu>
      </>
    );
  };

  useEffect(() => {
    const helpUrlRef = roomRef?.child('settings/helpUrl');
    const callback = (snapshot: firebase.database.DataSnapshot) => {
      setHelpUrl(snapshot.val() || '');
    };

    helpUrlRef?.on('value', callback);

    return () => helpUrlRef?.off('value', callback);
  }, [roomRef]);

  return (
    <div style={menuStyle}>
      <UncontrolledDropdown tag="div">
        <DropdownToggle outline color="secondary" className="m-1">
          <i className="fas fa-bars mx-1"></i>
        </DropdownToggle>
        {isEdit ? editModeMenu() : playModeMenu()}
      </UncontrolledDropdown>
      <HelpEditModal isOpen={showsHelpEditModal} onClose={toggleHelpEditModal} initialValue={helpUrl} />
      <ResetGameModal isOpen={showsResetGameModal} onClose={toggleResetGameModal} currentUser={currentUser} />
      {isEdit && room && currentUser?.canUseSnapshot(room) && (
        <>
          <ExportSnapshotModal isOpen={showsExportModal} onClose={toggleExportModal} room={room} currentUser={currentUser} />
          <ImportSnapshotModal isOpen={showsImportModal} onClose={toggleImportModal} room={room} currentUser={currentUser} />
        </>
      )}
    </div>
  );
}
