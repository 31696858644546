/* eslint-disable react-hooks/exhaustive-deps */
import firebase from 'firebase/compat/app';
import { useState, useEffect } from 'react';

export default function useDatabaseValueSubscription(ref: firebase.database.Reference | undefined, dependencies: any[] = []) {
  const [item, setItem] = useState<any>();
  useEffect(() => {
    if (!ref) return;

    const callback = (snapshot: firebase.database.DataSnapshot) => {
      if (!snapshot) return;
      setItem(snapshot.val());
    };

    ref.on('value', callback);
    return () => ref.off('value', callback);
  }, dependencies);
  return item;
}
