import firebase from 'firebase/compat/app';
import React, { useContext, ReactElement, useCallback, useRef, useMemo } from 'react';
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';
import { throttle } from 'lodash';
import { RoomContext } from './pages/Room';
import { validateRoom } from '../util';

type IDraggableObject = {
  key: string;
  ref?: firebase.database.Reference | null;
  x: number;
  y: number;
  z: number;
  dragger?: string | null;
};

type Props = {
  object: IDraggableObject;
  // Cardに限り、座標を取得するオブジェクト（card）と座標を更新するオブジェクト（stack）が異なるため、別途refを設定する
  objectRef?: firebase.database.Reference | null;
  onStart?: (e: DraggableEvent, position: DraggableData) => void;
  onDrag?: (e: DraggableEvent, position: DraggableData) => void;
  onStop?: (e: DraggableEvent, position: DraggableData) => void;
  onCancel?: () => void;
  disabled?: boolean;
  children: ReactElement;
  style?: React.CSSProperties;
  bounds?: { left?: number; top?: number; right?: number; bottom?: number } | string | null;
  disableDraggerUpdating?: boolean;
};

export default function DraggableObject(props: Props) {
  const { object, objectRef = object.ref, onStart, onDrag, onStop, onCancel, disabled = false, children, style, bounds: exBounds, disableDraggerUpdating = false } = props;
  const { currentUser, scale = 1.0, bounds, isBlocking, roomRef } = useContext(RoomContext);
  const { key, x, y, z } = object;
  const stopped = useRef<boolean>(false);

  const objectStyle: React.CSSProperties = {
    display: 'inline-block',
    cursor: 'pointer',
    position: 'absolute',
    zIndex: z,
    ...style,
  };
  const position = { x, y };
  const isDragging = !!object.dragger;
  const isDraggingByOther = isDragging && object.dragger !== currentUser?.id;

  const scaledPosition = ({ x, y }: { x: number; y: number }) => {
    return {
      x: x * scale,
      y: y * scale,
    };
  };

  const unscaledPosition = useCallback(
    ({ x, y }: { x: number; y: number }) => {
      return {
        x: x / scale,
        y: y / scale,
      };
    },
    [scale]
  );

  const handleStart = (e: DraggableEvent, position: DraggableData): false | void => {
    if (!currentUser || !objectRef) return;
    if (isDraggingByOther || isBlocking) {
      onCancel && onCancel();
      return false;
    }

    stopped.current = false;
    disableDraggerUpdating || objectRef.update({ dragger: currentUser.id });
    onStart && onStart(e, position);
  };

  const _handleDrag = useMemo(
    () => throttle((position: DraggableData) => {
      if (!objectRef || stopped.current) return;
      const { x, y } = unscaledPosition(position);

      objectRef.update({ x, y });
    }, 200),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unscaledPosition]
  );

  const handleDrag = (e: DraggableEvent, position: DraggableData): false | void => {
    if (isDraggingByOther || isBlocking) {
      onCancel && onCancel();
      return false;
    }

    _handleDrag(position);
    onDrag && onDrag(e, position);
  };

  const handleStop = (e: DraggableEvent, position: DraggableData): false | void => {
    if (!objectRef) return;

    stopped.current = true;

    const { x, y } = unscaledPosition(position);

    if (isDraggingByOther || isBlocking) {
      onCancel && onCancel();
      // handleStopでfalseを返すとオブジェクトをドラッグしたままになる
      return;
    }

    const updates = disableDraggerUpdating ? { x, y } : { x, y, dragger: null };
    objectRef.update(updates);
    onStop && onStop(e, position);

    // HACK: 整合性チェック
    roomRef && validateRoom(roomRef)
  };

  return (
    <Draggable
      handle=".handle"
      defaultPosition={{ x: 0, y: 0 }}
      position={scaledPosition(position)}
      grid={[1, 1]}
      scale={1}
      bounds={exBounds || bounds}
      onStart={handleStart}
      onDrag={handleDrag}
      onStop={handleStop}
      disabled={disabled || isBlocking}
    >
      <div key={key} className="handle panningDisabled" style={objectStyle}>
        {children}
      </div>
    </Draggable>
  );
}
