import { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { BOARD_WIDTH, ASPECT_RATIO }  from '../../shared/config';

export default function useScale(): number {
  // NOTE: 縦・横の短い方を基準にスケールを算出
  const calcScale = () => Math.min(window.innerWidth, window.innerHeight * ASPECT_RATIO) / BOARD_WIDTH;
  const [scale, setScale] = useState<number>(calcScale());

  useEffect(() => {
    window.onresize = debounce(() => {
      setScale(calcScale());
    }, 200);
  }, []);

  return scale;
}
