import React from 'react';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { useToggle } from 'react-use';
import classnames from 'classnames';
import { FieldValue } from '../firebase';

export default function DeleteButton(props: any) {
  const {
    item,
    storeRef,
    databaseRef,
    beforeDelete = (_: any) => true,
    onFinish,
    disabled,
    label = '削除',
    iconClassName = 'fas fa-trash',
    isLogicalDelete = false,
    ...extraProps
  } = props;
  const [isDeleting, toggleDeleting] = useToggle(false);
  const onClickDelete = async () => {
    if (!window.confirm('本当に削除しますか？')) return;

    toggleDeleting(true);
    if ((await beforeDelete(item)) === false) {
      return toggleDeleting(false);
    }

    databaseRef && await databaseRef.remove();
    if (isLogicalDelete) {
      await storeRef.set({ deletedAt: FieldValue.serverTimestamp() }, { merge: true });
    } else {
      await storeRef.delete();
    }
    onFinish && (await onFinish());
    toast.success('削除しました');
    toggleDeleting(false);
  };

  return (
    <Button color="danger" onClick={onClickDelete} disabled={disabled || isDeleting} {...extraProps}>
      <span className={classnames(iconClassName, 'me-1')} />
      {label}
    </Button>
  );
}
