import ReactDOM from 'react-dom/client';
import './sentry';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-pro/css/all.min.css';
import 'react-datepicker/dist/react-datepicker.css';

import Root from './components/Root';
import routes from './routes';
import './firebase';
import './css/index.css';

ReactDOM.createRoot(document.getElementById('root')!).render(<Root routes={routes} />);
