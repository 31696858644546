import firebase from 'firebase/compat/app';
import { BOARD_WIDTH, BOARD_HEIGHT, DEFAULT_CARD_HEIGHT, DEFAULT_SCALE } from '../../shared/config';
import { IStack, StackObject, ICard, Stack } from '.';
import { LoggingHandler, LogSpace } from '../firestore';

const DEFAULT_TYPE = 'hand';
const DEFAULT_X = 40;
const DEFAULT_WIDTH = BOARD_WIDTH - DEFAULT_X * 2;
const DEFAULT_HEIGHT = DEFAULT_CARD_HEIGHT * DEFAULT_SCALE;
const DEFAULT_Y = BOARD_HEIGHT - DEFAULT_HEIGHT;
const OBJECT_INTERVAL = 50;

export interface IHand {
  key: string;
  ref: firebase.database.Reference | null;
  type: string;
  name: string;
  x: number;
  y: number;
  width: number;
  height: number;
  objects: string[];
  toObject: () => {};
  arrangeObjects: (objects: string[], stacksRef: firebase.database.Reference, draggingStack?: IStack | null) => void;
  addStack: (stack: IStack, cards: ICard[], stacks: IStack[], onLogging: LoggingHandler) => Promise<void>;
  removeStacks: (stacks: IStack[], onLogging?: LoggingHandler) => void;
  rearrangeObjects: (stack: IStack) => void;
  toLogFormat: LogSpace;
}

export class Hand implements IHand {
  key: string = '';
  ref: firebase.database.Reference | null = null;
  type: string = '';
  name: string = '';
  x: number = 0;
  y: number = 0;
  width: number = 0;
  height: number = 0;
  objects: string[] = [];

  constructor({
    key = '',
    ref = null,
    type = DEFAULT_TYPE,
    name = '',
    x = DEFAULT_X,
    y = DEFAULT_Y,
    width = DEFAULT_WIDTH,
    height = DEFAULT_HEIGHT,
    objects = [],
  }: Partial<IHand>) {
    Object.assign(this, {
      key,
      ref,
      type,
      name,
      x,
      y,
      width,
      height,
      objects,
    });
  }

  toObject(): {} {
    return {
      type: this.type,
      name: this.name,
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      objects: this.objects,
    };
  }

  // TODO: 存在しない、または、ownerが設定されていないstackはhand.objectsから削除したほうがよさそう
  arrangeObjects(objects: string[], stacksRef: firebase.database.Reference, draggingStack: IStack | null = null): void {
    const updates = objects.reduce((acc: { [key: string]: number | string | null }, cur: string, index: number) => {
      if (!draggingStack || cur !== draggingStack.key) {
        acc[`${cur}/x`] = this.x + OBJECT_INTERVAL * index;
        acc[`${cur}/y`] = this.y;
        acc[`${cur}/owner`] = this.key;
        acc[`${cur}/holderId`] = null;
        acc[`${cur}/dragger`] = null;
      }
      acc[`${cur}/z`] = index + 1;
      return acc;
    }, {});
    stacksRef.update(updates);

    // drag中のカードが手札外カードの場合は手札に入れない（dropしたときに手札に入れる）
    (!draggingStack || draggingStack.owner) && this.ref && this.ref.update({ objects });
  }

  async addStack(stack: IStack, cards: ICard[], stacks: IStack[], onLogging: LoggingHandler): Promise<void> {
    const stackObjectKeys = stack.objects.map((object: StackObject) => object.key);
    const stacksRef = stack.ref && stack.ref.parent;
    if (!stacksRef) return;

    const index = Math.floor((stack.x - this.x) / OBJECT_INTERVAL);
    const correctedIndex = Math.min(Math.max(0, index), stack.owner ? this.objects.length - 1 : this.objects.length);

    if (stack.owner !== this.key) {
      const newStackPairs = stack.objects.map((object: StackObject) => {
        const { key } = object;
        const card = cards.find((_) => _.key === key);
        const { width, height } = card || {};
        const newStack = new Stack({
          objects: [{ ...object, opened: true }],
          x: this.x + OBJECT_INTERVAL * correctedIndex,
          y: this.y,
          width,
          height,
          dragger: null,
          owner: this.key,
        });
        onLogging('drop', stack, this);
        return [key, newStack.toObject()];
      });
      const updates = {
        ...stacks.reduce((acc: any, cur: IStack) => ({ ...acc, [cur.key]: cur.toObject() }), {}),
        [stack.key]: null,
        ...Object.fromEntries(newStackPairs),
      };
      await stacksRef.set(updates);
    }

    const newHandObjects = stack.owner
      ? this.objects
      : [...this.objects.slice(0, correctedIndex), ...stackObjectKeys, ...this.objects.slice(correctedIndex)];
    this.arrangeObjects(newHandObjects, stacksRef);
  }

  removeStacks(stacks: IStack[], onLogging?: LoggingHandler): void {
    const [stack] = stacks;
    const stacksRef = stack?.ref?.parent;
    if (!stacksRef) return;

    Promise.all(
      stacks.map(async (stack) => {
        stack.ref && stack.ref.update({ owner: null, objects: stack.objects.map((_) => ({ ..._, opened: false })) });
        onLogging && onLogging('pick', stack, this);
      })
    );
    // stackが束のままで手札に入っている場合、stack内の全カードのkeyがhand.objectsに追加されている可能性があるので、
    // 全カードのkeyをhand.objectsから削除する
    const stackObjects = stacks.map(({ objects }) => objects).flat()
    const rest = this.objects.filter((_: string) => !stackObjects.some((object) => object.key === _));
    this.arrangeObjects(rest, stacksRef);
  }

  rearrangeObjects(stack: IStack): void {
    const stacksRef = stack.ref && stack.ref.parent;
    if (!stacksRef) return;

    const rest = this.objects.filter((_) => _ !== stack.key);
    const currentIndex = this.objects.indexOf(stack.key);
    const newIndex = Math.floor((stack.x - this.x) / OBJECT_INTERVAL);
    const correctedIndex = Math.min(Math.max(0, newIndex), rest.length);
    if (currentIndex !== correctedIndex) {
      const newObjects = [...rest.slice(0, correctedIndex), stack.key, ...rest.slice(correctedIndex)];

      this.arrangeObjects(newObjects, stacksRef, stack);
    }
  }

  get toLogFormat(): LogSpace {
    return { type: 'hand', key: this.key, name: this.name, remarks: '' };
  }
}
