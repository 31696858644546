import React from 'react';
import { Navigate } from 'react-router-dom';
import { Navbar, Nav } from 'reactstrap';
import Footer from '../Footer';

export default function UnAuthPageHOC(WrappedComponent: any) {
  return function UnAuthPage(props: any) {
    const { currentUser } = props;
    return (
      <>
        {currentUser && <Navigate to="/" />}
        <div className="page">
          <header className="header-nav">
            <Navbar color="light" light className="justify-content-between">
              <Nav className="navbar-brand">Onlineアナログゲーム</Nav>
            </Navbar>
          </header>
          <WrappedComponent {...props} />
          <Footer />
        </div>
      </>
    );
  };
}
