import React, { useState, useContext } from 'react';
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  FormFeedback,
} from 'reactstrap';
import isInt from 'validator/lib/isInt';
import { IDeck, IStack, IHand } from '../../models/database';
import { scaleToPercent, percentToScale } from '../../util';
import BackImageRow from './deckEdit/BackImageRow';
import FrontImageRow from './deckEdit/FrontImageRow';
import BothImageRow from './deckEdit/BothImageRow';
import { EDIT_MODAL_ZINDEX } from '../../shared/config';
import { RoomContext } from '../pages/Room';

const validIntOption = { min: 50, max: 500 };

type Props = {
  isOpen: boolean;
  onClose: () => void;
  deck: IDeck;
  stacks: IStack[];
  hands: IHand[];
};

export default function EditModal(props: Props) {
  const { isOpen, onClose, deck, stacks, hands } = props;
  const { roomRef } = useContext(RoomContext);
  const [width, setWidth] = useState<string>(deck.actualSize.width.toString());
  const [height, setHeight] = useState<string>(deck.actualSize.height.toString());
  const [scale, setScale] = useState<number>(deck.actualSize.scale);

  const onChangeLabel = ({ target: { value } }: { target: { value: string } }) => {
    if (!deck.ref) return;

    deck.ref.update({ label: value });
  };

  const onChangeWidth = ({ target: { value } }: { target: { value: string } }) => {
    setWidth(value);
    if (!deck.ref || !isInt(value, validIntOption) || !roomRef) return;

    deck.ref.update({
      width: Number(value) * scale,
      actualSize: { ...deck.actualSize, width: Number(value) },
    });
    deck.changeImageSize(Number(value) * scale, deck.height, stacks, roomRef.child('objects/cards'));
  };

  const onChangeHeight = ({ target: { value } }: { target: { value: string } }) => {
    setHeight(value);
    if (!deck.ref || !isInt(value, validIntOption) || !roomRef) return;

    deck.ref.update({
      height: Number(value) * scale,
      actualSize: { ...deck.actualSize, height: Number(value) },
    });
    deck.changeImageSize(deck.width, Number(value) * scale, stacks, roomRef.child('objects/cards'));
  };

  const onChangeScale = ({ target: { value } }: { target: { value: string } }) => {
    const changedScale = percentToScale(Number(value));
    setScale(changedScale);
    if (!deck.ref || !isInt(value, validIntOption)) return;

    deck.ref.update({
      width: Number(width) * changedScale,
      height: Number(height) * changedScale,
      actualSize: { ...deck.actualSize, scale: changedScale },
    });
  };

  const onClickAddBackImage = () => {
    if (!deck.ref) return;
    deck.ref.update({ backImage: { fileName: '' } });
  };

  const onClickAddImageRow = () => {
    if (!deck.ref) return;
    deck.ref.update({ deckCards: [...deck.deckCards, { name: '', count: 0 }] });
  };

  const onChangeRecallShouldConfirm = () => {
    if (!deck.ref) return;
    deck.ref.update({ recallShouldConfirm: !deck.recallShouldConfirm });
  };

  const onChangeRecallAllShouldConfirm = () => {
    if (!deck.ref) return;
    deck.ref.update({ recallAllShouldConfirm: !deck.recallAllShouldConfirm });
  };

  const onChangeSameBackImage = async () => {
    if (!deck.ref || !roomRef || !window.confirm('設定を変更すると既に配置されているカードの表/裏面も全て切り替わります。よろしいですか？')) return;

    await deck.ref.update({ isSameBackImage: !deck.isSameBackImage });
    await deck.changeAllBackImageUrls(!deck.isSameBackImage, roomRef.child('objects/cards'));
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} zIndex={EDIT_MODAL_ZINDEX}>
      <ModalHeader>Edit Deck</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>Label</Label>
            <Input value={deck.label} onChange={onChangeLabel} />
          </FormGroup>
          <FormGroup>
            <Label>Card Size (width / height / scale)</Label>
            <Row>
              <Col md={4}>
                <InputGroup>
                  <Input value={width} onChange={onChangeWidth} invalid={!isInt(width, validIntOption)} />
                  <div className="input-group-append">
                    <InputGroupText>mm</InputGroupText>
                  </div>
                  <FormFeedback>50~500の範囲で指定してください</FormFeedback>
                </InputGroup>
              </Col>
              <Col md={4}>
                <InputGroup>
                  <Input value={height} onChange={onChangeHeight} invalid={!isInt(height, validIntOption)} />
                  <div className="input-group-append">
                    <InputGroupText>mm</InputGroupText>
                  </div>
                  <FormFeedback>50~500の範囲で指定してください</FormFeedback>
                </InputGroup>
              </Col>
              <Col md={4}>
                <InputGroup>
                  <Input
                    value={scaleToPercent(scale)}
                    onChange={onChangeScale}
                    invalid={!isInt(scaleToPercent(scale).toString(), validIntOption)}
                  />
                  <div className="input-group-append">
                    <InputGroupText>%</InputGroupText>
                  </div>
                  <FormFeedback>50~500の範囲で指定してください</FormFeedback>
                </InputGroup>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Label>Recall</Label>
            <Col>
              <FormGroup check>
                <Input
                  id="recall-should-confirm"
                  type="checkbox"
                  onChange={onChangeRecallShouldConfirm}
                  checked={deck.recallShouldConfirm}
                />
                <Label for="recall-should-confirm" check>
                  Recallに確認ダイアログを出す
                </Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  id="recall-all-should-confirm"
                  type="checkbox"
                  onChange={onChangeRecallAllShouldConfirm}
                  checked={deck.recallAllShouldConfirm}
                />
                <Label for="recall-all-should-confirm" check>
                  Recall Allに確認ダイアログを出す
                </Label>
              </FormGroup>
            </Col>
          </FormGroup>
          <FormGroup check>
            <Input id="is-same-back-image" type="checkbox" onChange={onChangeSameBackImage} checked={deck.isSameBackImage} />
            <Label for="is-same-back-image" check>
              共通の裏面を使用する
            </Label>
          </FormGroup>
          {deck.isSameBackImage ? (
            <>
              <FormGroup>
                <Label>Back Image</Label>
                {deck.backImage != null ? (
                  <BackImageRow {...props} />
                ) : (
                  <Button className="w-100" color="primary" outline onClick={onClickAddBackImage}>
                    <i className="fas fa-plus me-1"></i>
                    Add Back Image
                  </Button>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Front Images</Label>
                {deck.deckCards.map((deckCard, i) => {
                  return (
                    deck.ref != null &&
                    deckCard != null && (
                      <FrontImageRow
                        key={i}
                        {...props}
                        deckCardRef={deck.ref.child(`deckCards/${i}`)}
                        deckCard={deckCard}
                        index={i}
                        hands={hands}
                      />
                    )
                  );
                })}
                <Button className="w-100" color="primary" outline onClick={onClickAddImageRow}>
                  <i className="fas fa-plus me-1"></i>
                  Add Front Image
                </Button>
              </FormGroup>
            </>
          ) : (
            <FormGroup>
              <Label>Card Images</Label>
              {deck.deckCards.map((deckCard, i) => {
                return (
                  deck.ref != null &&
                  deckCard != null && (
                    <BothImageRow
                      key={i}
                      {...props}
                      deckCardRef={deck.ref.child(`deckCards/${i}`)}
                      deckCard={deckCard}
                      index={i}
                      hands={hands}
                    />
                  )
                );
              })}
              <Button className="w-100" color="primary" outline onClick={onClickAddImageRow}>
                <i className="fas fa-plus me-1"></i>
                Add Card Image
              </Button>
            </FormGroup>
          )}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
