import firebase from 'firebase/compat/app';
import React, { useRef, createContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { DraggableBounds } from 'react-draggable';
import { TransformWrapper, TransformComponent, MiniMap } from 'react-zoom-pan-pinch';

import { firestore, database } from '../../firebase';
import RoomPage from '../hocs/RoomPage';
import useScale from '../hooks/useScale';
import useMode, { Mode } from '../hooks/useMode';
import RoomMenu from '../RoomMenu';
import EditMenu from '../EditMenu';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useDatabaseSubscription from '../hooks/useDatabaseSubscription';
import useHoldersSubscription from '../hooks/useHoldersSubscription';
import useDecksSubscription from '../hooks/useDecksSubscription';
import useStacksSubscription from '../hooks/useStacksSubscription';
import { useBoardsSubscription } from '../hooks/useBoardsSubscription';
import useDatabaseValueSubscription from '../hooks/useDatabaseValueSubscription';
import useHand from '../hooks/useHand';
import { BOARD_WIDTH, BOARD_HEIGHT, MENU_ZINDEX } from '../../shared/config';
import { Room as RoomModel, IRoom, IUser } from '../../models/firestore';
import { Hand as HandModel, IHand, IHolder, IStack, IDeck, ICard, Card, IBoard } from '../../models/database';

import StackController from '../StackController';
import DeckController from '../DeckController';
import HolderController from '../HolderController';
import { BoardController } from '../BoardController';
import Hand from '../Hand';

const databaseRoomsRef = database.ref('rooms');

type Props = {
  currentUser: IUser | undefined;
};

type Context = {
  currentUser?: IUser;
  room?: IRoom;
  hand?: IHand;
  roomRef?: firebase.database.Reference;
  mode?: Mode;
  scale?: number;
  bounds?: DraggableBounds | string | false;
  zIndexRef?: React.MutableRefObject<number>;
  isBlocking?: boolean;
};

export const RoomContext = createContext<Context>({});

export const GameBoard = ({
  mode,
  boardStyle,
  boards,
  holders,
  stacks,
  decks,
  hands,
  cards,
  hand,
}: {
  mode: Mode;
  boardStyle: React.CSSProperties;
  boards: IBoard[];
  holders: IHolder[];
  stacks: IStack[];
  decks: IDeck[];
  hands: IHand[];
  cards: ICard[];
  hand: IHand | undefined;
}) => {
  return (
    <>
      {mode === 'edit' ? (
        <div className="edit-board" style={boardStyle}>
          <BoardController boards={boards} />
          <HolderController holders={holders} />
          <DeckController stacks={stacks} decks={decks} hands={hands} />
        </div>
      ) : (
        <div className="play-board" style={boardStyle}>
          <BoardController boards={boards} />
          {hand && <Hand hand={hand} />}
          <HolderController holders={holders} />
          <DeckController stacks={stacks} decks={decks} hands={hands} />
          <StackController stacks={stacks} cards={cards} holders={holders} decks={decks} />
        </div>
      )}
    </>
  );
};

export default RoomPage(function Room(props: Props) {
  const {
    currentUser,
  } = props;
  const { roomId } = useParams<{ roomId: string }>();
  const scale = useScale();
  const room = useDocumentSubscription(firestore.collection('rooms').doc(roomId), RoomModel);
  const mode = useMode({ room, ...props });
  // const zoom = (window.outerWidth) / window.innerWidth;
  // TODO: ズームが必要な場合は上を使う、一旦1.0倍固定値とする
  const zoom = 1.0;
  const width = scale * BOARD_WIDTH * zoom * zoom;
  const height = scale * BOARD_HEIGHT * zoom * zoom;
  const miniMapScale = 0.2;
  const miniMpaWidth = width * miniMapScale;
  const miniMpaHeight = height * miniMapScale;
  const boardMargin = (window.innerWidth * zoom * zoom - width) / 2;
  const databaseRoomRef = databaseRoomsRef.child(roomId!);
  const zIndexRef = useRef<number>(1);
  zIndexRef.current = useDatabaseValueSubscription(databaseRoomRef.child('frontZ'), []) || zIndexRef.current;
  const hands = useDatabaseSubscription(databaseRoomRef.child('objects/hands'), HandModel);
  // MEMO: currentUserの手札作成処理をuseHandから出して、handはhandsからfindするようにしたほうがよいかも
  const hand = useHand(currentUser, databaseRoomRef);
  const boards = useBoardsSubscription(databaseRoomRef);
  const holders = useHoldersSubscription(databaseRoomRef);
  const decks = useDecksSubscription(databaseRoomRef);
  const stacks = useStacksSubscription(databaseRoomRef);
  const cards = useDatabaseSubscription(databaseRoomRef.child('objects/cards'), Card);
  const isBlocking = useDatabaseValueSubscription(databaseRoomRef.child('isBlocking'), []) || false;
  const boardStyle: React.CSSProperties = {
    position: 'relative',
    width,
    height,
  };
  const transformWrapperStyle: React.CSSProperties = {
    left: boardMargin,
    width,
  };
  const miniMapTitleHeight = 40 * scale;
  const miniMapBorderRadius = 8 * scale;
  const miniMapTitleStyle: React.CSSProperties = {
    height: miniMapTitleHeight - 2 * scale,
    backgroundColor: '#f5f5f5',
    fontSize: 18 * scale,
    padding: 6 * scale,
    textAlign: 'center',
    borderRadius: `${miniMapBorderRadius}px ${miniMapBorderRadius}px 0 0`,
  };
  const miniMapStyle: React.CSSProperties = {
    position: 'fixed',
    top: height - miniMpaHeight - miniMapTitleHeight - 12,
    left: width + boardMargin - miniMpaWidth - 2 - 12,
    zIndex: MENU_ZINDEX,
    border: '1px solid darkgray',
    width: miniMpaWidth + 2,
    height: miniMpaHeight + miniMapTitleHeight,
    pointerEvents: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 8px 4px -4px, rgba(0, 0, 0, 0.14) 0px 4px 4px 0px, rgba(0, 0, 0, 0.12) 0px 4px 12px 0px',
    borderRadius: `${miniMapBorderRadius}px ${miniMapBorderRadius}px 0 0`,
  };

  if (!room) return <div />;
  if (room?.deletedAt) return <Navigate to="/" />;
  return (
    <RoomContext.Provider
      value={{
        currentUser,
        room,
        hand,
        roomRef: databaseRoomRef,
        mode,
        bounds: `.${mode}-board`,
        scale,
        zIndexRef,
        isBlocking,
      }}
    >
      <div style={{ backgroundColor: 'darkgray', overflow: 'hidden' }}>
        {mode === 'edit' && <EditMenu {...props} left={boardMargin} className="me-auto" />}
        <RoomMenu {...props} width={width} mode={mode} right={boardMargin} />
        <TransformWrapper
          initialScale={1}
          maxScale={8}
          panning={{ excluded: ['panningDisabled'] }}
          disablePadding={true}
          centerZoomedOut={false}
          doubleClick={{ disabled: true }}
        >
          <div style={miniMapStyle}>
            <div style={miniMapTitleStyle}>{room.name}</div>
            <MiniMap width={miniMpaWidth} height={miniMpaHeight}>
              <GameBoard
                mode={mode}
                boardStyle={boardStyle}
                boards={boards}
                holders={holders}
                stacks={stacks}
                decks={decks}
                hands={hands}
                cards={cards}
                hand={hand}
                {...props}
              />
            </MiniMap>
          </div>
          <TransformComponent wrapperStyle={transformWrapperStyle}>
            <GameBoard
              mode={mode}
              boardStyle={boardStyle}
              boards={boards}
              holders={holders}
              stacks={stacks}
              decks={decks}
              hands={hands}
              cards={cards}
              hand={hand}
              {...props}
            />
          </TransformComponent>
        </TransformWrapper>
      </div>
    </RoomContext.Provider>
  );
});
