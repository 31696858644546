import firebase from 'firebase/compat/app';
import { useState, useEffect } from 'react';
import { IUser, User } from '../../models/firestore';
import { firestore } from '../../firebase';
import useDocumentSubscription from './useDocumentSubscription';

const usersRef = firestore.collection('users');

export default function useCurrentUser(firebaseUser: firebase.User | null) {
  const user = useDocumentSubscription(firebaseUser && usersRef.doc(firebaseUser.uid), User, [firebaseUser]);
  const [currentUser, setCurrentUser] = useState<IUser | undefined | null>();

  useEffect(() => {
    if (firebaseUser) {
      setCurrentUser(user);
    } else {
      setCurrentUser(null);
    }
  }, [firebaseUser, user]);

  return currentUser;
}
