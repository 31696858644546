import { Navigate } from 'react-router-dom';
import HeaderNav from '../HeaderNav';
import Footer from '../Footer';

export default function PageHOC(WrappedComponent: any) {
  return function Page(props: any) {
    const { currentUser, isLoading } = props;

    return (
      isLoading || (
        <>
          {!currentUser && <Navigate to="/signin" />}
          <div className="page">
            <HeaderNav {...props} />
            <WrappedComponent {...props} />
            <Footer />
          </div>
        </>
      )
    );
  };
}
