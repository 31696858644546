import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { IUser, IRoom } from '../../models/firestore';

type Props = {
  room: IRoom | null;
  currentUser: IUser | undefined;
};

export type Mode = 'edit' | 'play';

export default function useMode(props: Props): Mode {
  const {
    room,
    currentUser,
  } = props;
  const { search } = useLocation();
  const [mode, setMode] = useState<Mode>('play');

  useEffect(() => {
    const { mode } = qs.parse(search.slice(1));
    if (mode === 'edit' && (!room || !currentUser?.canEdit(room))) return;

    setMode(mode as Mode);
  }, [room, search, currentUser]);

  return mode || 'play';
}
