/* eslint-disable react-hooks/exhaustive-deps */
import firebase from 'firebase/compat/app';
import { useState, useEffect } from 'react';

interface DocumentModel {
  key: string;
  ref: firebase.database.Reference | null;
}

export default function useDatabaseSubscription<T extends DocumentModel>(
  ref: firebase.database.Reference | undefined,
  ctor: { new (params: any): T },
  dependencies: any[] = [],
  { initialItems = [] } = {}
) {
  const [items, setItems] = useState<T[]>(initialItems);
  useEffect(() => {
    if (!ref) return;

    const callback = (snapshot: firebase.database.DataSnapshot) => {
      if (!snapshot) return;

      const values: any[] = Object.entries(snapshot.val() || {}).map(([key, value]: any) => ({ key, ...value })) || [];
      setItems(values.map((_: any) => new ctor({ key: _.key, ref: ref.child(_.key), ..._ })));
    };

    ref.on('value', callback);
    return () => ref.off('value', callback);
  }, dependencies);
  return items;
}
