import { useCallback } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { Navigate, useParams } from 'react-router-dom';
import { uniqBy } from 'lodash';

import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import Invitations from '../Invitations';
import { firestore as db } from '../../firebase';
import { User, IUser, Group, groupRoles, Invitation } from '../../models/firestore';
import Page from '../hocs/Page';
import RemoveGroupUserButton from '../RemoveGroupUserButton';
import EditUserGroupRoleButton from '../EditUserGroupRoleButton';
import { ModalButton } from '../ModalButton';
import { EditUserPasswordForm } from '../forms/EditUserPasswordForm';
import { ImportButton } from '../ImportButton';
import { ExportButton } from '../ExportButton';
import { createGroupUsers } from '../../firebaseFunctions';
import { EMAIL_DUMMY_DOMAIN } from '../../shared/config';

const usersRef = db.collection('users');
const groupsRef = db.collection('groups');
const invitationsRef = db.collection('invitations');

type Props = {
  currentUser: IUser | undefined;
};

export default Page(function Users(props: Props) {
  const { currentUser } = props;
  const { groupId } = useParams<{ groupId: string }>();
  const users = useCollectionSubscription(usersRef, User, []);
  const groupUsers = users.filter((user) => user.groupIds.includes(groupId!));
  const group = useDocumentSubscription(groupsRef.doc(groupId), Group);
  const invitations = useCollectionSubscription(invitationsRef, Invitation, []);
  const groupInvitations = invitations.filter((invitation) => invitation.groupId === groupId);
  const canImport = !!(group && invitations && (!group.capacity || group.capacity > group.userIds.length + invitations.length));
  const importProcess = useCallback(
    async (rows: any[]) => {
      const filteredRows = rows.filter(({ email, password, name }) => email && password && name);
      if (
        !window.confirm(
          `${filteredRows.length}件のユーザーを作成しますか？\n${filteredRows.map(({ email }) => email).join(', ')}`
        )
      )
        return false;

      const newUsers = filteredRows.map(({ email, password, name, role }) => ({
        email: email.match(/@/) ? email : email + '@' + EMAIL_DUMMY_DOMAIN,
        password,
        name,
        role: role || 'player',
      }));
      if (!group) return false;
      if (newUsers.length !== uniqBy(newUsers, 'email').length) return false;
      if (newUsers.length > 1000) return false;
      if (group?.capacity && group.capacity < group.userIds.length + invitations.length + newUsers.length) return false;
      if (newUsers.some(({ email }) => users.some((user) => user.email === email))) return false;
      if (newUsers.some(({ email }) => invitations.some((invitation) => invitation.email === email))) return false;

      await createGroupUsers({ groupId: group.id, users: newUsers });
      return true;
    },
    [group, invitations, users]
  );
  const exportData = groupUsers.map((user) => ({
    name: user.name,
    role: user.groups[groupId!]?.role || '',
    email: user.email.replace(`@${EMAIL_DUMMY_DOMAIN}`, ''),
    password: '',
  }));

  return currentUser?.isSysAdmin || currentUser?.isGroupAdmin(groupId!) ? (
    <div className="container py-5 position-relative">
      <div className="d-flex justify-content-end gap-2">
        {canImport && (
          <>
            <ExportButton data={exportData} />
            <ImportButton importProcess={importProcess} />
          </>
        )}
      </div>
      <Card className="my-2">
        <CardBody>
          <div className="d-flex justify-content-center">
            <h4>ユーザー管理</h4>
          </div>
          <Table>
            <thead>
              <tr>
                <th>名前</th>
                <th>メールアドレス or ID</th>
                <th>権限</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {groupUsers.map((user, i) => {
                const { id, name, email, groups } = user;
                const { role } = groups[groupId!] || {};
                return (
                  <tr key={id}>
                    <td>{name}</td>
                    <td>{email.replace(`@${EMAIL_DUMMY_DOMAIN}`, '')}</td>
                    <td>{groupRoles.find(({ value }) => value === role)?.label}</td>
                    <td className="text-end text-nowrap d-flex justify-content-end flex-wrap gap-2">
                      {group && currentUser?.canEditGroupUserPassword(group.id) && (
                        <ModalButton
                          color="warning"
                          modalTitle="パスワード変更"
                          modalContent={({ close }) => <EditUserPasswordForm uid={id} onSubmit={close} onClose={close} />}
                        >
                          パスワード変更
                        </ModalButton>
                      )}
                      {group && currentUser?.canEditGroupUser() && <EditUserGroupRoleButton group={group} user={user} />}
                      {group && currentUser?.canRemoveGroupUser(group.id) && (
                        <RemoveGroupUserButton group={group} user={user} />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      {group && currentUser && (
        <Invitations group={group} currentUser={currentUser} invitations={groupInvitations}></Invitations>
      )}
    </div>
  ) : (
    <Navigate to="/" />
  );
});
