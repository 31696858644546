import React, { useContext } from 'react';
import { IDeck } from '../../../models/database';
import DeckImageCardRow from './DeckImageCardRow';
import { RoomContext } from '../../pages/Room';

type Props = {
  deck: IDeck;
};

export default function BackImageRow(props: Props) {
  const { deck } = props;
  const { roomRef } = useContext(RoomContext);

  const onChangeUrl = (url: string) => {
    if (!roomRef) return;

    deck.changeImageUrl(deck.allCardIds, 'backImg', url, roomRef.child('objects/cards'));
  };
  return (
    <div className="p-1 mb-1" style={{ border: 'dashed 1px darkgray', borderRadius: '0.5rem' }}>
      {deck.ref != null && (
        <DeckImageCardRow
          deck={deck}
          cardImageRowRef={deck.ref.child('backImage')}
          cardImage={deck.backImage}
          onChangeUrl={onChangeUrl}
        />
      )}
    </div>
  );
}
