import React from 'react';

import DeckComponent from './Deck';
import { IDeck, IStack, IHand } from '../models/database';

type Props = {
  stacks: IStack[];
  decks: IDeck[];
  hands: IHand[];
};

export default function DeckController(props: Props) {
  const { decks } = props;

  return (
    <>
      {decks.map((deck: IDeck) => {
        return <DeckComponent key={deck.key} {...props} deck={deck} />;
      })}
    </>
  );
}
