import { useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { EDIT_MODAL_ZINDEX } from '../../shared/config';
import { RoomContext } from '../pages/Room';
import { firestore } from '../../firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import { Snapshot, IUser } from '../../models/firestore';

const snapshotsRef = firestore.collection('snapshots');

type Props = {
  isOpen: boolean;
  onClose: () => void;
  currentUser?: IUser;
};

export default function ResetGameModal(props: Props) {
  const { isOpen, onClose, currentUser } = props;
  const { room } = useContext(RoomContext);
  const snapshot = useDocumentSubscription(room?.snapshotId ? snapshotsRef.doc(room.snapshotId) : null, Snapshot, [
    room?.snapshotId,
  ]);

  const onSubmit = async () => {
    try {
      if (!snapshot || !room || !currentUser) return;
      await room.import(snapshot, currentUser);
      toast.success('リセットしました');
    } catch (e) {
      console.error(e);
      toast.error('リセットに失敗しました');
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} zIndex={EDIT_MODAL_ZINDEX}>
      <ModalHeader>Reset Game</ModalHeader>
      <ModalBody>ゲームを開始時点にリセットします。宜しいですか？</ModalBody>
      <ModalFooter>
        <Button color="primary" type="submit" onClick={onSubmit} disabled={!snapshot}>
          Reset
        </Button>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
