import React, { useState, useContext } from 'react';
import { Button } from 'reactstrap';
import { flatten } from 'lodash';
import { IStack, IDeck, Stack, IHand, StackObject } from '../models/database';
import { Log } from '../models/firestore';
import { RoomContext } from './pages/Room';
import { firestore, FieldValue } from '../firebase';
import { validateRoom } from '../util';

type Props = {
  stacks: IStack[];
  hands: IHand[];
  deck: IDeck;
  width?: number;
  height?: number;
  className?: string;
};

const logsRef = firestore.collection('logs');

export default function RecallButton(props: Props) {
  const { stacks, hands, deck, height = 40, width = 72, className } = props;
  const { roomRef, scale = 1.0, room, currentUser, isBlocking } = useContext(RoomContext);
  const [showRecallAll, setShowRecallAll] = useState<boolean>(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const stacksRef = roomRef && roomRef.child('objects').child('stacks');
  const style: React.CSSProperties = {
    height: height * scale,
    width: width * scale,
    fontSize: `${18 * scale}px`,
    borderRadius: '25%',
    textAlign: 'center',
    padding: '0 5px',
  };

  const getRecallStacks = (stacks: IStack[], isIncludingHand: boolean): IStack[] => {
    const handsObjects = flatten(hands.map(({ objects }) => objects));
    return stacks
      .filter((stack) => stack.objects.some(({ key }) => deck.allCardIds.includes(key)) && stack.holderId !== deck.key)
      .filter((stack) => isIncludingHand || !handsObjects.includes(stack.key));
  };

  // リコール対象のstacksから削除用の更新オブジェクトを生成する
  const getRemoveUpdates = (recallStacks: IStack[]): [key: string, value: {} | null][] => {
    if (!stacksRef) return [];

    return recallStacks
      .map((stack): [key: string, value: {} | null][] => {
        const stayedCards = stack.objects.filter(({ key }) => !deck.allCardIds.includes(key));
        if (stayedCards.some((card) => card.key === stack.key)) {
          // NOTE: 場に残るカードのkeyにstack.keyが含まれる場合、リコールされるカードを単純に除外する
          const newStack = new Stack({ ...stack.toObject(), objects: stayedCards });
          return [[stack.key, newStack.toObject()]];
        } else {
          // NOTE: 場に残るカードのkeyにstack.keyが含まれない場合、場に残るカードで新たにstackを作る
          if (stayedCards.length) {
            const newStack = new Stack({ ...stack.toObject(), objects: stayedCards });
            const [top] = stayedCards;
            return [
              [top.key, newStack.toObject()],
              [stack.key, null],
            ];
          } else {
            return [[stack.key, null]];
          }
        }
      })
      .flat();
  };

  // 集めたカードから、リコール後の山札更新オブジェクトを生成する
  const getRecalledStackUpdates = (recalledCards: StackObject[]): [key: string, value: {} | null][] => {
    if (recalledCards.length) {
      const newStack = new Stack({
        x: deck.x,
        y: deck.y,
        z: 1,
        width: deck.width,
        height: deck.height,
        objects: recalledCards.map((_) => ({ ..._, opened: false })),
        holderId: deck.key,
      });
      const [top] = recalledCards;
      return [[top.key, newStack.toObject()]];
    }
    return [];
  };

  const recall = async (isIncludingHand: boolean = false) => {
    if (!stacksRef) return;

    await roomRef?.child('isBlocking').set(true);
    const recallStacks: IStack[] = getRecallStacks(stacks, isIncludingHand);
    const recallCards: StackObject[] = recallStacks
      .map(({ objects }) => objects.filter(({ key }) => deck.allCardIds.includes(key)))
      .flat();
    const stack = stacks.find((stack) => stack.holderId === deck.key);

    const removeUpdates = getRemoveUpdates(recallStacks);
    const newStackUpdates = getRecalledStackUpdates(stack ? [...stack.objects, ...recallCards] : recallCards);

    const updates = {
      ...stacks.reduce((acc: any, cur: IStack) => ({ ...acc, [cur.key]: cur.toObject() }), {}),
      ...Object.fromEntries(removeUpdates),
      // NOTE: stack.keyと同じkeyがnewStackUpdatesに含まれる場合、後者で上書きされる
      ...(stack ? { [stack.key]: null } : {}),
      ...Object.fromEntries(newStackUpdates),
    };
    await stacksRef.set(updates);

    // NOTE: recallと手札整列を並列で実行すると処理が重くなるので、recall完了後に手札整列を実行する
    await Promise.all(
      hands.map(async (hand) => {
        hand.arrangeObjects(
          hand.objects.filter((_) => !recallStacks.map(({ key }) => key).includes(_)),
          stacksRef
        );
      })
    );
    await roomRef?.child('isBlocking').set(false);
    // HACK 整合性チェック
    roomRef && validateRoom(roomRef);
  };

  const onRecall = async () => {
    if (isBlocking) return;

    if (deck.recallShouldConfirm) {
      if (!window.confirm('Recallしますか？')) return;
    }
    await recall(false);
    await logging(false);

    setShowRecallAll(true);

    // MEMO: とりあえず2秒
    setTimer(
      setTimeout(() => {
        setShowRecallAll(false);
      }, 2 * 1000)
    );
  };

  const onRecallAll = async () => {
    if (isBlocking) return;

    if (deck.recallAllShouldConfirm) {
      if (!window.confirm('Recall Allしますか？')) return;
    }
    await recall(true);
    await logging(true);

    setShowRecallAll(false);
    timer && clearTimeout(timer);
  };

  const logging = async (isIncludingHand: boolean = false) => {
    if (!room || !room.ref || !currentUser) return;
    const log = new Log({
      roomId: room.id,
      action: 'recall',
      user: { id: currentUser.id, name: currentUser.name },
      target: { type: 'deck', key: deck.key, name: deck.label, remarks: isIncludingHand ? 'all' : '' },
    });
    return Promise.all([
      logsRef.add(log.toObject()),
      room.ref.set(
        { log: { size: FieldValue.increment(1), lastLoggedAt: FieldValue.serverTimestamp() }, isUse: true },
        { merge: true }
      ),
    ]);
  };

  return showRecallAll ? (
    <Button color="info" style={style} className={className || ''} onClick={onRecallAll} disabled={isBlocking}>
      <span>All</span>
    </Button>
  ) : (
    <Button color="info" style={style} className={className || ''} onClick={onRecall} disabled={isBlocking}>
      <span>Recall</span>
    </Button>
  );
}
