import React, { useContext } from 'react';
import { DraggableEvent, DraggableData } from 'react-draggable';
import { IStack, IHolder, IDeck, Stack, ICard } from '../models/database';
import { LoggingHandler } from '../models/firestore';
import { isOverlap } from '../util';
import { STACK_TAB_ZINDEX, BOARD_WIDTH, LIMIT_OF_DROP_CARDS_TO_HAND } from '../shared/config';
import { RoomContext } from './pages/Room';
import DraggableObject from './DraggableObject';

type Props = {
  stack: IStack;
  stacks: IStack[];
  cards: ICard[];
  holders: IHolder[];
  decks: IDeck[];
  width?: number;
  height?: number;
  setDraggingStack: any;
  onLogging: LoggingHandler;
};

export default function StackTab(props: Props) {
  const { stack, stacks, cards, holders, decks, width = 40, height = 40, setDraggingStack, onLogging } = props;
  const { currentUser, hand, roomRef, scale = 1.0, zIndexRef } = useContext(RoomContext);
  const { objects, dragger, owner } = stack;
  const stacksRef = stack.ref?.parent;
  const isDragged = dragger && currentUser && dragger === currentUser.id;
  const countTagStyle: React.CSSProperties = {
    width: width * scale,
    height: height * scale,
    fontSize: `${18 * scale}px`,
    borderRadius: '50%',
    opacity: 0.9,
    color: 'white',
    fontWeight: 'bold',
    paddingTop: `${6 * scale}px`,
    top: `${-42 * scale}px`,
    left: `${-10 * scale}px`,
  };

  const unscaledPosition = ({ x, y }: { x: number; y: number }) => {
    return {
      x: x / scale,
      y: y / scale,
    };
  };

  const moveToFront = () => {
    if (!stack.ref || !roomRef || !zIndexRef) return;

    const frontZ = ++zIndexRef.current;
    stack.ref.update({ z: frontZ });
    roomRef.update({ frontZ });
  };

  const handleDrag = (e: DraggableEvent, position: DraggableData) => {
    const currentStack = new Stack({ ...stack, ...unscaledPosition(position) });
    setDraggingStack(currentStack);
    currentStack.pickFromHolder(holders, decks, onLogging);
    if (hand) {
      if (isOverlap(hand, currentStack)) {
        hand.rearrangeObjects(currentStack);
      } else if (owner) {
        // stacksの一括更新によって束のままのカードが手札に入る可能性があるので
        // StackTabにも手札からStackを削除する処理を入れておく
        moveToFront();
        hand.removeStacks([currentStack], onLogging);
      } else {
        // ドラッグ中に手札ゾーンに入るとzが下がるので、手札ゾーンから出るときに最前面に戻す
        zIndexRef?.current && stack.z < zIndexRef.current && stack.ref?.update({ z: zIndexRef.current });
      }
    }
  };

  const handleStop = async (e: DraggableEvent, position: DraggableData) => {
    const currentStack = new Stack({ ...stack, ...unscaledPosition(position) });

    if (!isDragged) return;

    if (hand && isOverlap(hand, currentStack)) {
      hand.addStack(currentStack, cards, stacks, onLogging);
    } else {
      (await currentStack.dropToHolder(holders, decks, stacks, onLogging)) || (await currentStack.unionOverlapStack(stacks));
      // ドラッグ中のカードを手札の間に入れた場合、手札間に隙間ができた状態になるのでここで手札を整える
      // handleDragで毎回やるのは処理的に重いのでここで実施する
      hand && stacksRef && hand.arrangeObjects(hand.objects, stacksRef);
    }
  };

  const canDropToHand = stack.objects.length <= LIMIT_OF_DROP_CARDS_TO_HAND;
  const boundsWithoutHand = () => {
    if (canDropToHand) return;

    return hand && { top: 0, left: 0, right: (BOARD_WIDTH - width) * scale, bottom: (hand.y - stack.height) * scale };
  };

  const bounds = () => {
    return isDragged
      ? boundsWithoutHand()
      : {
          left: stack.x * scale,
          top: stack.y * scale,
          right: stack.x * scale,
          bottom: stack.y * scale,
        };
  };

  return (
    <DraggableObject
      object={stack}
      onStart={moveToFront}
      onDrag={handleDrag}
      onStop={handleStop}
      disabled={Boolean(dragger && currentUser && dragger !== currentUser.id)}
      style={{ zIndex: STACK_TAB_ZINDEX }}
      bounds={bounds()}
    >
      <div className={`handle panningDisabled ${isDragged ? 'balloon-bottom-orange' : 'balloon-bottom-gray'}`} style={countTagStyle}>
        {objects.length}
      </div>
    </DraggableObject>
  );
}
