import firebase from 'firebase/compat/app';
import { isOverlap } from '../../util';
import { LoggingHandler } from '../firestore';
import { IHolder, IDeck } from '.';

export type StackObject = {
  key: string;
  opened: boolean;
};
export interface IStack {
  key: string;
  ref: firebase.database.Reference | null;
  x: number;
  y: number;
  z: number;
  width: number;
  height: number;
  dragger: string | null;
  owner: string | null;
  holderId: string | null;
  objects: StackObject[];
  toObject: () => {};
  dropToHolder: (holders: IHolder[], decks: IDeck[], stacks: IStack[], onLogging: LoggingHandler) => Promise<boolean>;
  pickFromHolder: (holders: IHolder[], decks: IDeck[], onLogging: LoggingHandler) => void;
  unionOverlapStack: (stacks: IStack[]) => Promise<void>;
}

const HOLDER_OVERLAP_DETECTION_RATIO = 0.5;
const STACK_OVERLAP_DETECTION_RATIO = 0.9;

export class Stack implements IStack {
  key: string = '';
  ref: firebase.database.Reference | null = null;
  x: number = 0;
  y: number = 0;
  z: number = 0;
  width: number = 0;
  height: number = 0;
  dragger: string | null = null;
  holderId: string | null = null;
  owner: string | null = null;
  objects: StackObject[] = [];

  constructor({
    key = '',
    ref = null,
    x = 0,
    y = 0,
    z = 0,
    width = 0,
    height = 0,
    dragger = null,
    owner = null,
    holderId = null,
    objects = [],
  }: Partial<IStack>) {
    Object.assign(this, {
      key,
      ref,
      x,
      y,
      z,
      width,
      height,
      dragger,
      owner,
      holderId,
      objects,
    });
  }

  toObject(): {} {
    return {
      x: this.x,
      y: this.y,
      z: this.z,
      width: this.width,
      height: this.height,
      dragger: this.dragger,
      owner: this.owner,
      holderId: this.holderId,
      objects: this.objects,
    };
  }

  async unionStack(stack: IStack, stacks: IStack[]): Promise<void> {
    const { key: removeKey, objects } = stack;
    const newStack = new Stack({
      ...stack,
      objects: [...this.objects, ...objects],
      width: this.width,
      height: this.height,
    });
    const updates = {
      ...stacks.reduce((acc: any, cur: IStack) => ({ ...acc, [cur.key]: cur.toObject() }), {}),
      [removeKey]: null,
      [this.key]: newStack.toObject()
    }
    return await this.ref?.parent?.set(updates);
  }

  async dropToHolder(holders: IHolder[], decks: IDeck[], stacks: IStack[], onLogging: LoggingHandler): Promise<boolean> {
    const holder = [...holders, ...decks].find((holder) => isOverlap(this, holder, HOLDER_OVERLAP_DETECTION_RATIO));
    if (holder) {
      const overlap = stacks.filter((_) => _.key !== this.key).find((_) => _.holderId === holder.key);
      overlap
        ? await this.unionStack(overlap, stacks)
        : await this.ref?.update({ x: holder.x, y: holder.y, holderId: holder.key });
      onLogging('drop', this, holder);
    }
    return !!holder;
  }

  pickFromHolder(holders: IHolder[], decks: IDeck[], onLogging: LoggingHandler) {
    const holder = [...holders, ...decks].find(({ key }) => key === this.holderId);
    if (!holder) return;

    this.ref?.update({ holderId: null });
    onLogging('pick', this, holder);
  }

  async unionOverlapStack(stacks: IStack[]) {
    const overlap = stacks
      .filter(({ owner, dragger, key }) => !owner && !dragger && key !== this.key)
      .find((stack) => isOverlap(stack, this, STACK_OVERLAP_DETECTION_RATIO));
    overlap && await this.unionStack(overlap, stacks);
  }
}
