import React, { useState, useEffect, useContext } from 'react';

import Card from './Card';
import StackTab from './StackTab';
import { IStack, ICard, IHolder, IDeck, Stack as StackModel, StackObject, IHand } from '../models/database';
import { LoggingAction, Log } from '../models/firestore';
import { RoomContext } from './pages/Room';
import { firestore, FieldValue } from '../firebase';

const logsRef = firestore.collection('logs');

type Props = {
  stack: IStack;
  stacks: IStack[];
  cards: ICard[];
  holders: IHolder[];
  decks: IDeck[];
};

export default function Stack(props: Props) {
  const { stack, cards, decks } = props;
  const { objects } = stack;
  const { room, currentUser } = useContext(RoomContext);
  const [draggingStack, setDraggingStack] = useState(stack);
  const [visibleObjects, setVisibleObjects] = useState<StackObject[]>(objects.slice(0, 2));

  useEffect(() => {
    setDraggingStack(
      stack.dragger !== currentUser?.id ? stack : new StackModel({ ...stack, x: draggingStack.x, y: draggingStack.y })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stack, currentUser]);

  useEffect(() => {
    const { objects: draggingObjects } = draggingStack;
    setVisibleObjects(draggingObjects.slice(0, 2));
  }, [draggingStack]);

  const onLogging = (action: LoggingAction, stack: IStack, space: IHolder | IDeck | IHand) => {
    Promise.all(
      stack.objects.map(async ({ key: cardKey, opened }) => {
        const card = cards.find(({ key }) => key === cardKey);
        const deckCard = decks
          .map((_) => _.deckCards)
          .flat()
          .find((_) => _.cardIds.includes(cardKey));
        if (!room || !room.ref || !currentUser || !card || !deckCard) return;

        const log = new Log({
          roomId: room.id,
          action,
          user: { id: currentUser.id, name: currentUser.name },
          target: { type: 'card', key: card.key, name: deckCard.name, remarks: opened ? '表' : '裏' },
          space: space.toLogFormat,
          stack: { key: stack.key },
        });
        return Promise.all([
          logsRef.add(log.toObject()),
          room.ref.set({ log: { size: FieldValue.increment(1), lastLoggedAt: FieldValue.serverTimestamp() }, isUse: true }, { merge: true }),
        ]);
      })
    );
  };

  return (
    <>
      {visibleObjects.map((object, index) => {
        const ref = draggingStack?.ref?.child(`objects/${index}`);
        if (!ref) return <></>;

        return (
          <Card
            key={object.key}
            {...props}
            object={{ ...object, ref, x: draggingStack.x, y: draggingStack.y, z: draggingStack.z - index }}
            stack={draggingStack}
            onLogging={onLogging}
          />
        );
      })}
      {draggingStack.objects.length > 1 && (
        <StackTab {...props} stack={draggingStack} setDraggingStack={setDraggingStack} onLogging={onLogging} />
      )}
    </>
  );
}
