import firebase from 'firebase/compat/app';
import { FieldValue } from '../../firebase';
import { GroupRole } from './user';

export interface IInvitation {
  id: string;
  ref: firebase.firestore.DocumentReference | null;
  email: string;
  role: GroupRole;
  groupId: string;
  result: string;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
}

export class Invitation implements IInvitation {
  id: string = '';
  ref: firebase.firestore.DocumentReference | null = null;
  email: string = '';
  role: GroupRole = 'player';
  groupId: string = '';
  result: string = '';
  createdAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
  updatedAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp;

  constructor({
    id = '',
    ref = null,
    email = '',
    role = 'player',
    groupId = '',
    result = '',
    createdAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    updatedAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
  }: Partial<IInvitation>) {
    Object.assign(this, {
      id,
      ref,
      email,
      role,
      groupId,
      result,
      createdAt,
      updatedAt,
    });
  }

  toObject() {
    return {
      email: this.email,
      role: this.role,
      groupId: this.groupId,
      result: this.result,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }
}
