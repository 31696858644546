import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from 'reactstrap';
import Footer from '../Footer';

export default function PublicPageHOC(WrappedComponent: any) {
  return function PublicPage(props: any) {
    return (
      <>
        <div className="page">
          <header className="header-nav">
            <Navbar color="light" light className="justify-content-between">
              <Link to="/" className="navbar-brand">
                Onlineアナログゲーム
              </Link>
            </Navbar>
          </header>
          <WrappedComponent {...props} />
          <Footer />
        </div>
      </>
    );
  };
}
