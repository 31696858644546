import { Badge } from 'reactstrap';
import { keyBy, orderBy } from 'lodash';

import { firestore, database } from '../../firebase';
import AddButton from '../AddButton';
import DeleteButton from '../DeleteButton';
import JoinButton from '../JoinButton';
import EditButton from '../EditButton';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import { Room, IRoom, roomFormFields, Group } from '../../models/firestore';
import Page from '../hocs/Page';

const firestoreRoomsRef = firestore.collection('rooms');
const databaseRoomsRef = database.ref('rooms');
const firestoreGroupsRef = firestore.collection('groups');

export default Page(function Dashboard(props: any) {
  const { currentUser } = props;
  const activeRoomsRef = firestoreRoomsRef.where('deletedAt', '==', null);
  const readableRoomsRef =
    currentUser &&
    (currentUser.isSysAdmin ? activeRoomsRef : activeRoomsRef.where('userIds', 'array-contains', currentUser.id));
  const rooms = useCollectionSubscription(readableRoomsRef?.orderBy('groupId'), Room, []);
  const readableGroupsRef =
    currentUser &&
    (currentUser.isSysAdmin ? firestoreGroupsRef : firestoreGroupsRef.where('userIds', 'array-contains', currentUser.id));
  const groups = useCollectionSubscription(readableGroupsRef, Group, []);
  const groupsById = keyBy(groups, 'id');
  const roomsWithGroups = rooms.map((room: IRoom) => {
    const { groupId } = room;
    const group = groupsById[groupId];
    return {
      room,
      group,
    };
  });
  const sortedRoomsWithGroups = orderBy(roomsWithGroups, ['group.name', 'room.name'], ['asc', 'asc']);
  const processValues = (values: any) => {
    const { groupId } = values;
    const { userIds = [] } = groups.find(({ id }) => id === groupId) || {};
    return {
      ...values,
      userIds,
    };
  };

  return (
    <div>
      <div className="container py-5 position-relative">
        <h4>ルーム一覧</h4>
        {currentUser?.canCreateRoom() && (
          <div className="d-flex justify-content-end">
            {/* TODO: ルーム追加時にgroupのuserIdsを設定する */}
            <AddButton
              itemRef={firestoreRoomsRef.doc()}
              FormModal={ModelFormModal}
              processValues={processValues}
              formProps={{ title: 'ルーム作成', fields: roomFormFields({ groups }) }}
              isLogicalDelete
            />
          </div>
        )}
        <table className="table table-bordered mt-4">
          <thead>
            <tr>
              <th>グループ名</th>
              <th>ルーム名</th>
              <th>説明</th>
              <th>使用状況</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sortedRoomsWithGroups.map(({ room, group }) => {
              const { id, ref, name, description, isUse } = room;
              const databaseRef = databaseRoomsRef.child(id);

              return (
                <tr key={id}>
                  <td>{group?.name}</td>
                  <td>{name}</td>
                  <td>{description}</td>
                  <td>
                    <h5>
                      <Badge color={isUse ? 'secondary' : 'info'} style={{ width: '64px' }} pill>
                        {isUse ? '使用中' : '空室'}
                      </Badge>
                    </h5>
                  </td>
                  <td className="text-end text-nowrap">
                    <JoinButton item={room} itemRef={ref} />
                    {currentUser?.canEditRoom() && (
                      <EditButton
                        initialValues={room}
                        itemRef={ref}
                        FormModal={ModelFormModal}
                        processValues={processValues}
                        // TODO: グループ管理者も編集できるようにするときはここで指定するgroupsを管理者権限を持っているgroupsのみに絞る必要がある
                        formProps={{ title: 'ルーム編集', fields: roomFormFields({ groups }) }}
                        className="ms-2"
                      />
                    )}
                    {currentUser?.canDeleteRoom() && (
                      <DeleteButton storeRef={ref} databaseRef={databaseRef} className="ms-2" isLogicalDelete />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
});
