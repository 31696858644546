import firebase from 'firebase/compat/app';
import { FieldValue } from '../../firebase';

export interface IGroup {
  id: string;
  ref: firebase.firestore.DocumentReference | null;
  name: string;
  capacity: number;
  userIds: string[];
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
}

export const groupFormFields = {
  name: {
    label: 'グループ名',
    type: 'string',
  },
  capacity: {
    label: '参加可能人数',
    type: 'integer',
  },
};

export class Group implements IGroup {
  id: string = '';
  ref: firebase.firestore.DocumentReference | null = null;
  name: string = '';
  capacity: number = 0;
  userIds: string[] = [];
  createdAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
  updatedAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp;

  constructor({
    id = '',
    ref = null,
    name = '',
    capacity = 0,
    userIds = [],
    createdAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    updatedAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
  }: Partial<IGroup>) {
    Object.assign(this, {
      id,
      ref,
      name,
      capacity,
      userIds,
      createdAt,
      updatedAt,
    });
  }

  toObject(): {} {
    return {
      name: this.name,
      capacity: this.capacity,
      userIds: this.userIds,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }
}
