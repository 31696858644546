import firebase from 'firebase/compat/app';
import { isEmpty } from 'lodash';
import { firestore, database, FieldValue } from '../../firebase';
import { IUser, Log, IGroup, ISnapshot } from '.';

const databaseRoomsRef = database.ref('rooms');
const databaseSnapshotsRef = database.ref('snapshots');
const logsRef = firestore.collection('logs');

export interface IRoom {
  id: string;
  ref: firebase.firestore.DocumentReference | null;
  name: string;
  description: string;
  snapshotId: string;
  groupId: string;
  log: { size: number; lastLoggedAt: firebase.firestore.Timestamp } | null;
  isUse: boolean;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  deletedAt: firebase.firestore.Timestamp | null;
  import: (snapshot: ISnapshot, currentUser: IUser) => Promise<void>;
}

export const roomFormFields = ({ groups = [] }: { groups: IGroup[] }) => {
  return {
    name: {
      label: 'ルーム名',
      type: 'string',
    },
    description: {
      label: '説明',
      type: 'text',
    },
    groupId: {
      label: 'グループ',
      type: 'select',
      options: groups.map(({ name, id }) => ({ label: name, value: id })),
      validations: {
        required: (v: any) => !isEmpty(v),
      },
    },
  };
};

export class Room implements IRoom {
  id: string = '';
  ref: firebase.firestore.DocumentReference | null = null;
  name: string = '';
  description: string = '';
  snapshotId: string = '';
  groupId: string = '';
  log: { size: number; lastLoggedAt: firebase.firestore.Timestamp } | null = null;
  isUse: boolean = false;
  createdAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
  updatedAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
  deletedAt = null;

  constructor({
    id = '',
    ref = null,
    name = '',
    description = '',
    snapshotId = '',
    groupId = '',
    log = null,
    isUse = false,
    createdAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    updatedAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    deletedAt = null,
  }: Partial<IRoom>) {
    Object.assign(this, {
      id,
      ref,
      name,
      description,
      snapshotId,
      groupId,
      log,
      isUse,
      createdAt,
      updatedAt,
      deletedAt,
    });
  }

  toObject(): {} {
    return {
      name: this.name,
      description: this.description,
      snapshotId: this.snapshotId,
      groupId: this.groupId,
      log: this.log,
      isUse: this.isUse,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt,
    };
  }

  async import(snapshot: ISnapshot, currentUser: IUser) {
    const data = (await databaseSnapshotsRef.child(snapshot.id).once('value')).val();
    await databaseRoomsRef.child(this.id).set(data);
    const log = new Log({
      roomId: this.id,
      action: 'import',
      user: { id: currentUser.id, name: currentUser.name },
      target: { type: 'snapshot', key: snapshot.id, name: snapshot.name, remarks: snapshot.comment },
    });
    await Promise.all([
      logsRef.add(log.toObject()),
      this.ref?.set(
        { snapshotId: snapshot.id, log: { size: FieldValue.increment(1), lastLoggedAt: FieldValue.serverTimestamp() }, isUse: false },
        { merge: true }
      ),
    ]);
  }
}
