import { Navbar, Nav } from 'reactstrap';

export default function UnsupportedMessage() {
  return (
    <>
      <header className="header-nav">
        <Navbar color="light" light className="justify-content-between">
          <Nav className="navbar-brand">Onlineアナログゲーム</Nav>
        </Navbar>
      </header>
      <div className="container py-5 position-relative">
        <div className="d-flex justify-content-center">
          スマートフォンではご利用になれません。
          <br />
          恐れ入りますがPC/タブレットでご利用ください。
        </div>
      </div>
    </>
  );
}
