import React, { useContext } from 'react';

import Stack from './Stack';
import { IStack, IHolder, IDeck, ICard } from '../models/database';
import { RoomContext } from './pages/Room';

type Props = {
  stacks: IStack[];
  cards: ICard[];
  holders: IHolder[];
  decks: IDeck[];
};

export default function StackController(props: Props) {
  const { stacks } = props;
  const { currentUser } = useContext(RoomContext);

  return (
    <>
      {stacks
        .filter((_) => !_.owner || (currentUser && _.owner === currentUser.id))
        .map((stack: IStack) => {
          return <Stack key={stack.key} {...props} stack={stack} />;
        })}
    </>
  );
}
