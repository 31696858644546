import firebase from 'firebase/compat/app';
import React, { useRef, useContext } from 'react';
import { useToggle } from 'react-use';
import { DraggableEvent, DraggableData } from 'react-draggable';
import { IStack, ICard, IHolder, IDeck, Stack, StackObject } from '../models/database';
import { LoggingHandler } from '../models/firestore';
import { isOverlap } from '../util';
import { RoomContext } from './pages/Room';
import { ENLARGE_ZINDEX, BOARD_WIDTH, BOARD_HEIGHT } from '../shared/config';
import DraggableObject from './DraggableObject';

type Props = {
  object: StackObject & { ref: firebase.database.Reference; x: number; y: number; z: number };
  stack: IStack;
  cards: ICard[];
  stacks: IStack[];
  holders: IHolder[];
  decks: IDeck[];
  onLogging: LoggingHandler;
};

export default function Card(props: Props) {
  const { object, stack, cards, stacks, holders, decks, onLogging } = props;
  const { currentUser, hand, roomRef, scale = 1.0, zIndexRef } = useContext(RoomContext);
  const card = { ...cards.find(({ key }) => key === object.key), ...object };
  const { frontImg, backImg, opened, ref } = card || {};
  const { width, height, dragger, owner } = stack;
  const stacksRef = stack.ref?.parent;
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const enlargeTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [showsEnlarge, toggleEnlarge] = useToggle(false);
  const isDragged = dragger && currentUser && dragger === currentUser.id;
  const imgSrc = opened ? frontImg : backImg;
  const imgStyle: React.CSSProperties = {
    width: width * scale,
    height: height * scale,
    border: isDragged ? '2px solid orange' : 'none',
    borderRadius: '5px',
  };
  const enlargeScale = Math.min(BOARD_WIDTH / width, BOARD_HEIGHT / height) * 0.9;
  const enlargeWidth = width * enlargeScale;
  const enlargeHeight = height * enlargeScale;
  const enlargeImgStyle: React.CSSProperties = {
    zIndex: ENLARGE_ZINDEX,
    position: 'absolute',
    width: enlargeWidth * scale,
    height: enlargeHeight * scale,
    pointerEvents: 'none',
    transform: `translate(0px, ${((BOARD_HEIGHT - enlargeHeight) * scale) / 2.5}px)`,
  };
  const enlargeIconFontSize = 70;
  const enlargeIconStyle: React.CSSProperties = {
    position: 'absolute',
    fontSize: `${enlargeIconFontSize * scale}px`,
    color: 'orange',
    opacity: 0.5,
    pointerEvents: 'none',
    transform: `translate(${((width - enlargeIconFontSize) * scale) / 2}px, ${((height - enlargeIconFontSize) * scale) / 2}px)`,
  };

  const unscaledPosition = ({ x, y }: { x: number; y: number }) => {
    return {
      x: x / scale,
      y: y / scale,
    };
  };

  const enlarge = () => {
    toggleEnlarge(true);
  };

  const shrink = () => {
    toggleEnlarge(false);
  };

  const flip = () => {
    ref.update({ opened: !opened });
  };

  const moveToFront = () => {
    if (!roomRef || !zIndexRef || !stack.ref) return;

    const frontZ = ++zIndexRef.current;
    stack.ref.update({ z: frontZ });
    roomRef.update({ frontZ });
  };

  const cancelTimer = () => {
    if (!timerRef.current) return;

    clearTimeout(timerRef.current);
    timerRef.current = null;
  };

  const cancelEnlargeTimer = () => {
    if (!enlargeTimerRef.current) return;

    clearTimeout(enlargeTimerRef.current);
    enlargeTimerRef.current = null;
  }

  const takeOneCard = async () => {
    if (!stacksRef || !zIndexRef) return false;

    const { objects } = stack;
    const [taken, ...rest] = objects;
    const [{ key }] = rest;
    const restTop = cards.find((_) => _.key === key);
    const { width, height } = restTop || {};
    const restStack = { ...stack.toObject(), objects: rest, z: zIndexRef.current, width, height, dragger: null };
    const updates = {
      ...stacks.reduce((acc: any, cur: IStack) => ({ ...acc, [cur.key]: cur.toObject() }), {}),
      [key]: restStack,
      [stack.key]: {
        ...stack.toObject(),
        objects: [taken],
        z: zIndexRef.current + 1,
        holderId: null,
        dragger: currentUser?.id,
      }
    };
    await stacksRef.set(updates);
    moveToFront();
  };

  const handleStart = (e: DraggableEvent, position: DraggableData) => {
    const currentStack = new Stack({ ...stack, ...unscaledPosition(position) });

    timerRef.current = setTimeout(async () => {
      timerRef.current = null;

      if (!owner) {
        if (currentStack.objects.length > 1) {
          await takeOneCard();
        } else {
          await stack.ref?.update({ dragger: currentUser?.id });
          moveToFront();
          currentStack.pickFromHolder(holders, decks, onLogging);
        }
      } else {
        await stack.ref?.update({ dragger: currentUser?.id });
      }

      enlargeTimerRef.current = setTimeout(() => {
        enlarge();
      }, 200);
    }, 150);
  };

  const handleDrag = (e: DraggableEvent, position: DraggableData) => {
    const currentStack = new Stack({ ...stack, ...unscaledPosition(position) });

    if (hand) {
      if (isOverlap(hand, currentStack)) {
        hand.rearrangeObjects(currentStack);
      } else if (owner) {
        moveToFront();
        hand.removeStacks([currentStack], onLogging);
      } else {
        // ドラッグ中に手札ゾーンに入るとzが下がるので、手札ゾーンから出るときに最前面に戻す
        zIndexRef?.current && stack.z < zIndexRef.current && stack.ref?.update({ z: zIndexRef.current });
      }
    }

    if (enlargeTimerRef.current) {
      cancelEnlargeTimer();
    }
    if (showsEnlarge) {
      shrink();
    }
  };

  const handleStop = async (e: DraggableEvent, position: DraggableData) => {
    const currentStack = new Stack({ ...stack, ...unscaledPosition(position) });

    if (timerRef.current) {
      cancelTimer();
      flip();
      return;
    }

    currentStack.ref?.update({ dragger: null });

    if (enlargeTimerRef.current) {
      cancelEnlargeTimer();
    }
    if (showsEnlarge) {
      shrink();
    }

    if (!isDragged) return;

    if (hand && isOverlap(hand, currentStack)) {
      hand.addStack(currentStack, cards, stacks, onLogging);
    } else {
      currentStack.holderId || await currentStack.dropToHolder(holders, decks, stacks, onLogging) || await currentStack.unionOverlapStack(stacks);
      // ドラッグ中のカードを手札の間に入れた場合、手札間に隙間ができた状態になるのでここで手札を整える
      // handleDragで毎回やるのは処理的に重いのでここで実施する
      hand && stacksRef && hand.arrangeObjects(hand.objects, stacksRef);
    }
  };

  const onCancel = () => {
    showsEnlarge && shrink();
  };

  const bounds = () => {
    return isDragged
      ? null
      : {
          left: stack.x * scale,
          top: stack.y * scale,
          right: stack.x * scale,
          bottom: stack.y * scale,
        };
  };

  !isDragged && showsEnlarge && shrink();

  return (
    <>
      <DraggableObject
        object={{ ...card, dragger }}
        objectRef={stack.ref}
        onStart={handleStart}
        onDrag={handleDrag}
        onStop={handleStop}
        onCancel={onCancel}
        disabled={Boolean(dragger && currentUser && dragger !== currentUser.id)}
        bounds={bounds()}
        disableDraggerUpdating
      >
        <>
          {isDragged && <div className="fas fa-hand-rock" style={enlargeIconStyle} />}
          <img src={imgSrc} draggable="false" style={imgStyle} alt="card component" />
        </>
      </DraggableObject>
      {showsEnlarge && (
        <div className="d-flex justify-content-center">
          <img src={imgSrc} style={enlargeImgStyle} alt="card component" />
        </div>
      )}
    </>
  );
}
