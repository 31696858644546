import { FieldValue } from '../firebase';
import { omit } from 'lodash';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { useToggle } from 'react-use';
import classnames from 'classnames';

export default function AddButton(props: any) {
  const {
    initialValues,
    validateOnSubmit = (_: any) => true,
    itemRef,
    FormModal,
    formProps,
    processValues = (_: any) => _,
    label = '追加',
    iconClassName = 'fas fa-plus',
    onFinish,
    isLogicalDelete = false,
    ...extraProps
  } = props;
  const { id } = itemRef;
  const [showsModal, toggleModal] = useToggle(false);

  const onSubmit = async (values: any) => {
    if (!(await validateOnSubmit({ id, ...values }))) return;

    try {
      await itemRef.set({
        ...omit(await processValues({ id, ...values }), ['id', 'ref']),
        createdAt: FieldValue.serverTimestamp(),
        ...(isLogicalDelete ? { deletedAt: null } : {}),
      });
      onFinish && (await onFinish({ id, ...values }));
      toast.success('追加しました');
      toggleModal(false);
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <Button color="primary" onClick={(_) => toggleModal(true)} {...extraProps}>
      <span className={classnames(iconClassName, 'me-1')} />
      {label}
      {FormModal && (
        <FormModal
          isOpen={showsModal}
          values={initialValues}
          onClickClose={(_: any) => toggleModal(false)}
          onSubmit={onSubmit}
          {...formProps}
        />
      )}
    </Button>
  );
}
