import React, { useState } from 'react';
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  FormFeedback,
} from 'reactstrap';
import isInt from 'validator/lib/isInt';
import { IHolder } from '../../models/database';
import { scaleToPercent, percentToScale } from '../../util';
import { EDIT_MODAL_ZINDEX } from '../../shared/config';

const validIntOption = { min: 50, max: 500 };

type Props = {
  isOpen: boolean;
  onClose: () => void;
  holder: IHolder;
};

export default function EditModal(props: Props) {
  const { isOpen, onClose, holder } = props;
  const [width, setWidth] = useState<string>(holder.actualSize.width.toString());
  const [height, setHeight] = useState<string>(holder.actualSize.height.toString());
  const [scale, setScale] = useState<number>(holder.actualSize.scale);

  const onChangeLabel = ({ target: { value } }: { target: { value: string } }) => {
    if (!holder.ref) return;

    holder.ref.update({ label: value });
  };

  const onChangeWidth = ({ target: { value } }: { target: { value: string } }) => {
    setWidth(value);
    if (!holder.ref || !isInt(value, validIntOption)) return;

    holder.ref.update({
      width: Number(value) * scale,
      actualSize: { ...holder.actualSize, width: Number(value) },
    });
  };

  const onChangeHeight = ({ target: { value } }: { target: { value: string } }) => {
    setHeight(value);
    if (!holder.ref || !isInt(value, validIntOption)) return;

    holder.ref.update({
      height: Number(value) * scale,
      actualSize: { ...holder.actualSize, height: Number(value) },
    });
  };

  const onChangeScale = ({ target: { value } }: { target: { value: string } }) => {
    const changedScale = percentToScale(Number(value));
    setScale(changedScale);
    if (!holder.ref || !isInt(value, validIntOption)) return;

    holder.ref.update({
      width: Number(width) * changedScale,
      height: Number(height) * changedScale,
      actualSize: { ...holder.actualSize, scale: changedScale },
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} zIndex={EDIT_MODAL_ZINDEX}>
      <ModalHeader>Edit Holder</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>Label</Label>
            <Input value={holder.label} onChange={onChangeLabel} />
          </FormGroup>
          <FormGroup>
            <Label>Holder Size (width / height / scale)</Label>
            <Row>
              <Col md={4}>
                <InputGroup>
                  <Input value={width} onChange={onChangeWidth} invalid={!isInt(width, validIntOption)} />
                  <div className="input-group-append">
                    <InputGroupText>mm</InputGroupText>
                  </div>
                  <FormFeedback>50~500の範囲で指定してください</FormFeedback>
                </InputGroup>
              </Col>
              <Col md={4}>
                <InputGroup>
                  <Input
                    value={height}
                    onChange={onChangeHeight}
                    invalid={!isInt(height, validIntOption)}
                  />
                  <div className="input-group-append">
                    <InputGroupText>mm</InputGroupText>
                  </div>
                  <FormFeedback>50~500の範囲で指定してください</FormFeedback>
                </InputGroup>
              </Col>
              <Col md={4}>
                <InputGroup>
                  <Input
                    value={scaleToPercent(scale)}
                    onChange={onChangeScale}
                    invalid={!isInt(scaleToPercent(scale).toString(), validIntOption)}
                  />
                  <div className="input-group-append">
                    <InputGroupText>%</InputGroupText>
                  </div>
                  <FormFeedback>50~500の範囲で指定してください</FormFeedback>
                </InputGroup>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
