import { useContext, useEffect } from 'react';
import { Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, FormFeedback } from 'reactstrap';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { EDIT_MODAL_ZINDEX, URL_REGEX } from '../../shared/config';
import { RoomContext } from '../pages/Room';

const HELP_URL_REGEX = new RegExp('^$|' + URL_REGEX.toString().slice(1, -1));

type Props = {
  isOpen: boolean;
  onClose: () => void;
  initialValue?: string;
};

type IFormInputs = {
  helpUrl: string;
};

export default function HelpEditModal(props: Props) {
  const { isOpen, onClose, initialValue } = props;
  const { roomRef } = useContext(RoomContext);
  const helpUrlRef = roomRef?.child('settings/helpUrl');
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({ shouldUnregister: false });
  const { ref, ...rest } = register('helpUrl', { pattern: { value: HELP_URL_REGEX, message: '不当なURLです' } });

  const onSubmit = async (values: IFormInputs) => {
    try {
      await helpUrlRef?.set(values.helpUrl);
      toast.success('設定しました');
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
    onClose();
  };

  useEffect(() => {
    setValue('helpUrl', initialValue || '');
  }, [initialValue, setValue]);

  return (
    <Modal isOpen={isOpen} toggle={onClose} zIndex={EDIT_MODAL_ZINDEX}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Edit Help URL</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Help URL</Label>
            <Input invalid={!!errors.helpUrl} {...rest} innerRef={ref} />
            {errors.helpUrl && <FormFeedback>{errors.helpUrl.message}</FormFeedback>}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
          <Button color="secondary" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
