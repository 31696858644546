import firebase from 'firebase/compat/app';
import { useState, useEffect } from 'react';

import { auth } from '../../firebase';

export default function useFirebaseUser(): { firebaseUser: firebase.User | null; isLoading: boolean } {
  const [firebaseUser, setFirebaseUser] = useState<firebase.User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    auth.onAuthStateChanged((firebaseUser) => {
      setFirebaseUser(firebaseUser);
      setIsLoading(false);
    });
  }, []);

  return { firebaseUser, isLoading };
}
