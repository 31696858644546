import { Button } from 'reactstrap';
import { useToggle } from 'react-use';

import { IUser, IGroup } from '../models/firestore';
import UserGroupRoleFormModal from './modals/UserGroupRoleFormModal';

type Props = {
  user: IUser;
  group: IGroup;
  className?: string;
};

export default function EditUserGroupRoleButton(props: Props) {
  const { user, group, className = '' } = props;
  const [showsModal, toggleModal] = useToggle(false);

  return (
    <>
      <Button color="secondary" onClick={() => toggleModal(true)} className={className}>
        <span className="fas fa-edit me-1" />
        編集
      </Button>
      <UserGroupRoleFormModal user={user} group={group} isOpen={showsModal} onClose={() => toggleModal(false)} />
    </>
  );
}
