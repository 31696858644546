import firebase from 'firebase/compat/app';

export type CardImage = { fileName: string; url: string; ref: string };

export interface IDeckCard {
  key: string;
  ref: firebase.database.Reference | null;
  name: string;
  count: number;
  front: CardImage | null;
  back: CardImage | null;
  cardIds: string[];
}

export class DeckCard implements IDeckCard {
  key: string = '';
  ref: firebase.database.Reference | null = null;
  name: string = '';
  count: number = 0;
  front: CardImage | null = null;
  back: CardImage | null = null;
  cardIds: string[] = [];

  constructor({ key = '', ref = null, name = '', count = 0, front = null, back = null, cardIds = [] }: Partial<IDeckCard>) {
    Object.assign(this, {
      key,
      ref,
      name,
      count,
      front,
      back,
      cardIds,
    });
  }

  toObject(): {} {
    return {
      name: this.name,
      count: this.count,
      front: this.front,
      back: this.back,
      cardIds: this.cardIds,
    };
  }
}
