import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from 'reactstrap';

export default function NotFound(props: any) {
  const centerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30vh',
  };

  return (
    <div className="page-not-found">
      <header className="header-nav">
        <Navbar color="light" light className="justify-content-between">
          <Link to="/" className="navbar-brand">
            Onlineアナログゲーム
          </Link>
        </Navbar>
      </header>
      <div className="" style={centerStyle}>
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <h1>Page Not Found!</h1>
            <div className="mb-4 lead">申し訳ありません。お探しのページが見つかりませんでした。</div>
            <Link to="/">トップへ戻る</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
