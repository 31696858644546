import { useContext } from 'react';

import { IHand } from '../models/database';
// import { isOverlap } from '../util';
import { RoomContext } from './pages/Room';

type Props = {
  hand: IHand;
};

export default function Hand(props: Props) {
  const { hand } = props;
  const { scale = 1.0 } = useContext(RoomContext);
  const { x, y, width, height } = hand;
  // const [overlap, setOverlap] = useState<boolean>(false);
  const handStyle: any = {
    width: width * scale,
    height: height * scale,
    backgroundColor: 'lightgray',
    position: 'absolute',
    margin: '0 auto',
    borderRadius: '10px 10px 0 0',
    // border: overlap ? '1px solid black' : 'none',
    transform: `translate(${x * scale}px, ${y * scale}px)`,
  };

  // カード重なったときの強調表示が求められたらコメントアウトはずす
  // useEffect(() => {
  //   if (!hand || !roomRef) return;

  //   roomRef.child('objects/stacks').on('child_changed', (snapshot: any) => {
  //     if (!snapshot.val()) return;

  //     setOverlap(isOverlap(hand, snapshot.val()));
  //   });
  // }, [hand, roomRef]);

  return <div style={handStyle}></div>;
}
