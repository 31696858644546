import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import 'firebase/compat/functions';

import env from './env';
import { config } from './firebaseConfig';

firebase.initializeApp(config);

export const auth = firebase.auth();
if (env('AUTH_EMULATOR') === 'true') {
  auth.useEmulator('http://localhost:9099/');
}

export const functions: firebase.functions.Functions = firebase.app().functions('asia-northeast1');
if (env('CLOUD_FUNCTIONS_EMULATOR') === 'true') {
  functions.useEmulator('localhost', 5001);
}

export const firestore: firebase.firestore.Firestore = firebase.firestore();
if (env('FIRESTORE_EMULATOR') === 'true') {
  firestore.settings({
    host: 'localhost:8080',
    ssl: false,
    experimentalForceLongPolling: true,
  });
}

export const database = firebase.database();
if (env('DATABASE_EMULATOR') === 'true') {
  database.useEmulator('localhost', 9000);
}

export const storage = firebase.storage();
export const EmailAuthProvider = firebase.auth.EmailAuthProvider;
export const Timestamp = firebase.firestore.Timestamp;
export const FieldValue = firebase.firestore.FieldValue;
