import firebase from 'firebase/compat/app';
import { FieldValue } from '../../firebase';

export interface ISnapshot {
  id: string;
  ref: firebase.firestore.DocumentReference | null;
  name: string;
  comment: string;
  owner: string;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
}

export class Snapshot implements ISnapshot {
  id: string = '';
  ref: firebase.firestore.DocumentReference | null = null;
  name: string = '';
  comment: string = '';
  owner: string = '';
  createdAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
  updatedAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp;

  constructor({
    id = '',
    ref = null,
    name = '',
    comment = '',
    owner = '',
    createdAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    updatedAt = FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
  }: Partial<ISnapshot>) {
    Object.assign(this, {
      id,
      ref,
      name,
      comment,
      owner,
      createdAt,
      updatedAt,
    });
  }

  toObject(): {} {
    return {
      name: this.name,
      comment: this.comment,
      owner: this.owner,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }
}
