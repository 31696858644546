import { useState } from 'react';
import { useToggle } from 'react-use';
import { useForm } from 'react-hook-form';
import { Button, Form, FormGroup, Label, Input, Modal, ModalBody, ModalFooter, Alert, FormFeedback } from 'reactstrap';
import { auth } from '../../firebase';
import UnAuthPage from '../hocs/UnAuthPage';
import ResetPasswordModal from '../modals/ResetPasswordModal';
import { EMAIL_DUMMY_DOMAIN } from '../../shared/config';

const ERRORS: { [key: string]: string } = {
  'auth/invalid-email': 'メールアドレスの形式が不正です。',
  'auth/wrong-password': 'メールアドレスかパスワードが誤っています。',
  'auth/user-not-found': 'メールアドレスかパスワードが誤っています。',
};

type IFormInputs = {
  email: string;
  password: string;
};

export default UnAuthPage(function SignIn(props: any) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>();
  const { ref: emailRef, ...restEmail } = register('email', { required: 'メールアドレスが未入力です' });
  const { ref: passwordRef, ...restPassword } = register('password', { required: 'パスワードが未入力です' });
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showResetModal, toggle] = useToggle(false);

  const onSubmit = async (values: IFormInputs) => {
    const { email: _email, password } = values;
    const email = _email.match(/@/) ? _email : _email + '@' + EMAIL_DUMMY_DOMAIN;
    auth.signInWithEmailAndPassword(email, password).catch((error) => {
      const message = ERRORS[error.code] || '不明なエラーです。';
      setErrorMessage(message);
    });
  };

  return (
    <div>
      <Modal isOpen backdrop={false} keyboard={false} centered>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormGroup>
              <Label for="email">メールアドレス or ID</Label>
              <Input type="email" id="email" invalid={Boolean(errors.email)} {...restEmail} innerRef={emailRef} />
              {errors.email && <FormFeedback>{errors.email.message}</FormFeedback>}
            </FormGroup>
            <FormGroup>
              <Label for="password">パスワード</Label>
              <Input
                type="password"
                id="password"
                invalid={Boolean(errors.password)}
                {...restPassword}
                innerRef={passwordRef}
              />
              {errors.password && <FormFeedback>{errors.password.message}</FormFeedback>}
            </FormGroup>
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={toggle}>
              <small>パスワードを忘れた方はこちら</small>
            </Button>
            <Button color="primary" type="submit" onClick={handleSubmit(onSubmit)}>
              <i className="fas fa-sign-in-alt me-1"></i>
              サインイン
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <ResetPasswordModal isOpen={showResetModal} onClickClose={toggle} />
    </div>
  );
});
