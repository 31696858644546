import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

export const updateUserPassword = httpsCallable<{ uid: string; password: string }, boolean>(
  getFunctions(getApp(), 'asia-northeast1'),
  'updateUserPassword'
);

export const createGroupUsers = httpsCallable<
  { groupId: string; users: { email: string; password: string; name: string; role?: string }[] },
  boolean
>(getFunctions(getApp(), 'asia-northeast1'), 'createGroupUsers');
