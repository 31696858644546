import React, { Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { UAParser } from 'ua-parser-js';
import useFirebaseUser from './hooks/useFirebaseUser';
import useCurrentUser from './hooks/useCurrentUser';
import UnsupportedMessage from './pages/UnsupportedMessage';

const uaParser = new UAParser();

export default function Root(props: any) {
  const { firebaseUser, isLoading } = useFirebaseUser();
  const currentUser = useCurrentUser(firebaseUser);
  const deviceType = uaParser.getDevice().type;

  return !deviceType || deviceType === 'tablet' ? (
    <Fragment>
      <BrowserRouter>{props.routes({ firebaseUser, currentUser, isLoading })}</BrowserRouter>
      <ToastContainer />
    </Fragment>
  ) : (
    <UnsupportedMessage />
  );
}
