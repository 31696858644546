import firebase from 'firebase/compat/app';
import { DEFAULT_BOARD_WIDTH, DEFAULT_BOARD_HEIGHT, DEFAULT_SCALE } from '../../shared/config';

type ActualSize = {
  width: number;
  height: number;
  scale: number;
};

type Image = {
  name: string;
  url: string;
  path: string;
} | null;

export interface IBoard {
  key: string;
  ref: firebase.database.Reference | null;
  type: string;
  image: Image;
  x: number;
  y: number;
  z: number;
  width: number;
  height: number;
  actualSize: ActualSize;
  toObject: () => {};
}

export class Board implements IBoard {
  key: string = '';
  ref: firebase.database.Reference | null = null;
  type: string = 'board';
  image: Image = null;
  x: number = 0;
  y: number = 0;
  z: number = 0;
  width: number = 0;
  height: number = 0;
  actualSize: ActualSize = {
    width: DEFAULT_BOARD_WIDTH,
    height: DEFAULT_BOARD_HEIGHT,
    scale: DEFAULT_SCALE,
  };

  constructor({
    key = '',
    ref = null,
    type = 'board',
    image = null,
    x = 0,
    y = 0,
    z = 0,
    width = DEFAULT_BOARD_WIDTH * DEFAULT_SCALE,
    height = DEFAULT_BOARD_HEIGHT * DEFAULT_SCALE,
    actualSize = {
      width: DEFAULT_BOARD_WIDTH,
      height: DEFAULT_BOARD_HEIGHT,
      scale: DEFAULT_SCALE,
    },
  }: Partial<IBoard>) {
    Object.assign(this, {
      key,
      ref,
      type,
      image,
      x,
      y,
      z,
      width,
      height,
      actualSize,
    });
  }

  toObject(): {} {
    return {
      type: this.type,
      image: this.image,
      x: this.x,
      y: this.y,
      z: this.z,
      width: this.width,
      height: this.height,
      actualSize: this.actualSize,
    };
  }
}
