import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { auth } from '../../firebase';

type Props = {
  isOpen: boolean;
  onClickClose: () => void;
};

type IFormInputs = {
  email: string;
};

export default function ResetPasswordModal(props: Props) {
  const { isOpen, onClickClose } = props;
  const { register, handleSubmit, formState: { errors } } = useForm<IFormInputs>();
  const { ref, ...rest } = register('email', { required: 'メールアドレスが未入力です' });

  const onSubmit = async (values: IFormInputs) => {
    const { email } = values;
    await auth
      .sendPasswordResetEmail(email)
      .then(() => {
        toast.success('パスワードリセットメールを送信しました。');
        onClickClose();
      })
      .catch(() => {
        toast.error('リセットメール送信に失敗しました。');
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={onClickClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormGroup>
            <Label for="reset-email">メールアドレス</Label>
            <Input
              type="email"
              id="reset-email"
              invalid={Boolean(errors.email)}
              {...rest}
              innerRef={ref}
            />
            {errors.email && <FormFeedback>{errors.email.message}</FormFeedback>}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="warning" type="submit" onClick={handleSubmit(onSubmit)}>
            リセットメールを送信する
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
